import React, { useContext, useEffect, useState } from 'react';
import { HCarousel } from '../components/HCarousel'
import { CartContext } from '../contexts/CartContext';
import { genderCategoryShowCases2, genderCategoryShowCaseTabs2 } from '../placeholder';
import { useTranslation } from "../hooks/Translation";

export const RecentlyViewdItems = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ show: false })
    const { cartContext, onSetCartContext, onSetRecentViewsContext, onSetWishListContext } = useContext(CartContext)
    return (
        <>
            <div className="d-none d-lg-block">
                {
                    settings.show ?
                        <div className="blur-area" onClick={() => { setSettings({ show: false }) }}></div>
                    : ''
                }
                <div className="recent-views-items">
                    {
                        !settings.show ?
                            <button
                                onClick={() => { setSettings({ show: true }) }}
                                className="recent-button btn btn-sm btn-info">{t('you_recently_viewed')} <i className="fa fa-chevron-up"></i></button>
                            : <button
                                onClick={() => { setSettings({ show: false }) }}
                                className="recent-button btn btn-sm btn-info" style={{ bottom: 220 }}>{t('you_recently_viewed')} <i className="fa fa-chevron-down"></i></button>
                    }
                    {
                        settings.show ?
                            <div className="items">
                                <HCarousel
                                    initItems={6}
                                    itemStyle={{
                                        width: 200,
                                        height: 200
                                    }}
                                    className="col-12 col-md-12 col-lg-3 col-xl-3"
                                    showcases={cartContext.recentViews} />
                            </div>
                            : ''
                    }
                </div>
            </div>
        </>
    )
}

export const RecentlyViewdItemsMobile = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ show: false })
    const { cartContext, onSetCartContext, onSetRecentViewsContext, onSetWishListContext } = useContext(CartContext)
    useEffect(() => {
        setTimeout(() => {
            setSettings({ ...settings, show: true });
        }, 100);
    }, [])
    return (
        settings.show ?
            <div className="d-lg-none">
                <div className="recent-views-items1">
                    <h3 className="m-0 p-0 text-center bolder">{t('you_recently_viewed')}</h3>
                    <div className="items">
                        <HCarousel
                            initItems={6}
                            itemStyle={{
                                height: 200
                            }}
                            className="col-12 col-md-12 col-lg-3 col-xl-3"
                            showcases={cartContext.recentViews} />
                    </div>
                </div>
            </div>
            : ''
    )
}