import { useContext, useEffect, useState } from "react";
import { Axios } from '../helpers';
import swal from 'sweetalert';
import { AuthContext } from "../contexts/AuthContext";
import { parseQueryString } from '../helpers'
import { Link } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";

export const TermsAndCondition = (props) => {
    const t = useTranslation();
    return (
        <div className="brands myaccount container withoutheader-mobile mb-5">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <div className="wrap container" id="pageheight">
                <div className="container">
                    <h1 className="app-title">{t('terms_condition')}</h1>
                </div>
            </div>
        </div>
    )
}