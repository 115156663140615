import { AuthSideNav } from "./AuthSideNav"

export const UserAuthenticatedArea = (props) => {
    return (
        <div className="row">
            <div className="col-12 col-lg-3 col-xl-3">
                <AuthSideNav {...props} />
           </div>
           <div className="col-12 col-lg-9 col-xl-9">
               { props.children }
           </div>
        </div>
    )
}