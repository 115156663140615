import { Link } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";

export const ColumnShowCase = (props) => {
    const t = useTranslation();
    const itemStyle = props.itemStyle ? props.itemStyle : {};
    const actionStyle = props.actionStyle ? props.actionStyle : {};
    return (
        <div className="showcases mb-0" data-aos="fade-right">
            <div className="row g-2">
                <div className="col-6 csl p-0">
                    <div className="columnshowcaseleft">
                        <h2 className="bolder">{props.title}</h2>
                        {
                            props.tabs.map((tab) => {
                                return (
                                    <Link
                                        style={{
                                            fontSize: 18,
                                            color: 'white',
                                            fontStyle: 'italic',
                                            marginBottom: 10,
                                            marginTop: 10
                                        }}
                                        to={'/products/' + tab.slug}>{tab.title}</Link>
                                )
                            })
                        }
                        <Link to={'/products/mens-fashion'} className="btn sbutton mt-5 btn-secondary circle-button" style={{ fontSize: 11 }}>{t('see_the_collection')}</Link>
                    </div>
                </div>
                <div className="col-6 p-0">
                    {
                        props.showcases && [props.showcases[0]].map((showcase, index) => {
                            return !props.imageTag ?
                                (
                                    <div key={index} className={`${props.className}`}>
                                        <div
                                            to={showcase.url}
                                            key={index}
                                            className={`showcase-img`}
                                            style={{
                                                backgroundImage: `url(${props.image})`,
                                                backgroundPosition: '50% 6%',
                                                height: 450,
                                                ...itemStyle
                                            }}>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div
                                        key={index}
                                        className={`${props.className}`}
                                        style={{
                                            ...itemStyle
                                        }}>
                                        <div
                                            className="actions"
                                            style={{
                                                ...actionStyle
                                            }}>
                                            {props.renderActions ? props.renderActions(showcase) : ''}
                                        </div>
                                        <img className="img-fluid" src={showcase.image} />
                                    </div>
                                )
                        })
                    }
                </div>
            </div>
        </div>
    )
}