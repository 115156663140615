import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SideBarContext } from "../../contexts/SidebarContext"
import { UserAuthenticatedArea } from "./UserAuthenticatedArea"
import axios from "axios";
import swal from "sweetalert";
import { environment } from "../../config";
import { AuthContext } from "../../contexts/AuthContext";
import { Axios, parseQueryString } from "../../helpers"
import { RequetAQoute } from "../../pages/RequestAQoute";
import { Modal } from "../Modal";
import { WithAuth } from "./WithAuth";
import moment from "moment";
import { Appointment } from "../Appointment";
import { useTranslation } from "../../hooks/Translation";
import Stepper from '../../vendor/stepper';
import Select from "react-select";

export const UserMeasurements = (props) => {
    const t = useTranslation();
    const { appSettings, setAppSettings } = useContext(SideBarContext);
    const [settings, setSettings] = useState({
        orders: [],
        showModal: false,
        showSelectModal: false,
        selectState: {

        },
        stepperState: {
            activeIndex: 3,
            stepper:  {
                title: 'Length',
                label: 'Length',
                value: 'length',
                key: 'length',
                img: '/assets/images/length.jpeg',
                href: '#',
                index: 0
            }
        },
        updateState: {
            tailor: {},
            perons: {},
            selectedDate: '',
            selectedTime: ''
        }
    });
    useEffect(() => {
        loadData();
    }, [])
    const loadData = () => {
        setAppSettings({ ...appSettings, loading: true });
        axios.all([
            Axios({}).get('/measurements')
        ]).then(axios.spread((appointmentRes) => {
            const d = appointmentRes.data.map((r) => {
                r.measurement = {
                    arm_edge: r.arm_edge,
                    bicep: r.bicep,
                    bottom_width: r.bottom_width,
                    chest: r.chest,
                    collar: r.collar,
                    hand: r.hand,
                    length: r.length,
                    shoulder: r.shoulder,
                    waist: r.waist,
                    wrist: r.wrist
                };
                return r
            })
            setSettings({ ...settings, orders: d });
            setAppSettings({ ...appSettings, loading: false });
        }))
    }
    const lang = localStorage.getItem('lang') || 'en';
    const deleteCustomRequest = (id) => {
        setAppSettings({ ...appSettings, loading: true });
        axios.all([
            Axios({}).get(`/delete-measurement/${id}`),
        ]).then(axios.spread((addressRes) => {
            loadData();
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        });
    }
    const handleSubmit = (ssettings) => {
        setAppSettings({ ...appSettings, loading: true });
        Axios({}).post(`/update-measurement/${settings.updateState.id}`, {
            ...settings.updateState.measurement,
            profile: settings.updateState.profile,
            comments: settings.updateState.comments,
        }).then(() => {
            loadData();
            window.$('#editmodal').modal('hide')
            setAppSettings({ ...appSettings, updateState: {}, loading: false });
        })
    }
    const printMeasurments = (r) => {
        let m = <table className="table p-0 m-0 text-center"><tbody>
            {
                Object.keys(r).map((k) => {
                    return <tr className="text-center cap">
                        <th>{k.replace(/_/g, ' ')}</th>
                        <th><span className="badge bg-dark">{r[k]}</span></th>
                    </tr>
                })
            }
        </tbody></table>;
        return m;
    }
    let steps = [
        {
            title: 'Length',
            label: 'Length',
            value: 'length',
            key: 'length',
            img: '/assets/images/length.jpeg',
            href: '#'
        },
        {
            title: 'Bottom Size', 
            key: 'bottom_width',
            label: 'Bottom Size',
            value: 'bottom_width',
            img: '/assets/images/bottom_width.jpeg',
            href: '#',
        }, 
        {
            title: 'Collar Size', 
            key: 'collar',  
            label: 'Collar Size',
            value: 'collar',
            href: 'http://example1.com',
            img: '/assets/images/neck.jpeg',
        }, 
        {
            title: 'Chest Size', 
            key: 'chest',  
            label: 'Chest Size',
            value: 'chest',
            href: 'http://example.com',
            img: '/assets/images/chest.jpeg',
        }, 
        {
            title: 'Waist Size', 
            key: 'waist',  
            label: 'Waist Size',
            value: 'waist',
            href: 'http://example.com',
            img: '/assets/images/waist.jpeg',
        }, 
        {
            title: 'Shoulder Size', 
            key: 'shoulder',  
            label: 'Shoulder Size',
            value: 'shoulder',
            href: 'http://example.com',
            img: '/assets/images/shoulder.jpeg',
        }, 
        {
            title: 'Hand Girth', 
            key: 'hand',  
            label: 'Hand Girth',
            value: 'hand',
            href: 'http://example.com',
            img: '/assets/images/hand.jpeg',
        }, 
        {
            title: 'Arm length', 
            key: 'arm_edge',
            label: 'Arm length',
            value: 'arm_edge',
            href: 'http://example.com',
            img: '/assets/images/armpit.jpeg',
        }, 
        {
            title: 'Biceps Muscle', 
            key: 'bicep',
            label: 'Biceps Muscle',
            value: 'bicep',
            href: 'http://example.com',
            img: '/assets/images/biceps.jpeg',
        }, 
        {
            title: 'Wrist', 
            key: 'wrist',
            label: 'Wrist',
            value: 'wrist',
            href: 'http://example.com',
            img: '/assets/images/wrist.jpeg',
        }
    ];
    steps = steps.map((s, i) => {
        s.href = '#';
        s.index = i;
        s.onClick = (e) => {
            setSettings({
                ...settings,
                stepperState: {
                    ...settings.stepperState,
                    stepper: s,
                    activeIndex: i
                }
            })
            console.log(s);
        }
        s.label = t(s.label);
        s.title = t(s.title);

        return s;
    })
    return (
        <div className="myaccount container withoutheader-mobile mb-5 mt-3">
            <div className="pt-5 d-lg-none"></div>
            <div className="pt-5 d-lg-none"></div>
            <Modal
                onClose={() => {
                    setSettings({
                        ...settings,
                        showSelectModal: false
                    })
                }}
                footer={(p) => {
                    return (
                        <div className="modal-footer">
                            <button onClick={() => {
                                window.$('#selectmodal').modal('hide')
                            }} className="btn btn-dark btn-sm"><i className="fa fa-shopping"></i>{t('done')}</button>
                        </div>
                    )
                }}
                dialogClassName="modal-lg" {...props}
                id="selectmodal" title={t('select')}>
                {
                    settings.showSelectModal ?
                        <div style={{ maxHeight: 450, overflow: 'auto', overflowX: 'hidden' }}>
                            <div className="p-3">
                                <Appointment
                                    showType
                                    withSelect={true}
                                    withProfile={true}
                                    profile={settings.selectState}
                                />
                            </div>
                        </div>
                    : ''
                }
            </Modal>
            <Modal
                onClose={() => {
                    setSettings({
                        ...settings,
                        showModal: false
                    })
                }}
                footer={(p) => {
                    return (
                        <div className="modal-footer">
                            <button 
                                hidden={settings.stepperState.activeIndex === 0}
                                onClick={() => {
                                let index = (settings.stepperState.stepper.index) - 1;
                                if (index > -1) {
                                    const f = steps[index];
                                    setSettings({
                                        ...settings,
                                        stepperState: {
                                            ...settings.stepperState,
                                            stepper: f,
                                            activeIndex: f.index
                                        }
                                    })
                                }
                            }} className="btn btn-dark btn-sm">{t('back')}</button>
                            <button 
                                hidden={steps.length === settings.stepperState.activeIndex + 1}
                                onClick={() => {
                                let index = (settings.stepperState.stepper.index) + 1;
                                if (index < steps.length) {
                                    const f = steps[index];
                                    setSettings({
                                        ...settings,
                                        stepperState: {
                                            ...settings.stepperState,
                                            stepper: f,
                                            activeIndex: f.index
                                        }
                                    })
                                }
                            }} className="btn btn-dark btn-sm">{t('next')}</button>
                            <button onClick={() => {
                                handleSubmit()
                            }} className="btn btn-dark btn-sm">{t('save')}</button>
                            <button onClick={() => {
                                window.$('#editmodal').modal('hide')
                            }} className="btn btn-dark btn-sm">{t('cancel')}</button>
                        </div>
                    )
                }}
                dialogClassName="modal-lg" {...props}
                id="editmodal" title={t('edit')}>
                {
                    settings.showModal ?
                        <div style={{ maxHeight: 400, overflow: 'auto', overflowX: 'hidden' }}>
                            <div className="d-none d-lg-block">
                            <Stepper 
                                size={24}
                                defaultColor={'#ccc'}
                                activeColor={'#0769bd'}
                                completeColor={'#5096FF'}
                                circleFontSize={0}
                                titleFontSize={10}
                                steps={steps} 
                                onClick={(e) => {
                                    console.log(e);                                    
                                }}
                                activeStep={ settings.stepperState.activeIndex } 
                            />
                            </div>
                            <div className="p-5 pt-3 pb-3 d-lg-none">
                                <Select
                                    onChange={(s) => {
                                        setSettings({
                                            ...settings,
                                            stepperState: {
                                                ...settings.stepperState,
                                                stepper: s,
                                                activeIndex: s.index
                                            }
                                        })
                                    }}
                                    value={settings.stepperState.stepper}
                                    options={steps}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 p-5 pb-1 text-center pt-0" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    {t(settings.stepperState.stepper.title)}
                                    <div
                                        className="p-3"
                                        style={{
                                            position: 'relative',
                                            top: 1
                                        }}
                                        className="input-group input-group-sm flex-nowrap">
                                        <span
                                            onClick={() => {
                                                if (parseFloat(settings.updateState.measurement[settings.stepperState.stepper.key]) < 1) {
                                                    return;
                                                }
                                                setSettings({
                                                    ...settings,
                                                    updateState: {
                                                        ...settings.updateState,
                                                        measurement: {
                                                            ...settings.updateState.measurement,
                                                            [settings.stepperState.stepper.key]: parseFloat(parseFloat(settings.updateState.measurement[settings.stepperState.stepper.key]) - 1).toFixed(2),
                                                        }
                                                    }
                                                })
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="input-group-text" id="addon-wrapping">-</span>
                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                setSettings({
                                                    ...settings,
                                                    updateState: {
                                                        ...settings.updateState,
                                                        measurement: {
                                                            ...settings.updateState.measurement,
                                                            [settings.stepperState.stepper.key]: e.target.value,
                                                        }
                                                    }
                                                })
                                            }}
                                            style={{
                                                direction: 'ltr'
                                            }}
                                            placeholder={0}
                                            value={settings.updateState.measurement[settings.stepperState.stepper.key]}
                                            className="form-control text-center"
                                            aria-describedby="addon-wrapping" />
                                        <span
                                            onClick={() => {
                                                setSettings({
                                                    ...settings,
                                                    updateState: {
                                                        ...settings.updateState,
                                                        measurement: {
                                                            ...settings.updateState.measurement,
                                                            [settings.stepperState.stepper.key]: parseFloat(parseFloat(settings.updateState.measurement[settings.stepperState.stepper.key]) + 1).toFixed(2),
                                                        }
                                                    }
                                                })
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="input-group-text" id="addon-wrapping">+</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 p-2 text-center">
                                    <img style={{ height: 300 }} src={settings.stepperState.stepper.img} className="img-fluid" />                                    
                                </div>
                            </div>
                            <div className="p-3">
                                <div className="mb-3">
                                    <label>Profile</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            setSettings({
                                                ...settings,
                                                updateState: {
                                                    ...settings.updateState,
                                                    profile: e.target.value
                                                }
                                            })
                                        }}
                                        value={settings.updateState.profile} />
                                </div>
                                <div className="mb-3">
                                    <label>{t('comments')}</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            setSettings({
                                                ...settings,
                                                updateState: {
                                                    ...settings.updateState,
                                                    comments: e.target.value
                                                }
                                            })
                                        }}
                                        value={settings.updateState.comments}>
                                    </textarea>
                                </div>
                                {/* {
                                    Object.keys(settings.updateState?.measurement).map((k) => {
                                        return (
                                            <div className="mb-3">
                                                <label className="cap">{k.replace(/_/g, ' ')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        setSettings({
                                                            ...settings,
                                                            updateState: {
                                                                ...settings.updateState,
                                                                measurement: {
                                                                    ...settings.updateState.measurement,
                                                                    [k]: e.target.value,
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    value={settings.updateState.measurement[k]} />
                                            </div>
                                        )
                                    })
                                } */}
                            </div>
                        </div>
                        : ''
                }
            </Modal>
            <h2 className="app-title bolder pt-0">{t('measurement')}</h2>
            {
                settings.orders.length < 1 ?
                    <UserAuthenticatedArea>
                        <div className="alert alert-secondary">
                            <div className="pull-left mt-1">
                                <i className="fa fa-warning"></i>&nbsp;&nbsp;&nbsp;<span className="ml-3">
                                    {t('no_measurements_yet')}.</span>
                            </div>
                            <div className="pull-right">
                                <Link to="/book-a-tailor" className="btn btn-dark btn-sm">{t('add')}</Link>
                            </div>
                            <div className="clear clearfix"></div>
                        </div>
                    </UserAuthenticatedArea>
                    : <UserAuthenticatedArea>
                        <div className="pt-3 d-lg-none"></div>
                        <div>
                            <div className="pull-right">
                                <Link to="/book-a-tailor" className="btn btn-dark btn-sm">{t('add')}</Link>
                            </div>
                            <p className="clear clearfix"></p>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered p-0 m-0">
                                <thead>
                                    <tr>
                                        <th scope="col">{t('profile')}</th>
                                        <th scope="col">{t('comments')}</th>
                                        {/* <th style={{ minWidth: 200 }} className="text-center">{t('measurements')}</th> */}
                                        <th style={{ minWidth: 180 }} className="text-center" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        settings.orders.map((cr, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle' }}>{cr.profile}</td>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle' }}>{cr.comments}</td>
                                                    {/* <td className="text-center" style={{ position: 'relative', verticalAlign: 'middle' }}>
                                                        {printMeasurments(cr.measurement)}
                                                    </td> */}
                                                    <td className="text-center"  style={{ position: 'relative', verticalAlign: 'middle', width: 180 }}>
                                                        <button onClick={() => {
                                                            setSettings({
                                                                ...settings,
                                                                showModal: false,
                                                                updateState: {
                                                                    ...cr
                                                                }
                                                            })
                                                            setTimeout(() => {
                                                                setSettings({
                                                                    ...settings,
                                                                    showModal: true,
                                                                    updateState: cr
                                                                })
                                                                window.$('#editmodal').modal('show')
                                                            }, 100)
                                                        }} className="btn btn-sm btn-primary"><i className="fa fa-edit"></i></button>
                                                        &nbsp;
                                                        <button
                                                            onClick={() => {
                                                                swal({
                                                                    title: t('are_you_sure'),
                                                                    text: t('once_deleted_not_recover'),
                                                                    icon: "warning",
                                                                    buttons: true,
                                                                    dangerMode: true,
                                                                })
                                                                    .then((willDelete) => {
                                                                        if (willDelete) {
                                                                            deleteCustomRequest(cr.id)
                                                                        }
                                                                    });
                                                            }}
                                                            className="btn btn-sm btn-danger"><i className="fa fa-times"></i></button>
                                                            &nbsp;
                                                            <button onClick={() => {
                                                                setSettings({
                                                                    ...settings,
                                                                    showSelectModal: false,
                                                                    selectState: {
                                                                        ...cr
                                                                    }
                                                                })
                                                                setTimeout(() => {
                                                                    setSettings({
                                                                        ...settings,
                                                                        showSelectModal: true,
                                                                        selectState: cr
                                                                    })
                                                                    window.$('#selectmodal').modal('show')
                                                                }, 100)
                                                        }} className="btn btn-success btn-sm">{t('select')}</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </UserAuthenticatedArea>
            }
        </div>
    )
}