import { genderCategoryShowCases2, genderCategoryShowCaseTabs2 } from "../placeholder";
import { HCarousel } from "./HCarousel";
import { useTranslation } from "../hooks/Translation";

export const RelatedProducts = (props) => {
    const t = useTranslation();
    const itemStyle = props.itemStyle ? props.itemStyle : {};
    const actionStyle = props.actionStyle ? props.actionStyle : {};
    return (
        <div className="related_products">
            <HCarousel
                initItems={5}
                itemStyle={{
                    height: 200
                }}
                tabs={false}
                className="col-12 col-md-12 col-lg-3 col-xl-3"
                title={t('related_products')}
                showcases={props.products} />
        </div>
    )
}