import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { LayoutContext } from "../contexts/LayoutContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { useTranslation } from "../hooks/Translation";

export const MobileNavbar = (props) => {
    const t = useTranslation();
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const { layoutSettings, setLayoutSettings } = useContext(LayoutContext)
    const history = useHistory();
    const lang = document.documentElement.lang;
    const language = lang !== 'en' ? 'English' : 'العربية';
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];
    return (
        <nav className="mobile-nav d-xl-none pb-3">
            {
                window.location.pathname !== '/' ?
                    <button
                        onClick={() => {
                            history.goBack();
                        }}
                        className="btn btn-dark" style={{
                            position: 'absolute',
                            right: '20px',
                            top: '-50px'
                        }}><i className="fa fa-caret-left"></i></button>
                    : ''
            }
            {/* <div className="navicons text-center">
                <Link to="/">
                    <span 
                        style={{
                            fontSize: 30
                        }}
                        className="d-block lnr lnr-home"></span>
                        <span className="title">{ t('Home') }</span>
                </Link>
            </div> */}
            {/* <div className="navicons text-center">
                <Link to="/contact-us">
                    <span 
                        style={{
                            fontSize: 30
                        }}
                        className="d-block lnr lnr-map-marker"></span>
                </Link>
            </div> */}
            {
                layoutSettings.showHome ?
                    <div className="navicons text-center">
                        <Link to="/">
                            <span
                                style={{
                                    fontSize: 26
                                }}
                                className="d-block lnr lnr-home"></span>
                            <span className="title">{t('home')}</span>
                        </Link>
                    </div>
                    :
                    <div className="navicons text-center">
                        <Link to="/shops">
                            <span
                                style={{
                                    fontSize: 26
                                }}
                                className="d-block lnr lnr-store"></span>
                            <span className="title">{t('shop')}</span>
                        </Link>
                    </div>
            }
            {
                layoutSettings.mobileNav && layoutSettings.mobileNav.map((mnav, key) => {
                    return (
                        <div key={key} className="navicons text-center">
                            <Link
                                {...mnav.props}
                                to={mnav.url}
                                onClick={(e) => {
                                    if (!mnav.url) {
                                        e.preventDefault();
                                        if (mnav.callback) {
                                            mnav.callback(e);
                                        }
                                    }
                                }
                                }>
                                <span
                                    style={{
                                        fontSize: 26
                                    }}
                                    className={"d-block " + mnav.icon}></span>
                                <span className="title">{t(mnav.title)}</span>
                            </Link>
                        </div>
                    )
                })
            }
            <div className="navicons text-center" style={{ position: 'relative' }}>
                <div className="wishlist-content">
                    <div className="wishlist-contentcount">{lCart.length || 0}</div>
                </div>
                <a
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        setAppSettings({ ...appSettings, show: !appSettings.show })
                    }}>
                    <span
                        style={{
                            fontSize: 26
                        }}
                        className="d-block fa fa-shopping-bag"></span>
                    <span className="title">{t('cart')}</span>
                </a>
            </div>
        </nav>
    )
}