import { useContext, useEffect, useRef, useState } from "react";
import { Axios } from "../../helpers";
import { UserAuthenticatedArea } from "./UserAuthenticatedArea"
import swal from 'sweetalert';
import { AuthContext } from "../../contexts/AuthContext";
import { environment } from "../../config";
import { useTranslation } from "../../hooks/Translation";

export const AccountDetails = (props) => {
    const t = useTranslation();
    const { auth, setAuth } = useContext(AuthContext)
    let fRef = useRef(null);
    const [settings, setSettings] = useState({
        isUpdated: false,
        isFormValid: false,
        loader: false,
        file: '',
        state: {
            name: '',
            phone: '',
            address: '',
            dob: '',
            gender: 1,
            email: '',
            old_password: '',
            new_password: '',
            confirm_new_password: ''
        },
        errors: {
            email: [],
            password: []
        }
    });
    if (auth && auth.is_auth && !settings.isUpdated) {
        console.log(auth.auth);
        setSettings({
            ...settings,
            state: auth.auth,
            isUpdated: true
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (settings.state.new_password !== settings.state.confirm_new_password) {
            swal("Confirm Password", "Please confirm password", "error")
            return;
        }
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        const formData = new FormData();
        formData.append('name', settings.state.name);
        formData.append('email', settings.state.email);
        formData.append('old_password', settings.state.old_password);
        formData.append('phone', settings.state.phone);
        formData.append('new_password', settings.state.new_password);
        formData.append('confirm_new_password', settings.state.confirm_new_password);
        formData.append('dob', settings.state.dob);
        formData.append('gender', settings.state.gender);
        formData.append('address', settings.state.address);
        formData.append('image', settings.file);

        Axios({ "Content-Type": "multipart/form-data" }).post('/update-profile', formData).then((res) => {
            if (res.data.response_data && res.data.success) {
                let image = environment.staticUrl + 'users/' + res.data.response_data.image;
                const d = { ...auth.auth, ...res.data.response_data, image };
                localStorage.setItem('auth', JSON.stringify(d));
                setAuth({ is_auth: true, auth: d });
            }
            setSettings({ ...settings, loader: false })
        }).catch(error => {
            setSettings({ ...settings, loader: false })
        })
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1 mb-5">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    if (!auth && !auth.is_auth) {
        return (
            <div className="container p-5 text-center">
                <p className="alert alert-danger">{t('not_authenticated')}</p>
            </div>
        )
    }
    return (
        <div className="myaccount container withoutheader-mobile mb-5">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <h1 className="app-title">{t('account_detail')}</h1>
            <UserAuthenticatedArea>
                <div>
                    <form method="POST" onSubmit={handleSubmit}>
                        <div className="d-lg-none pt-5"></div>
                        <div className="mb-3">
                            <div onClick={() => {
                                if (fRef.current) {
                                    fRef.current.click();
                                }
                            }} style={{ cursor: 'pointer', width: 200, overflow: 'hidden', height: 200, margin: '0 auto', border: '1px solid #ccc', textAlign: 'center', borderRadius: '100%' }}>
                                <img style={{ width: 200, height: 200 }} src={settings.state.image} />
                            </div>
                            <input
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    var reader = new FileReader();
                                    reader.onload = function () {
                                        var dataURL = reader.result;
                                        setSettings({
                                            ...settings,
                                            file: file,
                                            state: { ...settings.state, image: dataURL }
                                        })
                                    };
                                    reader.readAsDataURL(file);
                                }}
                                style={{ display: 'none' }}
                                ref={fRef} accept="image/x-png,image/gif,image/jpeg" type="file" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">{t('name')}</label>
                            <input
                                value={settings.state.name}
                                onChange={handleInputChange}
                                type="text"
                                required={true}
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder={t('name') + "*"} />
                            {/* <p>This will be how your name will be displayed in the account section and in reviews</p> */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">{t('email_address')}</label>
                            <input
                                value={settings.state.email}
                                onChange={handleInputChange}
                                type="text"
                                className="form-control"
                                required={true}
                                name="email"
                                id="email"
                                placeholder={t('email_address')} />
                        </div>
                        <div className="row">
                            {/* <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="dfname" className="form-label">{t('dob')}</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="dob"
                                        value={settings.state.dob}
                                        onChange={handleInputChange}
                                        name="dob"
                                        placeholder={t('dob') + "*"} />
                                </div>
                            </div> */}
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="gender" className="form-label">{t('gender')}</label>
                                    <select value={settings.state.gender} id="gender" className="form-control" onChange={(e) => {
                                        setSettings({ ...settings, state: { ...settings.state, gender: e.target.value } })
                                    }}>
                                        <option value="1">{t('male')}</option>
                                        <option value="2">{t('female')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">{t('phone')}</label>
                            <input
                                value={settings.state.phone}
                                onChange={handleInputChange}
                                type="phone"
                                required={true}
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder={t('phone')} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="address" className="form-label">{t('address')}</label>
                            <textarea
                                type="date"
                                className="form-control"
                                id="address"
                                onChange={handleInputChange}
                                name="address"
                                value={settings.state.address}
                                placeholder={t('address') + "*"}>
                            </textarea>
                        </div>
                        <button type="submit" className="btn btn-primary form-control mb-3">
                            {
                                settings.loader ?
                                    <i className="fa fa-spin fa-spinner"></i>
                                    : <span>{t('update')}</span>
                            }
                        </button>
                        <div className="passwordbox box gray">
                            <h5 className="text-center"><strong>{t('password_change')}</strong></h5>
                            <div className="mb-3">
                                <label htmlFor="old_password" className="form-label">{t('current_password')}</label>
                                <input
                                    value={settings.state.old_password}
                                    onChange={handleInputChange}
                                    type="password"
                                    className="form-control"
                                    id="old_password"
                                    name="old_password"
                                    placeholder={t('current_password')} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="new_password" className="form-label">{t('new_password')}</label>
                                <input
                                    value={settings.state.new_password}
                                    onChange={handleInputChange}
                                    type="password"
                                    className="form-control"
                                    id="new_password"
                                    name="new_password"
                                    placeholder={t('new_password')} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="confirm_new_password" className="form-label">{t('confirm_password')}</label>
                                <input
                                    value={settings.state.confirm_new_password}
                                    onChange={handleInputChange}
                                    type="password"
                                    className="form-control"
                                    id="confirm_new_password"
                                    name="confirm_new_password"
                                    placeholder={t('confirm_password')} />
                            </div>
                            <button type="submit" className="btn btn-primary form-control mt-3">
                                {
                                    settings.loader ?
                                        <i className="fa fa-spin fa-spinner"></i>
                                        : <span>{t('update')}</span>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </UserAuthenticatedArea>
        </div>
    )
}