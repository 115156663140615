import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SideBySideMagnifier, Magnifier, MOUSE_ACTIVATION, TOUCH_ACTIVATION } from 'react-image-magnifiers';
import { CartContext } from "../contexts/CartContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios, CartTypes, printLeftCenterRight, printPrice } from "../helpers";
import { environment } from "../config";
import { RelatedProducts } from "../components/RelatedProducts";
import swal from "sweetalert";
import axios from "axios";
import { RecentlyViewdItems, RecentlyViewdItemsMobile } from "../components/RecentlyViewdItems";
import { useTranslation } from "../hooks/Translation";
import Select from "react-select";
import Stepper from "../vendor/stepper";


export const SingleProduct = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({
        state: {
            quantity: 1,
            size: '',
            color: '',
            measurements: {

            }
        },
        stepperState: {
            activeIndex: 0,
            stepper:  {
                index: 0
            }
        },
        params: '',
        render: false,
        related_products: [],
        product: { product_price_sizes: [], product_category: {}, images: [] },
        displayImage: {},
        firstRednerd: false
    });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext, onSetRecentViewsContext, onSetWishListContext } = useContext(CartContext)
    const params = useParams();
    const lang = localStorage.getItem('lang') || 'en';
    const dir = document.documentElement.dir || 'rtl';
    const categories = JSON.parse(localStorage.getItem('categories')) || [];
    const pKey = 'product_'
    const state = {
        alwaysInPlace: false,
        overlayOpacity: 0.6,
        switchSides: false,
        fillAvailableSpace: true,
        fillAlignTop: false,
        fillGapLeft: 0,
        fillGapRight: 0,
        fillGapTop: 0,
        fillGapBottom: 0,
        mouseActivation: MOUSE_ACTIVATION.CLICK,
        touchActivation: TOUCH_ACTIVATION.DOUBLE_TAP,
        dragToMove: true
    };
    const {
        alwaysInPlace,
        overlayOpacity,
        switchSides,
        fillAvailableSpace,
        fillAlignTop,
        fillGapLeft,
        fillGapRight,
        fillGapTop,
        fillGapBottom,
        mouseActivation, touchActivation, dragToMove
    } = state;
    const handleActivationChange = key => e => {
        const value = e.target.value;
        this.setState(() => ({ [key]: value }));
    };
    useEffect(() => {
        if (params.slug && settings.params && params.slug !== settings.params) {
            setSettings({
                ...settings,
                params: params.slug,
            });
            loadData();
        }
    })
    useEffect(() => {
        setSettings({
            ...settings,
            params: params.slug,
        });
        loadData();
    }, []);
    const loadData = () => {
        setTimeout(() => {
            setAppSettings({
                ...appSettings,
                sidenav: false,
                leftsidenav: false,
                loading: true
            })
            setSettings({
                ...settings,
                params: params.slug,
                loader: true,
            });
            Axios({}).get(`/products/${params.slug}`).then((res) => {
                let displayImage = {};
                let color = 0;
                if (res.data.have_color) {
                    res.data.images = res.data.product_color_images;
                    if (res.data.images.length > 0) {
                        color = res.data.images[0].color;
                    }
                } else {
                    res.data.images = res.data.product_images;
                }
                if (res.data?.images?.length > 0) {
                    displayImage = { image: `${environment.staticUrl}products/${res.data?.images[0]?.image}` };
                } else {
                    displayImage = { image: `${environment.staticUrl}no_image.png` };
                }
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    leftsidenav: false,
                })
                Axios({}).get(`/categories/${res.data.product_category['category_' + lang].slug}`).then((ress) => {
                    const related = ress.data.category_products.map((r) => {
                        let rdisplayImage = {};
                        if (r.have_color) {
                            r.images = r.product_color_images;
                        } else {
                            r.images = r.product_images;
                        }
                        if (r?.images?.length > 0) {
                            r.image = `${environment.staticUrl}products/${r.images[0].image}`;
                        } else {
                            r.image = `${environment.staticUrl}no_image.png`;
                        }
                        r.url = `/shops/` + r['product_' + lang].slug;
                        r.slug = `/shops/` + r['product_' + lang].slug;
                        return r;
                    })
                    setSettings({
                        ...settings,
                        loader: false,
                        render: true,
                        params: params.slug,
                        product: res.data,
                        product_category: res?.data?.product_category['category_' + lang],
                        related_products: related || [],
                        displayImage,
                        state: {
                            ...settings.state,
                            color: color
                        }
                    });
                    addToRecent(
                        res.data.id, {
                        image: displayImage,
                        ...res.data
                    });
                });
            }).catch((error) => {
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    leftsidenav: false,
                })
            })
        }, 0);
    }
    const addToRecent = (id, item) => {
        try {
            let recentViews = JSON.parse(localStorage.getItem('recent-views')) || [];
            const found = recentViews.findIndex((x) => x.id === id);
            if (recentViews.length > 10) {
                // recentViews.shift();
            }
            if (found === -1) {
                recentViews.push({
                    id: item.id,
                    url: `/shops/` + item['product_' + lang].slug,
                    slug: `/shops/` + item['product_' + lang].slug,
                    name: item['product_' + lang].title,
                    title: item['product_' + lang].title,
                    image: item.image.image,
                });
            }
            onSetRecentViewsContext(recentViews);
        } catch (error) {
            console.log(error);
        }
    }
    const steps = settings?.product?.product_measurements?.map((measurement, i) => {
        const s = {
            title: measurement.measurement['measurement_' + lang]?.title, 
            key: measurement.measurement['measurement_' + lang]?.slug.replace(/-/g, '_'),
            label: t(measurement.measurement['measurement_' + lang]?.slug),
            value: measurement.measurement['measurement_' + lang]?.slug.replace(/-/g, '_'),
            href: '#',
            img: '/assets/images/' + measurement.measurement['measurement_' + lang]?.slug + '.png',
            index: i,            
        }
        s.onClick = (e) => {
            setSettings({
                ...settings,
                stepperState: {
                    ...settings.stepperState,
                    stepper: s,
                    activeIndex: i
                }
            })
            console.log(s);
        }
        return s;
    })
    if (steps && steps.length > 0 && !settings.firstRednerd) {
        setSettings({ 
            ...settings, 
            firstRednerd: true,
            stepperState: {
                ...settings.stepperState,
                stepper: steps[0],
                activeIndex: 0
            }
        })
    }
    const renderSizeSelector = () => {
        return (
            <div className="mb-3">
                {/* <div className="mb-3">                   
                    <Select
                        onChange={(s) => {
                            setSettings({
                                ...settings,
                                stepperState: {
                                    ...settings.stepperState,
                                    stepper: s,
                                    activeIndex: s.index
                                }
                            })
                        }}
                        value={settings.stepperState.stepper}
                        options={steps}
                    />
                </div> */}
                <Stepper
                    size={24}
                    defaultColor={'#ccc'}
                    activeColor={'#7d968a'}
                    completeColor={'#839b8f'}
                    circleFontSize={0}
                    titleFontSize={10}
                    steps={steps || []} 
                    onClick={(e) => {
                        console.log(e);                                    
                    }}
                    activeStep={ settings.stepperState.activeIndex } 
                />
                <div className="text-center">
                    <img style={{ height: 300 }} src={settings.stepperState.stepper.img} className="img-fluid" />                                    
                </div>
                <div className="mb-3">
                                    {settings.stepperState.stepper.title}
                                    <div
                                        className="p-3"
                                        style={{
                                            position: 'relative',
                                            top: 1
                                        }}
                                        className="input-group input-group-sm flex-nowrap">
                                        <span
                                            onClick={() => {
                                                if (settings.state.measurements[settings.stepperState.stepper.key] && parseFloat(settings.state.measurements[settings.stepperState.stepper.key]) < 1) {
                                                    return;
                                                }
                                                let m = 1;
                                                if (settings.state.measurements[settings.stepperState.stepper.key]) {
                                                   m = settings.state.measurements[settings.stepperState.stepper.key];
                                                }
                                                setSettings({
                                                    ...settings,
                                                    state: {
                                                        ...settings.state,
                                                        measurements: {
                                                            ...settings.state.measurements,
                                                            [settings.stepperState.stepper.key]: parseFloat(parseFloat(m || 0) - 1).toFixed(2),
                                                        }
                                                    }
                                                })
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="input-group-text" id="addon-wrapping">-</span>
                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                setSettings({
                                                    ...settings,
                                                    state: {
                                                        ...settings.state,
                                                        measurements: {
                                                            ...settings.state.measurements,
                                                            [settings.stepperState.stepper.key]: e.target.value,
                                                        }
                                                    }
                                                })
                                            }}
                                            style={{
                                                direction: 'ltr'
                                            }}
                                            placeholder={0}
                                            value={settings.state.measurements[settings.stepperState.stepper.key] || ''}
                                            className="form-control text-center"
                                            aria-describedby="addon-wrapping" />
                                        <span
                                            onClick={() => {
                                                setSettings({
                                                    ...settings,
                                                    state: {
                                                        ...settings.state,
                                                        measurements: {
                                                            ...settings.state.measurements,
                                                            [settings.stepperState.stepper.key]: parseFloat(parseFloat(settings.state.measurements[settings.stepperState.stepper.key] || 0) + 1).toFixed(2),
                                                        }
                                                    }
                                                })
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="input-group-text" id="addon-wrapping">+</span>
                                    </div>
                                </div>
                <button
                    hidden={settings.stepperState.activeIndex === 0}
                    onClick={() => {
                        let index = (settings.stepperState.stepper.index) - 1;
                        if (index > -1) {
                            const f = steps[index];
                            setSettings({
                                ...settings,
                                stepperState: {
                                    ...settings.stepperState,
                                    stepper: f,
                                    activeIndex: f.index
                                }
                            })
                        }
                    }} className={
                        dir === 'rtl' ? "btn btn-dark btn-sm pull-right" : "btn btn-dark btn-sm pull-left"
                    }>{t('back')}</button>
                <button 
                    hidden={steps.length === settings.stepperState.activeIndex + 1}
                    onClick={() => {
                    let index = (settings.stepperState.stepper.index) + 1;
                    if (index < steps.length) {
                        const f = steps[index];
                        setSettings({
                            ...settings,
                            stepperState: {
                                ...settings.stepperState,
                                stepper: f,
                                activeIndex: f.index
                            }
                        })
                    }
                }} className={
                    dir === 'rtl' ? "btn btn-dark btn-sm pull-left" : "btn btn-dark btn-sm pull-right"
                }>{t('next')}</button>
                <p className="clear clearfix"></p>
            </div>
        )
    }
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];
    const lWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    let cwishlist = Array.isArray(lWishlist) ? lWishlist : [];
    const isMobile = window.innerWidth < 1024 || /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isInWishList = cwishlist.findIndex((x) => x.id === settings.product.id)
    return (
        <div className="shops container mt-3">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <div className="row">
                <div className="d-none d-lg-block col-md-3 col-sm-12">
                    <h5><strong>{t('product_categories')}</strong></h5>
                    <ul className="list-group">
                        {
                            categories && Array.isArray(categories) && categories.map((cat, index) => {
                                return (
                                    <Link
                                        key={index}
                                        className="nav-link list-group-item"
                                        aria-current="page"
                                        to={`/products/${cat['category_' + lang]?.slug}`}>
                                        { cat['category_' + lang]?.title}
                                    </Link>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="col-12 col-lg-9">
                    {
                        !settings.loader ?
                            <>
                                <h5><strong>{settings.product[pKey + lang]?.title}</strong></h5>
                                <div className="row p-0 m-0 mt-2">
                                    <div className="col-md-7 col-sm-12 p-0" style={{ position: 'relative' }}>
                                        {
                                            settings.render ?
                                                <div className="ml-2">
                                                    <button
                                                        style={{
                                                            position: 'absolute',
                                                            right: 12,
                                                            zIndex: 1
                                                        }}
                                                        onClick={() => {
                                                            let wishlist = Array.isArray(lWishlist) ? lWishlist : [];
                                                            const found = wishlist.findIndex((x) => x.id === settings.product.id);
                                                            if (found > -1) {
                                                                swal(t('already_wish_list_title'), t('already_wish_list_message'), "info")
                                                                return;
                                                            } else {
                                                                wishlist.push({ ...settings.product, ...settings.state, image: settings.displayImage });
                                                                onSetWishListContext(wishlist);
                                                                swal(t('wish_list_title'), t('wish_list_message'), "success")
                                                            }
                                                        }} 
                                                        className={ isInWishList > -1 ? "btn btn-danger mt-2" : "btn app-color mt-2"}
                                                        ><i className="fa fa-heart"></i></button>
                                                    {
                                                        isMobile ?
                                                            <Magnifier
                                                                imageSrc={settings.displayImage.image}
                                                                largeImageSrc={settings.displayImage.image}
                                                                mouseActivation={mouseActivation}
                                                                touchActivation={touchActivation}
                                                                dragToMove={dragToMove}
                                                            />
                                                        :
                                                            <SideBySideMagnifier
                                                                className=""
                                                                style={{ order: switchSides ? "1" : "0" }}
                                                                imageSrc={settings.displayImage.image}
                                                                largeImageSrc={settings.displayImage.image}
                                                                alwaysInPlace={alwaysInPlace}
                                                                overlayOpacity={overlayOpacity}
                                                                switchSides={switchSides}
                                                                zoomPosition={"left"}
                                                                inPlaceMinBreakpoint={641}
                                                                fillAvailableSpace={fillAvailableSpace}
                                                                fillAlignTop={fillAlignTop}
                                                                fillGapTop={fillGapTop}
                                                                fillGapRight={fillGapRight}
                                                                fillGapBottom={fillGapBottom}
                                                                fillGapLeft={fillGapLeft}
                                                                zoomContainerBorder="1px solid #ccc"
                                                                zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                                                            />
                                                    }
                                                </div>
                                                : ''
                                        }
                                        <div className="thumbnails text-center mt-3">
                                            {
                                                settings?.product?.have_color === 1 ?
                                                    <div>
                                                        {
                                                            settings?.product?.product_color_images?.map((image, i) => {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        onClick={(e) => {
                                                                            setSettings({
                                                                                ...settings,
                                                                                displayImage: {
                                                                                    image: `${environment.staticUrl}products/${image.image}`
                                                                                },
                                                                                state: {
                                                                                    ...settings.state,
                                                                                    color: image.color,
                                                                                },
                                                                                render: true
                                                                            });
                                                                        }}
                                                                        className="d-inline-block m-1"
                                                                        style={{
                                                                            width: 35,
                                                                            height: 35,
                                                                            cursor: 'pointer',
                                                                            borderRadius: '100%',
                                                                            backgroundColor: `#${image.color.color_code}`
                                                                        }}>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    settings?.product?.images?.map((image, i) => {
                                                        return (
                                                            <img
                                                                key={i}
                                                                onClick={(e) => {
                                                                    setSettings({
                                                                        ...settings,
                                                                        displayImage: {
                                                                            image: `${environment.staticUrl}products/${image.image}`
                                                                        },
                                                                        render: true
                                                                    });
                                                                }}
                                                                key={i}
                                                                alt=""
                                                                src={`${environment.staticUrl}products/${image.image}`}
                                                                className="img-fluid img-thumbnail"
                                                                style={{ width: 100, height: 100, cursor: 'pointer' }}
                                                            />
                                                        )
                                                    })
                                            }
                                        </div>
                                        {/* {
                                        settings?.product.product_price_sizes.map((x) => {
                                            return (
                                                <p>{ x.size['size_' + lang]?.title }</p>
                                            )
                                        })
                                    } */}
                                    </div>
                                    <div className="col-md-5 col-sm-12 mt-3">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="p-2">{t('name')}</td>
                                                    <td className="p-2">{settings.product['product_' + lang]?.title}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2">{t('description')}</td>
                                                    <td className="p-2">{settings.product['product_' + lang]?.description}</td>
                                                </tr>
                                                {
                                                    settings.product?.have_size === 0 ?
                                                        <tr>
                                                            <td className="p-2">{t('price')}</td>
                                                            <td className="p-2">{ printPrice(t, settings.product?.price)}</td>
                                                        </tr>
                                                        : ''
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            settings.product_category?.slug === 'custom-abaya' ||
                                                settings.product_category?.slug === 'jalabiya' ?
                                                <div className="mb-3">
                                                    <Link
                                                        to={`/brands/booking/the-tailor?info=${settings.product['product_' + lang]?.slug}`}
                                                        className="btn btn-dark">{t('book_appointment')}</Link>
                                                    <p className="m-0 mt-1">{t('don_t_have_measurements')}</p>
                                                </div>
                                                : ''
                                        }                                       
                                        <div className="mb-3" hidden={settings.product.have_size !== 1}>
                                            <label>{t('sizes')}</label>
                                            <select
                                                onChange={(e) => {
                                                    const price = settings.product?.product_price_sizes?.find((x) => x?.size['size_' + lang]?.slug === e.target.value);
                                                    setSettings({
                                                        ...settings,
                                                        state: {
                                                            ...settings.state,
                                                            size: e.target.value,
                                                            price: (parseFloat(price.price) * settings.state.quantity).toFixed(2)
                                                        }
                                                    })
                                                }}
                                                defaultValue={settings.state.size}
                                                value={settings.state.size}
                                                className="form-control"
                                                aria-label="Default select example">
                                                <option selected>{t('select_size')}</option>
                                                {
                                                    settings.product.product_price_sizes?.map((size, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={size?.size['size_' + lang]?.slug}
                                                                className="d-inline-block">
                                                                { size?.size['size_' + lang]?.title}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        { 
                                        settings.product_category?.slug === 'custom-abaya' ?
                                            renderSizeSelector() 
                                        :
                                        <div className="measurement" hidden={settings.product.have_measurement !== 1}>
                                            {
                                                settings.product?.product_measurements?.map((measurement, i) => {
                                                    return (
                                                        <div key={i} className="mb-3">
                                                            <label>{t(measurement.measurement['measurement_' + lang].slug.replace(/-/g, '_'))}</label>
                                                            <input
                                                                onChange={(e) => {
                                                                    setSettings({
                                                                        ...settings,
                                                                        state: {
                                                                            ...settings.state,
                                                                            measurements: {
                                                                                ...settings.state.measurements,
                                                                                [measurement.measurement['measurement_' + lang].slug.replace(/[- ]/g, '_')]: e.target.value
                                                                            }
                                                                        }
                                                                    })
                                                                }}
                                                                value={settings.state.measurements[measurement.measurement['measurement_' + lang].slug.replace(/-/g, '_')] || ''}
                                                                type="number"
                                                                className="form-control" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        }
                                        <div className="mb-3">
                                            <label>{t('quantity')}</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" value={settings.state.quantity} />
                                                <button
                                                    onClick={() => {
                                                        if (settings.state.quantity > 1) {
                                                            setSettings({
                                                                ...settings,
                                                                state: { ...settings.state, quantity: settings.state.quantity - 1 }
                                                            })
                                                        }
                                                    }}
                                                    className="btn btn-outline-secondary" type="button">-</button>
                                                <button
                                                    onClick={() => {
                                                        if (settings.state.quantity <= 100) {
                                                            setSettings({
                                                                ...settings,
                                                                state: { ...settings.state, quantity: settings.state.quantity + 1 }
                                                            })
                                                        }
                                                    }}
                                                    className="btn btn-outline-secondary"
                                                    type="button">+</button>
                                            </div>
                                        </div>
                                        <div className="prices" hidden={!settings.state.size && settings.state.size === ''}>
                                            {
                                                settings.product?.product_price_sizes?.map((price, i) => {
                                                    return (
                                                        settings.state.size === price.size['size_' + lang]?.slug ?
                                                            <div key={i} className="alert alert-info mt-1 bolder">
                                                                <label>{t('product_price')} {printLeftCenterRight(price?.price, 'x', settings.state.quantity)}</label>
                                                                &nbsp;
                                                                &nbsp;
                                                                &nbsp;
                                                                <label>{printPrice(t, (parseFloat(parseFloat(price?.price) * settings.state.quantity).toFixed(2)))}</label>
                                                                <div className="clear clearfix"></div>
                                                            </div> : ''
                                                    )
                                                })
                                            }
                                        </div>
                                        <button onClick={() => {
                                            if (settings.product.have_measurement) {
                                                let checkM = false;
                                                let key = '';
                                                settings.product?.product_measurements.forEach((measurement) => {
                                                    if (!settings.state.measurements[measurement.measurement['measurement_' + lang].slug.replace(/-/g, '_')]) {
                                                        key += measurement.measurement['measurement_' + lang].title + ', '
                                                        checkM = true;
                                                    }
                                                });
                                                if (checkM) {
                                                    swal(t('error'), `${t('invalid')} ${key}`, "error")
                                                    return false;
                                                }
                                            }
                                            if (settings.product.have_size) {
                                                if (!settings.state.size) {
                                                    swal(t("error"), t("please_select_size"), "error")
                                                    return;
                                                }
                                            }
                                            let cart = Array.isArray((lCart)) ? lCart : [];
                                            let found = {};
                                            let size = null;
                                            let price = settings.product.price;
                                            let colorKey = ``;
                                            let sizeKey = ''
                                            if (settings.state.color.id) {
                                                colorKey = `_color_${settings.state.color.id}`;
                                            }
                                            if (settings.state.size) {
                                                sizeKey = `_size_${settings.state.size}`;
                                            }
                                            let key = `_p_${sizeKey}${colorKey}`;
                                            if (settings.product.have_size === 1) {
                                                found = cart.findIndex((x) => x.pid === settings.product.id + key);
                                                const p = settings.product?.product_price_sizes?.find((x) => settings.state.size === x.size['size_' + lang]?.slug);
                                                if (p) {
                                                    price = p.price;
                                                }
                                                size = settings.state.size;
                                            } else {
                                                found = cart.findIndex((x) => x.pid === settings.product.id + key);
                                            }
                                            const p = {
                                                id: settings.product.id,
                                                pid: settings.product.id + key,
                                                image: settings.displayImage,
                                                name: settings.product[pKey + lang]?.title,
                                                price: price,
                                                size: size,
                                                color: settings.state.color['color_' + lang]?.title,
                                                quantity: settings.state.quantity,
                                                have_measurement: settings.product.have_measurement,
                                                measurement: settings.state.measurements
                                            };
                                            if (found > -1) {
                                                cart[found].quantity = cart[found].quantity + settings.state.quantity;
                                                cart[found].type = CartTypes.Product;
                                                if (!cart[found].moreProducts) {
                                                    cart[found].moreProducts = [];
                                                    cart[found].moreProducts.push(p)
                                                } else {
                                                    cart[found].moreProducts.push(p);
                                                }
                                            } else {
                                                p.type = CartTypes.Product;
                                                cart.push(p);
                                            }
                                            swal(t("cart"), t("cart_updated"), "success")
                                            const foundIndexWishList = lWishlist.findIndex((x) => x.id === settings.product.id);
                                            if (foundIndexWishList > -1) {
                                                lWishlist.splice(foundIndexWishList, 1);
                                                onSetWishListContext(lWishlist);
                                                localStorage.setItem('wishlist', JSON.stringify(lWishlist));
                                            }
                                            onSetCartContext(cart);
                                        }} className="btn btn-dark form-control mt-2">{t('add_to_cart')}</button>
                                    </div>
                                </div>
                            </> :
                            <div className="col-12 col-lg-9 text-center bolder pt-4" style={{ fontSize: 30 }}>
                                <i className="fa fa-spin fa-spinner"></i>
                            </div>
                    }
                </div>
                {
                    settings.render && !settings.loader ?
                        <>
                            <RelatedProducts
                                products={settings.related_products}
                                {...props}
                            />
                            <RecentlyViewdItemsMobile {...props} />
                        </>
                        : ''
                }
            </div>
        </div>
    )
}