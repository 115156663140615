import React from 'react';

export const Modal = (props) => {
    return (
        <div className="modal fade" id={props.id} tabIndex="-1" role="dialog">
            <div className={`modal-dialog modal-dialog-centered ${props.dialogClassName}`} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ props.title }</h5>
                        <span onClick={() => {
                            props.onClose && props.onClose();
                            window.$(`#${props.id}`).modal('hide')
                        }} aria-hidden="true" style={{ fontSize: 20 }}><i className="fa fa-times"></i></span>
                    </div>
                    { props.children }
                    { props.footer ? props.footer(props) : '' }
                </div>
            </div>
        </div>
    )
}