import { useState } from "react"
import { Axios } from "../../helpers";
import swal from 'sweetalert';
import { useTranslation } from "../../hooks/Translation";

export const ContactUsForm = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({
        isFormValid: false,
        loader: false,
        state: { name: '', email: '', subject: '', message: '' },
        errors: {
            email: [],
            password: []
        }
    });
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        Axios({}).post('/contact', settings.state).then((res) => {
            setSettings({ ...settings, loader: false, state: { name: '', email: '', subject: '', message: '' } })
        }).catch(error => {
            setSettings({ ...settings, loader: false })
        })
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    return (
        <form method="POST" onSubmit={handleSubmit}>
            <div className="mb-3">
                <label for="name" className="form-label">{t('name')}</label>
                <input
                    type="text"
                    className="form-control"
                    required={true}
                    id="name"
                    value={settings.state.name}
                    name="name"
                    onChange={handleInputChange}
                    placeholder="Your Name" />
            </div>
            <div className="mb-3">
                <label for="emailaddress" className="form-label">{t('email_address')}</label>
                <input
                    type="email"
                    required={true}
                    value={settings.state.email}
                    className="form-control"
                    id="emailaddress"
                    name="email"
                    onChange={handleInputChange}
                    placeholder="Email Address*" />
            </div>
            <div className="mb-3">
                <label for="subject" className="form-label">{t('subject')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="subject"
                    name="subject"
                    value={settings.state.subject}
                    onChange={handleInputChange}
                    placeholder="Subject" />
            </div>
            <div className="mb-3">
                <label for="message" className="form-label">{t('message')}</label>
                <textarea
                    type="text"
                    className="form-control"
                    value={settings.state.message}
                    id="message"
                    required={true}
                    name="message"
                    onChange={handleInputChange}
                    placeholder={t('message')}></textarea>
            </div>
            <button className="btn btn-dark mt-1">
                {
                    settings.loader ?
                        <i className="fa fa-spin fa-spinner"></i>
                        : <span>{t('submit')}</span>
                }
            </button>
        </form>
    )
}