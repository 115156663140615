import * as $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/js/bootstrap.js";
import { BrowserRouter } from 'react-router-dom';
import 'owl.carousel';
import AOS from 'aos';
import *  as Axios from 'axios';
import { environment } from './app/config';

window.$ = $;
window.jQuery = $;
window.jquery = $;
AOS.init({
  duration: 1000
});
Axios.interceptors.request.use((request) => {
  return request;
}, (error) => {
  return Promise.reject(error.message);
});
Axios.interceptors.response.use((response) => {
  if (response.headers['auth'] !== undefined && response.headers['auth'] === false) {
      localStorage.removeItem('token');
  }
  return response;
}, (error) => {
  return Promise.reject(error.message);
});
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
