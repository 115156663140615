import Datetime from "react-datetime";
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import { Axios, CartTypes, parseQueryString } from '../helpers';
import axios from "axios";
import Select from 'react-select'
import { environment } from "../config";
import { SideBarContext } from "../contexts/SidebarContext";
import { Materials } from "../pages/Materials";
import { AuthContext } from "../contexts/AuthContext";
import { CartContext } from "../contexts/CartContext";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { useTranslation } from "../hooks/Translation";

export const Appointment = (props) => {
    const t = useTranslation();
    const history = useHistory();
    const [settings, setSettings] = useState({
        hasMeasurements: false,
        step: 1,
        anyOneWithYou: false,
        materials: [],
        shops: [],
        notAllowedDates: [],
        allowedTimes: [],
        selectedTime: '',
        selectedDate: '',
        shopsSelect: [],
        personsSelect: [
            { label: '1 ' + t('person'), value: '1' },
            { label: '2 ' + t('person'), value: '2' },
            { label: '3 ' + t('person'), value: '3' },
            { label: '4 ' + t('person'), value: '4' },
            { label: '5 ' + t('person'), value: '5' }
        ],
        person: { label: '1 ' + t('person'), value: '1' },
        tailor: { label: t('book_a_tailor'), value: '', image: `${environment.staticUrl}no_image.png`, shop_id: -1, appointment_price: 0 }
    });
    const { cartContext, onSetCartContext, onSetRecentViewsContext, onSetWishListContext } = useContext(CartContext)
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];
    const { auth, setAuth } = useContext(AuthContext)
    const lang = localStorage.getItem('lang') || 'en';
    const isValidDate = (dt, sd) => {
        const f = settings.notAllowedDates.find((x) => {
            return moment(dt).isSame(x);
        })
        if (f) {
            return false;
        } else {
            return (dt >= (moment()))
                && (dt <= moment().add(90, 'days'))
                && moment().subtract(1, 'minute');
        }
    }
    const steps = {
        1: {
            appointment: {
                className: 'd-block col-12 p-5'
            },
            calendar: {
                className: 'd-none'
            },
            form: {
                className: 'd-none'
            }
        },
        2: {
            appointment: {
                className: 'd-block col-12 col-lg-6 p-5'
            },
            calendar: {
                className: 'd-block col-12 col-lg-6 p-0 pb-3'
            },
            form: {
                className: 'd-none'
            }
        },
        3: {
            appointment: {
                className: 'd-none col-6'
            },
            calendar: {
                className: 'd-none col-6'
            },
            form: {
                className: 'd-block col-12 p-5'
            }
        }
    }
    const classNames = steps[settings.step];
    useEffect(() => {
        getDates();
    }, [])
    const calcMaterials = (shop, r) => {
        let materials = [];
        shop.shop_materials = shop?.shop_materials?.map((sm) => {
            sm.shop = shop;
            if (sm.image) {
                sm.image = environment.staticUrl + 'materials/' + sm.image;
            } else {
                sm.image = environment.staticUrl + 'no_image.png';
            }
            return sm;
        })
        materials = [...materials, ...shop.shop_materials];
        setSettings({
            ...settings,
            ...r,
            tailor: shop,
            materials: materials,
        })
    }
    const getDates = () => {
        setAppSettings({
            ...appSettings, loading: true, sidenav: false,
            leftsidenav: false,
            loading: false
        });
        axios.all([
            Axios({}).get('/shops' + (props.params || '')),
            Axios({}).get('/booking-date'),
            Axios({}).get('/measurements'),
        ]).then(axios.spread((shopsRes, bookingDatesRes, measurementsRes) => {
            let hasMeasurements = measurementsRes.data.length > 0 ? true : false;
            if (props.onShopLoad) {
                props.onShopLoad(shopsRes.data);
            }
            if (props.params) {
                shopsRes = {
                    data: [shopsRes.data]
                }
            }
            const shopsSelectMap = shopsRes?.data?.map((shop, i) => {
                return {
                    value: shop.id,
                    shop_materials: shop.shop_materials,
                    image: shop.image ? `${environment.staticUrl}shops/${shop.image}` : environment.staticUrl + 'no_image.png',
                    shop_id: shop.id,
                    label: shop['shop_' + lang].title,
                    title: shop['shop_' + lang].title,
                    appointment_price: shop.appointment_price
                }
            });
            let tailor = settings.tailor;
            const auth = { auth: JSON.parse(localStorage.getItem('auth')) } || auth;
            if (props.updateState) {
                const hasVal = props.updateState.person.value > 0;
                const s = {
                    ...settings,
                    person: props.updateState.person,
                    tailor: props.updateState.tailor,
                    anyOneWithYou: hasVal,
                    tailor: tailor,
                    selectedDate: props.updateState.selectedDate,
                    selectedTime: props.updateState.selectedTime,
                    shopsSelect: shopsSelectMap,
                    hasMeasurements: hasMeasurements
                };
                setSettings(s)
                if ((props.params || auth.auth.profile > 0) && shopsSelectMap.length > 0) {
                    tailor = shopsSelectMap[0];
                    setTimeout(() => {
                        calcMaterials(tailor, s);
                    }, 1000);
                }
            } else {
                const s = {
                    ...settings,
                    tailor: tailor,
                    notAllowedDates: bookingDatesRes?.data?.data || [],
                    shops: shopsRes.data,
                    shopsSelect: shopsSelectMap,
                    hasMeasurements: hasMeasurements
                }
                if ((props.params || auth.auth.profile > 0) && shopsSelectMap.length > 0) {
                    tailor = shopsSelectMap[0];
                    setTimeout(() => {
                        calcMaterials(tailor, s);
                    }, 1000);
                }
                setSettings(s);
            }
            setAppSettings({
                ...appSettings, loading: false, sidenav: false,
                leftsidenav: false,
                loading: false
            });
        })).catch((error) => {
            setAppSettings({
                ...appSettings, loading: false, sidenav: false,
                leftsidenav: false,
                loading: false
            });
        })
    }
    const handleSubmit = () => {
        setAppSettings({ ...appSettings, loading: true });
        let info = '';
        try {
            info = parseQueryString(window.location.search);
        } catch (e) { }
        Axios({}).post('/add-appointment', {
            shop_id: settings.tailor.shop_id,
            booking_date: moment(settings.selectedDate).format('YYYY-MM-DD'),
            booking_time: settings.selectedTime,
            ttl_ppl: settings.person.value,
            note: info,
        }).then((res) => {
            setAppSettings({ ...appSettings, loading: false });
            setSettings({
                ...settings,
                step: 1,
                anyOneWithYou: false,
                notAllowedDates: [],
                allowedTimes: [],
                selectedTime: '',
                selectedDate: '',
                personsSelect: [
                    { label: '1 ' + t('person'), value: '1' },
                    { label: '2 ' + t('person'), value: '2' },
                    { label: '3 ' + t('person'), value: '3' },
                    { label: '4 ' + t('person'), value: '4' },
                    { label: '5 ' + t('person'), value: '5' }
                ],
                person: { label: '1 ' + t('person'), value: '1' },
                tailor: { label: 'Any', value: '', image: `${environment.staticUrl}no_image.png`, shop_id: -1 }
            });
            let cart = Array.isArray(lCart) ? lCart : [];
            const found = cart.findIndex((x) => x.id + '_app' === res.data.appointment_data.id + '_app');
            const p = {
                id: res.data.appointment_data.id,
                pid: res.data.appointment_data.id + '_app',
                name: 'Appointment',
                price: settings.tailor.appointment_price,
                quantity: 1,
                person: settings.person.value,
                image: {
                    image: settings.tailor.image
                }
            };
            if (found > -1) {
                swal(t("cart"), t("already_in_the_cart"), "success")
                return;
            } else {
                p.type = CartTypes.Appointment;
                cart.push(p);
            }
            swal(t("cart"), t("cart_updated"), "success")
            history.push('/checkout');
            onSetCartContext(cart);
        }).catch((error) => {
            setAppSettings({ ...appSettings, loading: false });
        })
    }
    const handleDateChange = (d, s) => {
        setSettings({
            ...settings,
            ...s,
            timeLoader: true,
            allowedTimes: []
        });
        axios.all([
            Axios({
                'hl': false
            }).post('/booking-date-time', { date: moment(d).format('YYYY-MM-DD') })
        ]).then(axios.spread((bookingDateTimeRes) => {
            setSettings({
                ...settings,
                ...s,
                selectedDate: d,
                allowedTimes: bookingDateTimeRes.data.data,
                timeLoader: false
            });
        }))
    }
    const handleTimeChange = (d) => {
        setSettings({
            ...settings,
            selectedTime: d
        });
    }

    return (
        <>
            <div className="appointment" style={{ minHeight: 200 }}>
                {/* <div className="pt-5 d-lg-none"></div>
                <div className="pt-5 d-lg-none"></div> */}
                <div className="row p-0 m-0">
                    <div className={classNames.appointment.className}>
                        {/* <h5 className="text-center"><strong>{auth.auth.profile < 1 ? t('book_appointment') : t('select_material')}</strong></h5> */}
                        {
                            props.showType ?
                                <div className="mb-3">
                                    <label htmlFor="apptype text-left">{t('tailor')}</label>
                                    <Select
                                        value={settings.tailor}
                                        onChange={(e) => {
                                            setSettings({
                                                ...settings,
                                                tailor: e
                                            })
                                            calcMaterials(e, settings);
                                        }}
                                        options={settings.shopsSelect} />
                                </div>
                                : ''
                        }
                        {
                            props.withSelect !== true ?
                                <div className="row">
                                    <div className="col-10">
                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{t('bringing_others')}</label>
                                    </div>
                                    <div className="col-2" style={{ textAlign: 'right' }}>
                                        <div className="form-check form-switch mb-3 pull-right" style={{ textAlign: 'right' }}>
                                            <input
                                                onClick={() => { setSettings({ ...settings, anyOneWithYou: !settings.anyOneWithYou }) }}
                                                className="form-check-input"
                                                value={settings.anyOneWithYou}
                                                checked={settings.anyOneWithYou}
                                                type="checkbox" id="flexSwitchCheckChecked" />
                                        </div>
                                    </div>
                                </div> : ''
                        }
                        {
                            settings.anyOneWithYou && props.withSelect !== true ?
                                (
                                    <div className="mb-3">
                                        <label htmlFor="apptype text-left">{t('bringing_others')}{t('number_of_persons')}</label>
                                        <Select
                                            value={settings.person}
                                            onChange={(e) => {
                                                setSettings({
                                                    ...settings,
                                                    person: e
                                                })
                                            }}
                                            options={settings.personsSelect} />
                                    </div>

                                )
                                : ''
                        }
                        {
                            settings.step === 1 && props.withSelect !== true ?
                                <div className="text-center">
                                    <button onClick={() => {
                                        if (window.innerWidth < 1024) {
                                            const rootElement = document.documentElement;
                                            rootElement.scrollTo({
                                                top: 300,
                                                behavior: "smooth"
                                            });
                                        }
                                        if (props.updateState) {
                                            handleDateChange(props.updateState.selectedDate, { step: 2 })
                                        } else {
                                            setSettings({ ...settings, step: 2 })
                                        }
                                    }} className="btn btn-dark btn-sm">{t('continue')}</button>
                                </div> : ''
                        }
                    </div>
                    <div
                        style={{
                            background: '#97a8a0',
                            color: 'white'
                        }}
                        className={classNames.calendar.className}>
                        <Datetime
                            onChange={(e) => { handleDateChange(e, {}) }}
                            renderDay={(props, currentDate, selectedDate) => {
                                return (
                                    <>
                                        <td {...props}>{currentDate.date()}</td>
                                    </>
                                )
                            }}
                            value={settings.selectedDate}
                            isValidDate={isValidDate}
                            input={false} />
                        {
                            settings.timeLoader ?
                                <div className="text-center bolder">
                                    <i className="fa fa-spin fa-spinner"></i>
                                </div>
                                : ''
                        }
                        {
                            settings.selectedDate ?
                                <div className="times-block p-4 text-center">
                                    {
                                        settings?.allowedTimes?.map((t, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={t === settings.selectedTime ? 'times active d-time d-inline-block m-1 p-1' : 'times d-time d-inline-block m-1 p-1'}
                                                    onClick={() => {
                                                        handleTimeChange(t)
                                                    }}>{t}</div>
                                            )
                                        })
                                    }
                                </div>
                                : ''
                        }
                        <div className="text-center">
                            {
                                settings.selectedTime && settings.selectedDate ?
                                    <button
                                        onClick={() => {
                                            if (!settings.tailor.value) {
                                                const l = settings.shopsSelect;
                                                let tailor = l[Math.floor(Math.random(0, l.length) * l.length + 1)]
                                                setSettings({ ...settings, step: 3, tailor })
                                            } else {
                                                setSettings({ ...settings, step: 3 })
                                            }
                                        }} className="btn btn-dark btn-sm">{t('continue')}</button>
                                    : ''
                            }
                        </div>
                    </div>
                    <div className={classNames.form.className}>
                        <div>
                            <div className="form">
                                <div className="text-center">
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            background: `url(${settings.tailor.image})`,
                                            width: 100,
                                            height: 100,
                                            borderRadius: 100,
                                            margin: '0 auto'
                                        }}
                                        className="avatar mb-4">
                                    </div>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>{t('tailor')}:</th>
                                            <th>{t('date')}</th>
                                            <th>{t('local_time')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{settings.tailor.label}</td>
                                            <td>{moment(settings.selectedDate).format('MMM Do YYYY')}</td>
                                            <td>{settings.selectedTime}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="row mb-2" style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    <div className="col-6">
                                        {t('total_number_person')}:
                                    </div>
                                    <div className="col-6" style={{ textAlign: 'right' }}>
                                        {settings.person.value}
                                    </div>
                                </div>
                                <div className="row mb-2" style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    <div className="col-6">
                                        {t('base_price')}:
                                    </div>
                                    <div className="col-6" style={{ textAlign: 'right' }}>
                                        {settings.tailor.appointment_price}
                                    </div>
                                </div>
                                <div className="row mb-3" style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    background: '#eee',
                                    color: '#aaa',
                                    marginLeft: '0px',
                                    marginRight: '0px',
                                    padding: '1ex'
                                }}>
                                    <div className="col-6 p-0">
                                        {t('total_cost')}:
                                    </div>
                                    <div className="col-6 p-0" style={{ textAlign: 'right' }}>
                                        {settings.tailor.appointment_price}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button onClick={() => {
                                    if (!props.updateState) {
                                        handleSubmit()
                                    } else {
                                        props.onSubmit && props.onSubmit(settings);
                                    }
                                }} className="btn btn-dark btn-sm"> {t('submit')}</button>
                                &nbsp;
                                <button onClick={() => { setSettings({ ...settings, step: 2 }) }} className="btn btn-dark btn-sm">{t('cancel')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                !props.updateState && settings.materials.length > 0 && settings.hasMeasurements > 0 ?
                    <Materials
                        withProfile={props.withProfile}
                        profile={props.profile}
                        materials={settings.materials} />
                    : <div className="mb-5" style={{ minHeight: 300 }}>
                    </div>
            }
        </>
    )
}