import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { AuthContext } from "../contexts/AuthContext";
import { CartContext } from "../contexts/CartContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios, CartTypeIds, CartTypes, parseQueryString, printPrice } from "../helpers";
import { useTranslation } from "../hooks/Translation";
import { Modal } from "../components/Modal";

const DifferentAddress = (props) => {
    const t = useTranslation();
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div className="mb-3">
                        <label for="dfname" className="form-label">{t('name')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="dfname"
                            placeholder="First Name*" />
                    </div>
                </div>
                <div className="col-6">
                    <div className="mb-3">
                        <label for="dlname" className="form-label">{t('name')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="dlname"
                            placeholder={t('name') + "*"} />
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <strong>Qatar</strong>
                <p className="p-0 m-0">Country / Region</p>
            </div>
            <div className="mb-3">
                <label for="dstreetaddress" className="form-label">Street Address</label>
                <input
                    type="text"
                    className="form-control"
                    id="dstreetaddress"
                    placeholder="Street Address*" />
            </div>
            <div className="mb-3">
                <label for="dtowncity" className="form-label">Town / City</label>
                <input
                    type="text"
                    className="form-control"
                    id="dtowncity"
                    placeholder="Town / City*" />
            </div>
            <div className="mb-3">
                <label for="dstatecountry" className="form-label">State / Country</label>
                <input
                    type="text"
                    className="form-control"
                    id="dstatecountry"
                    placeholder="State / Country*" />
            </div>
            <div className="mb-3">
                <label for="dpostalzip" className="form-label">Postal / Zip</label>
                <input
                    type="text"
                    className="form-control"
                    id="dpostalzip"
                    placeholder="Postal / Zip*" />
            </div>
        </>
    )
}

export const Checkout = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({
        showLogin: false,
        differentAddress: false,
        paymentForm: '',
        status: '',
        state: {
            da_name: '',
            order_note: '',
            contact: '',
            city: '',
            da_address: '',
            is_default: true,
            email: '',
            slug: ''
        }
    });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const { auth, setAuth } = useContext(AuthContext)
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];
    const printTotalF = (props) => {
        let total = [];
        lCart.forEach((cart, index) => {
            if (cart.price && cart.quantity > 0) {
                let t = parseFloat((parseFloat(cart.price)) * cart.quantity).toFixed(2);
                total.push(t);
            }
        });
        const types = Array.from(new Set(lCart.map((x) => x.type)));
        let priceToAdd = 0;
        if (types.length === 1 && types.find((x) => x === CartTypes.Appointment)) {
            priceToAdd = 0;
        } else {
            priceToAdd = t('ship_price') === 'ship_price' ? 0 : parseFloat(t('ship_price'));
        }
        const price = total.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
        if (parseFloat(price) > 1000) {
            priceToAdd = 0;
        }
        return parseFloat(parseFloat(price) + priceToAdd).toFixed(2);
    }

    useEffect(() => {
        let status = '';
        try {
            const l = parseQueryString(window.location.search);
            status = l.status;
            setSettings({ ...settings, status: l.status });
            if (l.status === 'success') {
                onSetCartContext({ ...cartContext, cart: [] });
                localStorage.setItem('cart', JSON.stringify([]));
            }
        } catch (e) {

        }
        setAppSettings({ ...appSettings, show: false, sidenav: false });
        axios.all([
            Axios({}).get('/default-delivery-addresses'),
        ]).then(axios.spread((addressRes) => {
            setSettings({
                ...settings,
                status,
                state: { ...addressRes.data, da_address: addressRes.data.address, da_name: addressRes.data.name },
            })
            setAppSettings({ ...appSettings, sidenav: false, show: false, loading: false });
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, show: false, loading: false });
        });
    }, []);
    const printTotal = (props) => {
        let total = [];
        lCart.forEach((cart, index) => {
            if (cart.price && cart.quantity > 0) {
                let t = parseFloat((parseFloat(cart.price)) * cart.quantity).toFixed(2);
                total.push(t);
            }
        });
        const types = Array.from(new Set(lCart.map((x) => x.type)));
        let priceToAdd = 0;
        if (types.length === 1 && types.find((x) => x === CartTypes.Appointment)) {
            priceToAdd = 0;
        } else {
            priceToAdd = t('ship_price') === 'ship_price' ? 0 : parseFloat(t('ship_price'));
        }
        const price = total.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
        if (parseFloat(price) > 1000) {
            priceToAdd = 0;
        }
        return printPrice(t, parseFloat(parseFloat(price) + priceToAdd).toFixed(2));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setAppSettings({ ...appSettings, loading: true });
        const formData = new FormData();
        formData.append('email', settings.state.email);
        formData.append('contact', settings.state.contact);
        formData.append('phone', settings.state.contact);
        formData.append('address', settings.state.address);
        formData.append('order_note', settings.state.order_note);
        formData.append('city', settings.state.city);
        formData.append('name', auth?.auth?.name);
        formData.append('u_address', settings.state.id);
        formData.append('total_price', printTotalF());
        const items = []
        lCart.forEach((cart, index) => {
            const { key, id } = getIdAndKey(cart);
            const productDetails = {
                color: cart.color,
                size: cart.size
            };
            if (cart.measurement) {
                if (Object.keys(cart.measurement).length > 0) {
                    Object.keys(cart.measurement).forEach((m) => {
                        productDetails[m.replace(/[-_]/, ' ')] = cart.measurement[m];
                    });
                }
            }
            let item = {};
            if (cart.type === CartTypes.Material) {
                item.material_profile_id = cart.material_profile_id;
            }
            item.product_detail = JSON.stringify(productDetails);
            item.type = cart.type;
            item.qty = cart.quantity;
            item.price = cart.price;
            if (item.type === CartTypes.StaticTShirt) {
                item['customize_size'] = cart.size;
                item['customize_name'] = cart.name;
                item['customize_number'] = cart.number;
                cart.files.forEach((f, i) => {
                    item['customize_image_' + (i)] = f.image;
                })
            }
            item[key] = id;
            items.push(item);
        });
        createFormData(formData, 'items', items);
        Axios({ "Content-Type": "multipart/form-data", hl: false }).post('/add-order', formData).then((res) => {
            setSettings({
                ...settings,
                paymentForm: res.data.data
            })
            setAppSettings({ ...appSettings, loading: false });
            // window.$('#checkoutForm').modal('show')
            window.$('#form-container').html(res.data.data);
            document.gosadad.submit();
        }).catch((error) => {
            setAppSettings({ ...appSettings, loading: false });
        })
    }
    function parseHTML(html) {
        var t = document.createElement('template');
        t.innerHTML = html;
        return t.content.cloneNode(true);
    }
    const createFormData = (formData, key, data) => {
        if (data === Object(data) || Array.isArray(data)) {
            for (var i in data) {
                createFormData(formData, key + '[' + i + ']', data[i]);
            }
        } else {
            formData.append(key, data);
        }
    }
    const getIdAndKey = (cart) => {
        if (CartTypeIds[cart.type]) {
            const cartTypeKey = CartTypeIds[cart.type];
            return { key: cartTypeKey, id: cart.id };
        } else {
            throw new Error('type ids not found.');
        }
    }
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    function createMarkup() {
        return { __html: settings.paymentForm };
    }
    return (
        <div className="container cart mb-5">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <Modal {...props} id="checkoutForm" title={t('checkout')}>
                <div className="modal-body p-0">
                    <div id="form-container"></div>
                </div>
            </Modal>
            <h1 className="app-title">{t('checkout')}</h1>
            {
                settings.status === 'success' ?
                    <div className="alert alert-success text-center">{t('pay_success_message')}</div>
                    : <div className="row">
                        <div className="col-12 col-lg-8">
                            {
                                settings.status === 'failed' ?
                                    <div className="alert alert-danger text-center">{t('pay_failed_message')}</div>
                                    : ''
                            }
                            <form method="POST" onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label for="dname" className="form-label">{t('name')}</label>
                                    <input
                                        value={auth?.auth?.name}
                                        name="da_name"
                                        onChange={handleInputChange}
                                        type="text"
                                        className="form-control"
                                        required={true}
                                        readOnly={false}
                                        id="da_name"
                                        placeholder={t('name') + "*"} />
                                </div>
                                <div className="col-6">
                                </div>
                                <div className="mb-3">
                                    <label for="streetaddress" className="form-label">{t('address')}</label>
                                    <input
                                        type="text"
                                        name="da_address"
                                        onChange={handleInputChange}
                                        value={settings.state.da_address || ''}
                                        className="form-control"
                                        readOnly={false}
                                        required={true}
                                        id="streetaddress"
                                        placeholder={t('address') + "*"} />
                                </div>
                                <div className="mb-3">
                                    <label for="towncity" className="form-label">{t('town_city')}</label>
                                    <input
                                        type="text"
                                        required={true}
                                        className="form-control"
                                        readOnly={false}
                                        value={settings.state.city || ''}
                                        id="towncity"
                                        name="city"
                                        onChange={handleInputChange}
                                        placeholder={t('town_city') + "*"} />
                                </div>
                                <div className="mb-3">
                                    <label for="phone" className="form-label">{t('phone')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly={false}
                                        value={settings.state.contact || ''}
                                        id="phone"
                                        required={true}
                                        name="contact"
                                        onChange={handleInputChange}
                                        placeholder={t('phone') + "*"} />
                                </div>
                                <div className="mb-3">
                                    <label for="emailaddress" className="form-label">{t('email_address')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly={false}
                                        value={settings.state.email || ''}
                                        id="emailaddress"
                                        name="email"
                                        required={true}
                                        onChange={handleInputChange}
                                        placeholder={t('email_address') + "*"} />
                                </div>
                                {/* <h4><strong>SHIPPING DETAILS</strong></h4>
                            <div className="mb-3">
                                <div className="form-check">
                                    <input onChange={(e) => {
                                        setSettings({ ...settings, differentAddress: e.target.checked })
                                    }} 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    checked={settings.differentAddress}
                                    value={!settings.differentAddress} id="rememberme" />
                                    <label className="form-check-label" for="rememberme">
                                        Ship to a different address?
                                    </label>
                                </div>
                            </div> */}
                                {/* {
                                settings.differentAddress ?
                                    <DifferentAddress {...props} />
                                : ''
                            } */}
                                <div className="mb-3">
                                    <label for="ordernotes" className="form-label">{t('order_notes')}</label>
                                    <textarea
                                        type="text"
                                        value={settings.state.order_note || ''}
                                        className="form-control"
                                        id="ordernotes"
                                        name="order_note"
                                        onChange={handleInputChange}
                                        placeholder={t('notes_about_your_order')}>
                                    </textarea>
                                </div>
                                <div className="mb-3">
                                    <button className="btn btn-dark">{t('pay_now')}</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-lg-4 mt-3">
                            <h3 style={{
                                padding: '8px',
                                color: '#555',
                                background: '#ccc',
                                margin: 0
                            }}><strong>{t('cart_totals')}</strong></h3>
                            <div className="shipping-info" style={{
                                padding: '2ex',
                                backgroundColor: '#ddd'
                            }}>
                                <h6>{t('shipping')}</h6>
                                {/* {
                                    printTotalF() > 1000 ?
                                        <>
                                            <h6>{t('free_delivery')}</h6>
                                            <h6>{t('shipping_to')} <strong>{t('to_doha')}</strong></h6>
                                        </>
                                        :
                                        <>
                                            <h6>{t('charges_for_delivery')}</h6>
                                            <h6>{t('shipping_to')} <strong>{t('to_doha')}</strong></h6>
                                        </>
                                } */}
                                <h6>{t('free_delivery')}</h6>
                                <h6>{t('shipping_to')} <strong>{t('to_doha')}</strong></h6>
                                {/* <p style={{
                            padding: '8px',
                            margin: 0,
                            color: '#555',
                            background: '#eee'
                        }}><i className="fa fa-truck"></i> {t('you_got_free_shipping')}</p> */}
                                <button className="form-control btn btn-dark mt-2">{t('total')} {printTotal()}</button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}