import { Appointment } from "../../components/Appointment"
import { BrandsComponent } from "../../components/BrandsComponent"
import { Brands } from "../Brands"
import { Shops } from "../Shops"

export const SchoolUniform = (props) => {
    return (
        <div className="school-uniforms">
            <BrandsComponent
                showCounter={false}
                title="School Uniforms"
                category="children"
                {...props} />
            {/* <div className="row">
                <div className="col-10 offset-1">
                    <Appointment {...props} />
                </div>
            </div> */}
        </div>
    )
}