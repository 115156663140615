import { useContext, useEffect, useState } from "react";
import { ContactUsForm } from "../components/Forms/ContactUsForm";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios } from "../helpers";
import { useTranslation } from "../hooks/Translation";

export const Faq = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ faqs: [] });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const lang = localStorage.getItem('lang') || 'en';
    useEffect(() => {
        setAppSettings({ ...appSettings, show: false, leftsidenav: false, sidenav: false, loading: true });
        Axios({}).get('/faqs').then((res) => {
            setSettings({ ...settings, faqs: res.data });
            setAppSettings({ ...appSettings, loading: false });
        }).catch((error) => {
            setAppSettings({ ...appSettings, loading: false });
        })
    }, []);
    return (
        <div className="brands pb-5">
            <div className="header" style={{ backgroundColor: '#aaa' }}>
                <h1 style={{ flex: 0.1 }}><strong>{t('faq')}</strong></h1>
            </div>
            <div className="container p-3 pt-0">
                <div className="mb-3">
                    <h3>{t('how_much_is_the_charge_time')}</h3>
                </div>
                <div className="mb-3">
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>{t('delivery_type')}</th>
                                    <th>{t('charges')}</th>
                                    <th>{t('time_frame')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{t('3_hour_pack_and_collect')}3 hour pack and collect</th>
                                    <td>{t('free')}</td>
                                    <td>{t('faq_p1')} </td>
                                </tr>
                                <tr>
                                    <th>{t('store_transfer')}</th>
                                    <td>{t('free')}</td>
                                    <td>
                                        <span>{t('faq_p2')} :</span>
                                        <ul>
                                            <li>{t('faq_p3')}</li>
                                            <li>{t('faq_p4')}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('special_day_delivery')}</th>
                                    <td>{t('free')}</td>
                                    <td>{t('faq_p5')} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <h4 className="bolders">{t('questions_answers')}</h4>
                <div className="mb-3">
                    <div className="accordion" id="faq">
                        {
                            settings.faqs && settings.faqs.map((faq, i) => {
                                return (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapseOne${i}`}
                                                aria-expanded="true"
                                                aria-controls="collapseOne">
                                                {faq['faq_' + lang]?.question}
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapseOne${i}`}
                                            className={i === 0 ? `accordion-collapse collapse show` : `accordion-collapse collapse`}
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#faq">
                                            <div className="accordion-body">
                                                {faq['faq_' + lang]?.answer}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="mb-3 mt-3">
                    <h3>{t('faq_p6')}</h3>
                    <ContactUsForm {...props} showSubject={true} />
                </div>
            </div>
        </div>
    );
}