import { useState } from 'react'
import { Link } from 'react-router-dom';
import { environment } from '../config';
import { useTranslation } from "../hooks/Translation";
export const Carousel = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ activeIndex: 0 });
    const lang = localStorage.getItem('lang') || 'en';
    return (
        <>
            <div id={props.id || 'carouselExampleDark'} className="carousel carousel-dark slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {
                        props.sliders.map((slider, k) => {
                            return (
                                <>
                                    <button
                                        key={k}
                                        type="button"
                                        data-bs-target={`#${props.id}`}
                                        data-bs-slide-to={(k)}
                                        className={settings.activeIndex === k ? 'active' : ''}
                                        aria-current="true"
                                        aria-label="Slide 1">
                                    </button>
                                </>
                            )
                        })
                    }
                </div>
                <div className="carousel-inner">
                    {
                        props.sliders.map((slider, k) => {
                            return (
                                <div
                                    key={k}
                                    className={settings.activeIndex === k ? 'carousel-item active' : 'carousel-item'}
                                    data-bs-interval="3000">
                                    {/* <img src={{ backgroundImage: `url(${slider.img})` }} className="item-img img-fluid" /> */}
                                    <div className="item-img d-block w-100" style={{ backgroundImage: `url(${slider.img})` }}></div>
                                    <div className="carousel-caption">
                                        <h1 className="bolder whiteshadow">{slider['slider_' + lang]?.title}</h1>
                                        <h4 className="whiteshadow">{slider['slider_' + lang]?.description}</h4>
                                        <Link to={slider?.url} className="btn btn-sm btn-dark">{t('more')}</Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target={`#${props.id}`} data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">{t('previous')}</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target={`#${props.id}`} data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">{t('next')}</span>
                </button>
            </div>
        </>
    )
}

export const CarouselV2 = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ activeIndex: 0 });
    const lang = localStorage.getItem('lang') || 'en';
    return (
        <>
            <div id={props.id || 'carouselExampleDark'} className="carousel carouselv2 carousel-dark slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {
                        props.sliders.map((slider, k) => {
                            return (
                                <>
                                    <button
                                        key={k}
                                        type="button"
                                        data-bs-target={`#${props.id}`}
                                        data-bs-slide-to={(k)}
                                        className={settings.activeIndex === k ? 'active' : ''}
                                        aria-current="true"
                                        aria-label="Slide 1">
                                    </button>
                                </>
                            )
                        })
                    }
                </div>
                <div className="carousel-inner">
                    {
                        props.sliders.map((slider, k) => {
                            return (
                                <div
                                    key={k}
                                    className={settings.activeIndex === k ? 'carousel-item active' : 'carousel-item'}
                                    data-bs-interval="3000">
                                    {/* <img src={{ backgroundImage: `url(${slider.img})` }} className="item-img img-fluid" /> */}
                                    <div className="item-img d-block w-100" style={{ backgroundImage: `url(${slider.img})` }}></div>
                                    <div className="carousel-caption">
                                        <h1 className="bolder whiteshadow m-0">{slider?.title_1}</h1>
                                        <h1 className="bolder whiteshadow m-0">{slider?.title_2}</h1>
                                        <p className="whiteshadow" style={{ fontWeight: 'normal' }}>{slider?.description}</p>
                                        {/* <Link to={slider?.url} className="btn btn-sm btn-dark">{t('more')}</Link> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target={`#${props.id}`} data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">{t('previous')}</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target={`#${props.id}`} data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">{t('next')}</span>
                </button>
            </div>
        </>
    )
}