import { Brands } from "./pages/Brands";
import { Home } from "./pages/Home";
import { MyAccount } from "./pages/MyAccount";
import { BookATailor } from "./pages/BookATailor";
import { ContactUs } from "./pages/ContactUs";
import { WhishList } from "./pages/WhishList";
import { Shops } from "./pages/Shops";
import { Products } from "./pages/Products";
import { ProductCategory } from "./pages/ProductCategory";
import { SingleProduct } from "./pages/SingleProduct";
import { Checkout } from "./pages/Checkout";
import { Cart } from "./pages/Cart";
import { SlugPages } from "./pages/slug/SlugPages";
import { RequetAQoute } from "./pages/RequestAQoute";
import { UserMeasurements } from "./components/Auth/UserMeasurements";
import { Orders } from "./components/Auth/Orders";
import { Addresses } from "./components/Auth/Addresses";
import { AccountDetails } from "./components/Auth/AccountDetails";
import { BrandsBooking } from "./pages/BrandsBooking";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { Faq } from "./pages/Faq";
import { BrandsByType } from "./pages/BrandsByType";
import { TailorShops } from "./pages/TailorShops";
import { CustomRequest } from "./components/Auth/CustomRequest";
import { WithAuth } from "./components/Auth/WithAuth";
import { ThankYou } from "./pages/ThankYou";
import { ProductChildCategory } from "./pages/ProductChildCategory";
import { ActivationLink } from "./pages/ActivationLink";
import { ResendActivationCode } from "./pages/ResendActivationCode";
import { ProductCategoryChild } from "./pages/ProductCategoryChild";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsAndCondition } from "./pages/TermsAndCondition";
import { StaticProduct } from "./pages/StaticProduct";
import { HomeV2 } from "./pages/v2/HomeV2";
import { TailorAndCustomizeAbaya } from "./pages/v2/TailorAndCustomizeAbaya";
import { SocialAppLogin } from "./pages/SocialAppleLogin";
import { BrandsProducts } from "./pages/BrandsProducts";

export const routes = [
    {
        path: '/homev1',
        name: 'HomeV1',
        component: HomeV2,
        tkey: 'home',
        exact: true
    },
    {
        path: '/',
        name: 'Home',
        tkey: 'home',
        component: HomeV2,
        exact: true
    },
    {
        path: '/custom/:slug',
        name: 'Tailor and Customize Abaya',
        tkey: 'tailor-and-customize-abaya',
        component: TailorAndCustomizeAbaya,
        exact: true
    },
    {
        path: '/tailor-shops',
        name: 'Tailor Shops',
        tkey: 'tailor-shops',
        component: TailorShops,
        exact: true
    },
    {
        path: '/thank-you',
        name: 'Thank You',
        tkey: 'thank-you',
        component: ThankYou,
        exact: true
    },
    {
        path: '/brands',
        name: 'Brands',
        tkey: 'brands',
        component: Brands,
        exact: true
    },
    {
        path: '/brands-products/:slug',
        name: 'Brands Products',
        tkey: 'brands',
        component: BrandsProducts,
        exact: true
    },
    {
        path: '/brands/:slug',
        name: 'A Brand',
        tkey: 'brands',
        component: BrandsByType,
        exact: true
    },
    {
        path: '/brands/booking/:slug',
        name: 'Brands Booking',
        tkey: 'brands-booking',
        component: WithAuth(BrandsBooking),
        exact: true
    },
    {
        path: '/my-account',
        name: 'My Account',
        tkey: 'my-account',
        component: MyAccount,
        exact: true
    },
    {
        path: '/my-account/forgotpassword',
        name: 'Forgot Password',
        tkey: 'forgot-password',
        component: ForgotPassword,
        exact: true
    },
    {
        path: '/my-account/activation',
        name: 'Activation',
        tkey: 'activation',
        component: ActivationLink,
        exact: true
    },
    {
        path: '/my-account/resend',
        name: 'Resend Activation Code',
        tkey: 'resend-activation-code',
        component: ResendActivationCode,
        exact: true
    },
    {
        path: '/my-account/resetpassword',
        name: 'Reset Password',
        tkey: 'reset-password',
        component: ResetPassword,
        exact: true
    },
    {
        path: '/my-account/measurements',
        name: 'Measurements',
        tkey: 'measurements',
        component: WithAuth(UserMeasurements),
        exact: true
    },
    {
        path: '/my-account/orders',
        name: 'Orders',
        tkey: 'orders',
        component: WithAuth(Orders),
        exact: true
    },
    {
        path: '/my-account/custom-requests',
        name: 'Custom Requests',
        tkey: 'custom-requests',
        component: WithAuth(CustomRequest),
        exact: true
    },
    {
        path: '/my-account/addresses',
        name: 'Addresses',
        tkey: 'addresses',
        component: WithAuth(Addresses),
        exact: true
    },
    {
        path: '/my-account/account-details',
        name: 'Account Details',
        tkey: 'account-details',
        component: WithAuth(AccountDetails),
        exact: true
    },
    {
        path: '/book-a-tailor',
        name: 'Book A Tailor',
        tkey: 'book-a-tailor',
        component: WithAuth(BookATailor),
        exact: true
    },
    {
        path: '/contact-us',
        name: 'Contact Us',
        tkey: 'contacut-us',
        component: ContactUs,
        exact: true
    },
    {
        path: '/wishlist',
        name: 'Wishlist',
        tkey: 'wishlist',
        component: WhishList,
        exact: true
    },
    {
        path: '/shops',
        name: 'Shops',
        tkey: 'shops',
        component: Shops,
        exact: true
    },
    {
        path: '/shops/:slug',
        name: 'Single Product',
        tkey: 'single-product',
        component: SingleProduct,
        exact: true
    },
    {
        path: '/products',
        name: 'Shops',
        tkey: 'shops',
        component: Products,
        exact: true
    },
    {
        path: '/products-child/:slug',
        name: 'Product Category',
        tkey: 'product-category',
        component: ProductChildCategory,
        exact: true
    },
    {
        path: '/products/:slug',
        name: 'Product Category',
        tkey: 'product-category',
        component: ProductCategoryChild,
        exact: true
    },
    {
        path: '/social-login/apple',
        name: 'Apple Login',
        tkey: 'apple-login',
        component: SocialAppLogin,
        exact: true
    },
    {
        path: '/terms-and-condition',
        name: 'Terms And Condition',
        tkey: 'terms-and-condition',
        component: TermsAndCondition,
        exact: true
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        tkey: 'privacy-policy',
        component: PrivacyPolicy,
        exact: true
    },
    {
        path: '/cart',
        name: 'Cart',
        tkey: 'cart',
        component: Cart,
        exact: true
    },
    {
        path: '/checkout',
        name: 'Checkout',
        tkey: 'checkout',
        component: WithAuth(Checkout),
        exact: true
    },
    {
        path: '/request-a-qoute',
        name: 'Request A Qoute',
        tkey: 'request-a-qoute',
        component: RequetAQoute,
        exact: true
    },
    {
        path: '/faq',
        name: 'FAQ',
        tkey: 'faq',
        component: Faq,
        exact: true
    },
    {
        path: '/sports-wear',
        name: 'Sports Wear',
        tkey: 'sports-wear',
        component: StaticProduct,
        exact: true
    },
    {
        path: '/:slug',
        isRootSlugRoute: true,
        tkey: 'slug-pages',
        name: '',
        component: SlugPages,
        exact: true
    }
];