import { useContext, useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import { AuthContext } from "../contexts/AuthContext";
import { CartContext } from "../contexts/CartContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios } from "../helpers";
import { MyAccount } from "./MyAccount";
import { useTranslation } from "../hooks/Translation";


export const RequetAQoute = (props) => {
    let fRef = useRef(null);
    const t = useTranslation();
    const [settings, setSettings] = useState({
        isUpdated: false,
        isFormValid: false,
        loader: false,
        message: '',
        files: [],
        state: {
            note: '',
            quantity: 1,
            files: [],
        },
        errors: {
            email: [],
            password: []
        }
    });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const { auth } = useContext(AuthContext)
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];
    useEffect(() => {
        if (props.state) {
            setSettings({
                ...settings,
                state: {
                    ...props.state,
                    quantity: props.state.qty
                }
            })
        }
    }, [])
    const handleSubmit = (event) => {
        event.preventDefault();
        setAppSettings({ ...appSettings, loading: true });
        let url = '';
        if (props.isEdit) {
            url = `/update-custom-request/${settings.state.id}`
        } else {
            url = `/add-custom-request`
        }
        event.preventDefault();
        if (settings.state.files.length === 0) {
            swal("Images", t('please_upload_some_images'), "error")
            return;
        }
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        const formData = new FormData();
        formData.append('qty', settings.state.quantity);
        formData.append('note', settings.state.note);
        settings.files.forEach((file, index) => {
            formData.append(`files[]`, file);
        });
        Axios({ "Content-Type": "multipart/form-data" }).post(url, formData).then((res) => {
            if (res.data.success) {
                settings.files.forEach((f) => {
                    const fd = new FormData();
                    fd.append(`file`, f);
                    Axios({ "Content-Type": "multipart/form-data", hl: false }).post(`/add-custom-request-image/${res.data.custom_request_data.id}`, fd).then((res) => { }).catch((r) => {
                        setAppSettings({ ...appSettings, loading: false });
                    });
                })
                setSettings({
                    ...settings,
                    loader: false,
                    message: t('thanks_for_the_request'),
                    state: {
                        note: '',
                        quantity: 1,
                        files: [],
                    },
                    files: [],
                })
                setTimeout(() => {
                    if (props.onLoadData) {
                        props.onLoadData();
                        props.setCloseModal();
                    }
                }, 1000);
            } else {
                setSettings({
                    message: '',
                    ...settings,
                    loader: false,
                })
            }
            setAppSettings({ ...appSettings, loading: false });
        }).catch(error => {
            setAppSettings({ ...appSettings, loading: false });
            setSettings({ ...settings, loader: false, message: '' })
        })
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    const deleteImage = (index) => {
        if (!props.isEdit) {
            return;
        }
        const id = settings?.state?.images[index]?.id;
        const custom_request_id = settings?.state?.images[index]?.custom_request_id;
        if (!id || !custom_request_id) {
            return;
        }
        setAppSettings({ ...appSettings, loading: true });
        Axios({}).post(`delete-custom-request-image/${custom_request_id}`, { image_id: id }).then(() => {
            setAppSettings({ ...appSettings, loading: false });
            props.onLoadData();
        }).catch((error) => {
            setAppSettings({ ...appSettings, loading: false });
        })
    }
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    const onQuantityChange = (op) => {
        const tcart = Object.assign([], lCart);
        let quantity = settings.state.quantity;
        if (op === '+' && quantity <= 100) {
            quantity += 1;
        } else if (op === '-' && quantity > 1) {
            quantity -= 1;
        }
        setSettings({
            ...settings,
            state: {
                ...settings.state,
                quantity: quantity
            }
        })
    }
    return (
        <div className="container pt-3 mb-5">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            {
                settings.message ?
                    <p className="alert alert-success text-center"><i className="fa fa-info-circle"></i> {settings.message}</p>
                    : ''
            }
            {
                !auth.is_auth ?
                    <div className="text-center">
                        <p className="alert alert-info"><i className="fa fa-info-circle"></i> {t('for_custom_login_please')}</p>
                        <MyAccount
                            showTitle={false}
                            {...props} />
                    </div>
                    : <form onSubmit={handleSubmit} method="POST">
                        <div className="mb-3">
                            {
                                settings.state?.files?.length > 0 ?
                                    <div className="row">
                                        {
                                            settings.state.files && settings.state.files.map((f, i) => {
                                                return (
                                                    <div className="col-6 col-lg-2">
                                                        <div className="wrapper p-1 mt-3 text-center" style={{ position: 'relative' }}>
                                                            <span
                                                                onClick={() => {
                                                                    if (props.isEdit) {
                                                                        swal({
                                                                            title: t('are_you_sure'),
                                                                            text: t('once_deleted_not_recover'),
                                                                            icon: "warning",
                                                                            buttons: true,
                                                                            dangerMode: true,
                                                                        })
                                                                            .then((willDelete) => {
                                                                                if (willDelete) {
                                                                                    const foundB = settings.files;
                                                                                    const found64 = settings.state.files;
                                                                                    delete foundB[i];
                                                                                    delete found64[i];
                                                                                    setSettings({
                                                                                        ...settings,
                                                                                        files: foundB.filter((x) => x !== undefined),
                                                                                        state: {
                                                                                            ...settings.state,
                                                                                            files: found64.filter((x) => x !== undefined),
                                                                                        }
                                                                                    })
                                                                                    deleteImage(i);
                                                                                }
                                                                            });
                                                                    } else {
                                                                        const foundB = settings.files;
                                                                        const found64 = settings.state.files;
                                                                        delete foundB[i];
                                                                        delete found64[i];
                                                                        setSettings({
                                                                            ...settings,
                                                                            files: foundB.filter((x) => x !== undefined),
                                                                            state: {
                                                                                ...settings.state,
                                                                                files: found64.filter((x) => x !== undefined),
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: 0,
                                                                    top: -5,
                                                                    color: 'white',
                                                                    cursor: 'pointer',
                                                                    background: 'red',
                                                                    width: 20,
                                                                    height: 20,
                                                                    borderRadius: '100%',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                <i style={{ position: 'relative', left: 1, top: -2 }} className="fa fa-times"></i>
                                                            </span>
                                                            <img className="img-thumbnail" style={{ height: 100 }} src={f} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="col-6 col-lg-2 p-1 pl-3">
                                            <div
                                                className="wrapper pl-3 mt-3 img-thumbnail text-center"
                                                onClick={() => {
                                                    if (fRef.current) {
                                                        fRef.current.click();
                                                    }
                                                }}
                                                style={{ cursor: 'pointer', marginRight: '1rem', marginLeft: '1rem', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '32px', color: '#aaa' }}
                                            >
                                                <i className="fa fa-upload"></i>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div
                                            onClick={() => {
                                                if (fRef.current) {
                                                    fRef.current.click();
                                                }
                                            }}
                                            className="img-thumbnail p-4" style={{
                                                display: 'flex',
                                                cursor: 'pointer',
                                                width: 200,
                                                height: 100,
                                                fontSize: 32,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: '#aaa'
                                            }}>
                                            <i className="fa fa-upload"></i>
                                        </div>
                                    </div>
                            }
                            <input
                                multiple={true}
                                onChange={(e) => {
                                    const bfiles = [];
                                    for (let i = 0; i < e.target.files.length; i++) {
                                        const file = e.target.files[i];
                                        bfiles.push(URL.createObjectURL(file));
                                    }
                                    setSettings({
                                        ...settings,
                                        files: Array.from(e.target.files).slice(0, 5),
                                        state: {
                                            ...settings.state,
                                            files: [...settings.state.files, ...bfiles].slice(0, 5)
                                        }
                                    })
                                    console.log(bfiles)
                                }}
                                style={{ display: 'none' }}
                                ref={fRef} accept="image/x-png,image/gif,image/jpeg" type="file" />
                        </div>
                        {
                            props.hideMessage === true ?
                                ''
                                : <div className="alert alert-danger mb-3 text-center" role="alert">
                                    <i className="fa fa-info-circle"></i> {t('upload_before_submiting')}
                                </div>
                        }

                        <div className="row mb-3">
                            <div className="col-8">
                                {t('quantity')}
                            </div>
                            <div className="col-4">
                                <div
                                    style={{
                                        position: 'relative',
                                        top: 1
                                    }}
                                    className="input-group input-group-sm flex-nowrap">
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => onQuantityChange('-')}
                                        className="input-group-text btn btn-sm btn-primary" id="addon-wrapping">-</span>
                                    <input
                                        readOnly
                                        type="text"
                                        value={settings.state.quantity}
                                        className="form-control text-center"
                                        placeholder="Username"
                                        aria-label="Username" aria-describedby="addon-wrapping" />
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => onQuantityChange('+')}
                                        className="input-group-text btn-sm btn-primary" id="addon-wrapping">+</span>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t('customer_note')}</label>
                            <textarea
                                className="form-control"
                                name="note"
                                value={settings.state.note}
                                onChange={handleInputChange}
                                type="text" placeholder={t('place_suggestions')}></textarea>
                        </div>
                        <button className="btn btn-primary">
                            {
                                props.btnText ?
                                    props.btnText
                                    : t('submit')
                            }
                        </button>
                    </form>
            }
        </div>
    )
}