import { slugAllowdPages } from '../../slug-routes'
import { NotFound404 } from "../errors/NotFound404"
export const SlugPages = (props) => {
    let Component = NotFound404;
    if (props.match && props.match.params && slugAllowdPages[props.match.params.slug]) {
        Component = slugAllowdPages[props.match.params.slug].component;
    }
    return (
        <Component {...props}></Component>
    );
}