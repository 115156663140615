import { useContext, useEffect, useState } from "react";
import { Carousel } from "../components/Carousel";
import { CategoryShowCase } from "../components/CategoryShowcase";
import { ShowCase } from "../components/ShowCase";
import { fluidShowcases, genderCategoryShowCases, genderCategoryShowCaseTabs, showcases, genderCategoryShowCases2, genderCategoryShowCaseTabs2, football } from "../placeholder";
import CountUp from 'react-countup';
import { Axios } from "../helpers";
import { SideBarContext } from "../contexts/SidebarContext";
import { environment } from '../config';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";


export const BrandsByType = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ brands: [] });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const params = useParams();
    const lang = localStorage.getItem('lang') || 'en';
    useEffect(() => {
        setTimeout(() => {
            setAppSettings({
                ...appSettings,
                sidenav: false,
                leftsidenav: false,
                loading: true
            })
            Axios({}).get('/brands?type=women').then((res) => {
                setSettings({ ...settings, brands: res.data });
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    leftsidenav: false,
                })
            }).catch((error) => {
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    leftsidenav: false,
                })
            })
        }, 0);
    }, []);
    return (
        <div className="brands pb-5">
            <div className="header" style={{ backgroundImage: `url(/assets/images/abayabrands.jpg)` }}>
                <h1 style={{ flex: 0.1 }}><strong>{t(params.slug.replace(/-/g, '_'))}</strong></h1>
            </div>
            <div className="container-fluid p-3 pt-0">
                <div className="row">
                    {
                        settings.brands.map((shop, i) => {
                            return (
                                <div key={i} className="col-12 col-lg-3 mt-2">
                                    <div className="shop">
                                        <div className="wrap">
                                            <div className="image" style={{ backgroundImage: `url(${environment.staticUrl + 'brands/' + shop.image})` }}>
                                                <div className="text">
                                                    <h6><strong>{shop['brand_' + lang]?.title}</strong></h6>
                                                    <h6>{shop['brand_' + lang]?.description}</h6>
                                                    <h6>{shop?.shop_price_range}</h6>
                                                    <Link
                                                        to={`/brands-products/${shop['brand_' + lang]?.slug}`}
                                                        className="btn btn-sm btn-dark">{t('shop_now')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}