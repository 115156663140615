import { countBy } from "lodash";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { environment } from "../config";
import { CartContext } from "../contexts/CartContext";
import { printPrice } from "../helpers";
import { useTranslation } from "../hooks/Translation";

export const WhishList = (props) => {
    const t = useTranslation();
    const { cartContext, onSetWishListContext } = useContext(CartContext);
    const onRemoveCart = (c, index) => {
        let tcart = Object.assign([], cartContext.wishlist);
        delete tcart[index];
        tcart = tcart.filter(x => x);
        onSetWishListContext(tcart);
    }
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];
    const lwishlist = JSON.parse(localStorage.getItem('wishlist')) || [];

    const addToCart = (c, index) => {
        let cart = Array.isArray(lCart) ? lCart : [];
        cart.push(c);
        onSetWishListContext(cart);
        onRemoveCart(c, index);
    }
    const lang = localStorage.getItem('lang') || 'en';

    return (
        <div className="wishlist brands">
            <div className="header" style={{ height: 350, backgroundImage: `url(${'assets/images/wishlist.jpg'})` }} >
                <h1 style={{ flex: 0.1 }}><strong><i className="fa fa-heart"></i></strong></h1>
                <h1 style={{ flex: 0.1 }}><strong>{t('wishlist')}</strong></h1>
                {/* <h2><strong>{t('my_wish_list_on_the_tailor')}</strong></h2> */}
            </div>
            <div className="container mt-3">
                <div className="table-responsive text-center">
                    <table className="table table-bordered p-0 m-0">
                        <thead>
                            <tr>
                                <th scope="col">{t('image')}</th>
                                <th scope="col">{t('product_name')}</th>
                                <th scope="col">{t('unit_price')}</th>
                                <th scope="col" style={{ width: '300px' }}>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lwishlist.map((cart, index) => {
                                    return (
                                        <tr>
                                            <td style={{ verticalAlign: 'middle' }}><img alt="" src={cart.image.image} style={{ width: 80 }} className="img-fluid" /></td>
                                            <td style={{ verticalAlign: 'middle' }}>{cart['product_' + lang]?.title}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{printPrice(t, parseFloat(cart.price).toFixed(2))}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }} className="text-center">
                                                <Link
                                                    to={'/shops/' + cart['product_' + lang]?.slug}
                                                    className="btn btn-dark btn-sm d-inline-block">
                                                    <i className="fa fa-shopping-cart"></i>
                                                </Link>
                                                &nbsp;
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onRemoveCart(cart, index);
                                                    }} className="btn btn-dark btn-sm d-inline-block">
                                                    <i className="fa fa-times"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {
                    lwishlist.length > 0 ? "" : <p className="text-center">{t('no_product_wishlist')}</p>
                }

            </div>
        </div>
    )
}