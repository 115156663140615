import { Link } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";

export const ShowCase = (props) => {
    const t = useTranslation();
    const itemStyle = props.itemStyle ? props.itemStyle : {};
    const actionStyle = props.actionStyle ? props.actionStyle : {};
    return (
        <div className="showcases" data-aos="fade-right">
            <h2 hidden={!props.title} className="text-center title mt-5 mb-5">{ props.title }</h2>
            <div className="row g-2">
                {
                    props.showcases && props.showcases.map((showcase, index) => {
                        return !props.imageTag ?
                            (
                                <div key={index} className={`${props.className}`} >
                                    <div
                                        to={showcase.url}
                                        key={index}
                                        className={`showcase-img m-0 mt-1 p-1`} 
                                        style={{ 
                                            backgroundImage: `url(${showcase.image})`, 
                                            ...itemStyle
                                        }}>
                                            {
                                                props.showOnlyTitle ?
                                                    <Link 
                                                    style={{
                                                        fontSize: 32,
                                                        lineHeight: '34px',
                                                        ...props.titleStyle
                                                    }}
                                                    to={showcase.url} className="d-block bolder text-center whiteshadow ">{ t(showcase.title) }</Link>
                                                : (
                                                    <>
                                                        {
                                                        showcase.url ?
                                                                <Link to={showcase.url} data-aos="fade-top" className="bolder text-center whiteshadow">{ t(showcase.title) }</Link>
                                                            : <button className="btn btn-sm btn-dark">{ t('show_more') }</button>
                                                        }
                                                        
                                                        {
                                                            showcase.url ?
                                                                <Link to={showcase.url} className="btn btn-sm btn-dark">{ t('show_more') }</Link>
                                                            : <button className="btn btn-sm btn-dark">{ t('show_more') }</button>
                                                        }
                                                    </>
                                                )
                                            }
                                            
                                    </div>
                                </div>
                            )
                        : (
                            <div
                                key={index}
                                className={`m-0 mt-1 ${props.className}`} 
                                style={{ 
                                    ...itemStyle
                                }}>
                                    <div 
                                        className="actions" 
                                        style={{ 
                                            ...actionStyle
                                        }}>
                                            { props.renderActions ? props.renderActions(showcase) : '' }
                                    </div>
                                    <img className="img-fluid" src={showcase.image} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}