import { BrandsComponent } from "../components/BrandsComponent";
import { useTranslation } from "../hooks/Translation";

export const TailorShops = (props) => {
    const t = useTranslation();
    return (
        <BrandsComponent
            bg={{ backgroundImage: `url(/assets/images/thetailor.jpg)` }}
            showCounter={true}
            title={t('tailor_shops')}
            {...props} />
    )
}