import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { Modal } from "../components/Modal";
import { environment } from "../config";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios, printPrice } from "../helpers";
import { useTranslation } from "../hooks/Translation";
import { StaticPara } from "../components/StaticPara";
import { ShowCase } from "../components/ShowCase";

export const ProductCategory = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ search: '', image: '', ischild: false, category_en: {}, onceLoaded: false, category_ar: {}, category_products: [] });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const params = useParams();
    const lang = localStorage.getItem('lang') || 'en';
    const school_rfq = [
        { url: '/request-a-qoute', title: t('request_for_quotation'), key: 'shop_by_brands', id: 1, image: '/assets/images/womenhome2.jpg' }
    ];
    const mobileNav = [
        {
            title: t('categories'),
            icon: 'lnr lnr-menu',
            url: '',
            callback: (e) => {
                setTimeout(() => {
                    setAppSettings({ ...appSettings, mobileNav: mobileNav, leftsidenav: true, sidenav: false })
                }, 10)
            }
        },
        {
            title: t('sorting'),
            icon: 'lnr lnr-sort-amount-asc',
            url: '',
            props: {
                "data-toggle": "modal",
                "data-target": "#sorting"
            },
            callback: (e) => {
                window.$('#sorting').modal('show')
            }
        },
        {
            title: t('filters'),
            icon: 'lnr lnr-funnel',
            url: '',
            callback: (e) => {
                console.log(3);
            }
        }
    ];

    useEffect(() => {
        loadData();
    }, []);
    const loadData = (slug = props.slug) => {
        setTimeout(() => {
            setAppSettings({
                ...appSettings,
                sidenav: false,
                mobileNav: mobileNav,
                leftsidenav: false,
                loading: true
            })
            Axios({}).get(`/categories/${slug ? slug : params.slug}`).then((res) => {
                let tabs = [];
                let ischild = settings.ischild;
                let image = res.data.banner_image;
                if (res.data.children && res.data.children.length > 0) {
                    tabs = res.data.children.map((child) => {
                        return {
                            name: child['category_' + lang].title,
                            key: child['category_' + lang].slug
                        }
                    })
                    ischild = true;
                    if (!settings.onceLoaded) {
                        props.onLoadTabs && props.onLoadTabs(tabs, loadData, image);
                    }
                }
                res.data.category_products = res?.data?.category_products?.map((r) => {
                    if (r.have_color) {
                        r.images = r.product_color_images;
                    } else {
                        r.images = r.product_images;
                    }
                    if (r?.images?.length > 0) {
                        r.image = { image: `${environment.staticUrl}products/${r.images[0].image}` };
                    } else {
                        r.image = { image: `${environment.staticUrl}no_image.png` };
                    }
                    return { ...r['product_' + lang], ...r, ...r.image, };
                })
                if (!ischild) {
                    // image = settings.image;
                }
                setSettings({ ...settings, ...res.data, onceLoaded: true, ischild: ischild, image });
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    mobileNav: mobileNav,
                    leftsidenav: false,
                })
            })
        }, 0);
    }
    let image = settings.image;
    if (props.image) {
        image = props.image;
    }
    const handleSearch = (e) => {
        setSettings({ ...settings, search: e.target.search })
    }


    return (
        <div className="brands mb-5">
            {
                props.showHeader === false ? ''
                    : <div className="header" style={{ backgroundImage: `url(${environment.staticUrl + 'categories/' + image})` }}>
                        <h1><strong>{props.title ? settings['category_' + lang].title : ''}</strong></h1>
                        <p><strong>{props.title ? settings['category_' + lang].description : ''}</strong></p>
                    </div>
            }
            <div className="shops container mt-3">
                <Modal {...props} id="sorting" title="Sort By">
                    <div className="modal-body p-0">
                        <div className="list-group text-center">
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=menu_order">
                                {t('filter_1')}</a>
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=popularity">
                                {t('filter_2')}</a>
                            <a className="list-group-item active p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=rating">
                                {t('filter_3')}</a>
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=rating">
                                {t('filter_4')}</a>
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=price">
                                {t('filter_5')}</a>
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=price-desc">
                                {t('filter_6')}</a>
                        </div>
                    </div>
                </Modal>
                {
                    props.showFilters !== false ?
                        <div className="actions d-none d-lg-block p-2">
                            <div className="pull-left" style={{ width: '30%' }}>
                                <button className="btn btn-dark"><i className="fa fa-filter"></i>  {t('filter')}</button>
                            </div>
                            <div className="pull-right" style={{ width: '70%', textAlign: 'right' }}>
                                <select className="form-control d-inline" style={{ maxWidth: 200 }}>
                                    <option> {t('filter_4')}</option>
                                    <option> {t('filter_7')}</option>
                                    <option>  {t('filter_7')}</option>
                                    <option> {t('filter_7')}</option>
                                    <option> {t('filter_7')}</option>
                                    <option> {t('filter_7')}</option>
                                </select>
                                <span>&nbsp;</span>
                                <input
                                    type="search"
                                    onChange={handleSearch}
                                    value={settings.search}
                                    className="form-control d-inline"
                                    placeholder="Search" style={{ maxWidth: 200 }} />
                            </div>
                            <div className="clear clearfix"></div>
                        </div>
                        : ''
                }
                <div className="category-showcases">
                    <ul className="nav justify-content-center tabs">
                        {
                            props.tabs && props.tabs.map((tab, index) => {
                                return (
                                    <li key={index} className="nav-item tab">
                                        <a
                                            onClick={(e) => {
                                                props.onTabClick && props.onTabClick(e, tab, index, loadData);
                                                loadData(tab.key)
                                            }}
                                            className={tab.key === props.tab ? `nav-link tab-link active` : `nav-link tab-link`}
                                            aria-current="page"
                                            href="/">
                                            {tab.name}
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                {
                    props.slug === "hospitality" || props.slug === "corporates-wear" || props.slug === "work-wear" || props.slug === "health-care" || props.slug === "school" ?
                        <>
                            <StaticPara
                                content={{
                                    heading: t(props.match.params.slug.replace(/-/g, '_') + '_h'),
                                    paragraph_1: t(props.match.params.slug.replace(/-/g, '_') + '_p'),
                                }}
                                {...props}
                            />
                            <br />
                            {(props.slug !== "school" ?
                                <div className="mb-3">
                                    <ShowCase
                                        titleStyle={{
                                            top: 315,
                                            margin: "0 14px 0 auto",
                                            padding: 8,
                                            fontSize: 14,
                                            width: "35%",
                                            lineHeight: "18px !important",
                                            borderRadius: "50px 50px",
                                            textTransform: "uppercase",
                                            backgroundColor: "#9db2a8",
                                        }}
                                        itemStyle={
                                            {
                                                height: 400
                                            }
                                        }
                                        showOnlyTitle={true}
                                        className="col-12 col-md-12 col-lg-12 col-xl-12 animate__animated animate__bounce"
                                        showcases={school_rfq} />
                                </div>
                                :
                                <div className="text-center" >
                                    <Link
                                        style={{
                                            fontSize: 18,
                                            color: 'white',
                                            fontStyle: 'italic',
                                            marginBottom: 10,
                                            marginTop: 10,
                                            backgroundColor: "#9db2a8",
                                            padding: 10,
                                            borderRadius: 30,
                                            width: "90%",
                                            display: "block",
                                            margin: "0 auto"
                                        }}
                                        to={`/book-a-tailor`}>{t('book_a_tailor')}</Link>
                                </div>


                            )}
                        </>
                        : ''
                }

                <div className="row p-0 m-0 mt-2">
                    {
                        settings?.category_products?.map((product, i) => {
                            return (
                                <Link
                                    key={i}
                                    to={`/shops/${product.slug}`}
                                    className="col-6 col-md-3 text-center p-1 " style={{ position: 'relative', border: '1.5px solid #dee2e6' }}>
                                    <img
                                        // style={{ height: 300 }}
                                        src={`${product.image}`}
                                        className="img-fluid" />
                                    <div style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        bottom: 0,
                                        background: `rgba(255,255,255, 0.7)`,
                                        height: 60,
                                        width: '100%',
                                        padding: '1ex',
                                        paddingLeft: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <p className="p-0 m-0" style={{ textTransform: 'uppercase', textAlign: 'left' }}><strong>{product.title}</strong></p>
                                        <p className="p-0 m-0" style={{ textTransform: 'uppercase', textAlign: 'left' }}><strong>{
                                            product.have_size == 1 && product.product_price_sizes && product.product_price_sizes.length > 0 ?
                                                <p className="p-0 m-0 bolder">{printPrice(t, product.product_price_sizes[0].price)}</p>
                                                : <p className="bold m-0 bold">{printPrice(t, product?.price)}</p>
                                        }</strong></p>
                                    </div>
                                </Link>
                            )
                        })
                    }
                    <div className="col-12 text-center">
                        {
                            settings?.category_products.length == 0 ? <h1>{t('product_coming_soon')}</h1> : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}