import { useContext, useEffect, useState } from "react";
import { Carousel } from "../components/Carousel";
import { CategoryShowCase } from "../components/CategoryShowcase";
import { ShowCase } from "../components/ShowCase";
import { fluidShowcases, genderCategoryShowCases, genderCategoryShowCaseTabs, showcases, genderCategoryShowCases2, genderCategoryShowCaseTabs2, football } from "../placeholder";
import CountUp from 'react-countup';
import { Axios, printPrice } from "../helpers";
import { SideBarContext } from "../contexts/SidebarContext";
import { environment } from '../config';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";


export const BrandsProducts = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ products: [], details: {} });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const params = useParams();
    const lang = localStorage.getItem('lang') || 'en';
    useEffect(() => {
        setTimeout(() => {
            setAppSettings({
                ...appSettings,
                sidenav: false,
                leftsidenav: false,
                loading: true
            })
            Axios({}).get('/brands/' + params.slug).then((res) => {
                const p = res.data.brand_products.map((r) => {
                    let image = {};
                    if (r.have_color === 1) {
                        image = r.product_color_images.length > 0 ?
                            { ...r.product_color_images[0], image: `${environment.staticUrl}products/${r.product_color_images[0].image}` } :
                            { image: `${environment.staticUrl}no_image.png` }
                    } else {
                        if (r.product_images && r.product_images.length > 0) {
                            image = r.product_images.length > 0 ?
                                { ...r.product_images[0], image: `${environment.staticUrl}products/${r.product_images[0].image}` } :
                                { image: `${environment.staticUrl}no_image.png` }
                        }
                    }
                    return {
                        ...r,
                        product_color_image: image
                    }
                })
                setSettings({ ...settings, products: p, details: res.data });
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    leftsidenav: false,
                })
            }).catch((error) => {
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    leftsidenav: false,
                })
            })
        }, 0);
    }, []);
    return (
        <div className="brands pb-5">
            <div className="header" style={{
                backgroundImage: `url(${environment.staticUrl + 'brands/' + settings.details.image}`
            }}>
                <h1 style={{ flex: 0.1 }}><strong style={{ textTransform: 'capitalize' }}>{params.slug.replace(/-/g, ' ')}</strong></h1>
            </div>
            <div className="container-fluid p-3 pt-0">
                <div className="row p-0 m-0 mt-2">
                    {
                        settings?.products?.map((product, i) => {
                            return (
                                <Link
                                    key={i}
                                    to={`/shops/${product['product_' + lang].slug}`}
                                    className="col-6 col-md-3 text-center p-1 " style={{ position: 'relative', border: '1.5px solid #dee2e6' }}>
                                    <img
                                        // style={{ height: 300 }}
                                        src={`${product.product_color_image.image}`}
                                        className="img-fluid" />
                                    <div style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        bottom: 0,
                                        background: `rgba(255,255,255, 0.7)`,
                                        height: 60,
                                        width: '100%',
                                        padding: '1ex',
                                        paddingLeft: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <p className="p-0 m-0" style={{ textTransform: 'uppercase', textAlign: 'left' }}><strong>{product['product_' + lang].title}</strong></p>
                                        <p className="p-0 m-0" style={{ textTransform: 'uppercase', textAlign: 'left' }}><strong>{
                                            product.have_size == 1 && product.product_price_sizes && product.product_price_sizes.length > 0 ?
                                                <p className="p-0 m-0 bolder">{printPrice(t, product.product_price_sizes[0].price)}</p>
                                                : <p className="bold m-0 bold">{printPrice(t, product?.price)}</p>
                                        }</strong></p>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}