import { useContext, useEffect, useState } from "react";
import { Appointment } from "../components/Appointment";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { SideBarContext } from "../contexts/SidebarContext";
import { Materials } from "./Materials";
import { useTranslation } from "../hooks/Translation";
import { parseQueryString } from "../helpers";

export const BookATailor = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({});
    // const slug = parseQueryString(window.location.search);
    return (
        <div className="myaccount container mb-5">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <h1 className="app-title">{t('book_a_tailor')}</h1>
            <Appointment
                showType={true}
                {...props} />
        </div>
    )
}