import { useContext } from "react";
import { environment } from "../config";
import { CartContext } from "../contexts/CartContext";
import { useTranslation } from "../hooks/Translation";

export const ThankYou = (props) => {
    const t = useTranslation();
    const { cartContext, onSetWishListContext } = useContext(CartContext);
    const lang = localStorage.getItem('lang') || 'en';
    return (
        <div className="thankyou brands">
            <div className="d-lg-none pb-2"></div>
            <div className="d-lg-none pb-5"></div>
            <div className="header" style={{ backgroundColor: '#a6f88b' }}>
                <h1 style={{ flex: 0.1 }}><strong style={{ textTransform: 'capitalize' }}>{t('thank_you')}</strong></h1>
            </div>
        </div>
    )
}