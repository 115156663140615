import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SideBarContext } from "../../contexts/SidebarContext"
import { UserAuthenticatedArea } from "./UserAuthenticatedArea"
import axios from "axios";
import swal from "sweetalert";
import { environment } from "../../config";
import { AuthContext } from "../../contexts/AuthContext";
import { Axios, parseQueryString } from "../../helpers"
import { RequetAQoute } from "../../pages/RequestAQoute";
import { Modal } from "../Modal";
import { WithAuth } from "./WithAuth";
import moment from "moment";
import { Appointment } from "../Appointment";
import { useTranslation } from "../../hooks/Translation";

export const Orders = (props) => {
    const t = useTranslation();
    const { appSettings, setAppSettings } = useContext(SideBarContext);
    const [settings, setSettings] = useState({
        orders: [],
        showModal: false,
        updateState: {
            tailor: {},
            perons: {},
            selectedDate: '',
            selectedTime: ''
        }
    });
    useEffect(() => {
        loadData();
    }, [])
    const loadData = () => {
        setAppSettings({ ...appSettings, loading: true });
        axios.all([
            Axios({}).get('/appointments')
        ]).then(axios.spread((appointmentRes) => {
            const d = appointmentRes.data.map((r) => {
                return {
                    ...r,
                    type: t('appointment')
                }
            })
            setSettings({ ...settings, orders: d });
            setAppSettings({ ...appSettings, loading: false });
        }))
    }
    const lang = localStorage.getItem('lang') || 'en';
    const deleteCustomRequest = (id) => {
        setAppSettings({ ...appSettings, loading: true });
        axios.all([
            Axios({}).get(`/delete-custom-request/${id}`),
        ]).then(axios.spread((addressRes) => {
            loadData();
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        });
    }
    const handleSubmit = (ssettings) => {
        setAppSettings({ ...appSettings, loading: true });
        Axios({}).post(`/update-appointment/${settings.updateState.id}`, {
            shop_id: ssettings.tailor.shop_id,
            booking_date: moment(ssettings.selectedDate).format('YYYY-MM-DD'),
            booking_time: ssettings.selectedTime,
            ttl_ppl: ssettings.person.value,
        }).then(() => {
            loadData();
            window.$('#editmodal').modal('hide')
            setAppSettings({ ...appSettings, updateState: {}, loading: false });
        })
    }
    return (
        <div className="myaccount container withoutheader-mobile mb-5 mt-3">
            <div className="pt-5 d-lg-none"></div>
            <div className="pt-4 d-lg-none"></div>
            <Modal
                onClose={() => {
                    setSettings({
                        ...settings,
                        showModal: false
                    })
                }}
                dialogClassName="modal-lg" {...props}
                id="editmodal" title={t('edit')}>
                {
                    settings.showModal ?
                        <Appointment
                            onSubmit={handleSubmit}
                            updateState={settings.updateState}
                            showType={true}
                            {...props} />
                        : ''
                }
            </Modal>
            <h2 className="app-title bolder pt-0">{t('orders')}</h2>
            {
                settings.orders.length < 1 ?
                    <UserAuthenticatedArea>
                        <div className="alert alert-secondary">
                            <div className="pull-left mt-1">
                                <i className="fa fa-warning"></i>&nbsp;&nbsp;&nbsp;<span className="ml-3">{t('no_order_place')}.</span>
                            </div>
                            <div className="pull-right">
                                <Link to="/shops" className="btn btn-dark btn-sm">{t('browse_products')}</Link>
                            </div>
                            <div className="clear clearfix"></div>
                        </div>
                    </UserAuthenticatedArea>
                    : <UserAuthenticatedArea>
                        <div className="pt-3 d-lg-none"></div>
                        <div className="table-responsive">
                            <table className="table table-bordered p-0 m-0">
                                <thead>
                                    <tr>
                                        <th scope="col">{t('type')}</th>
                                        <th scope="col">{t('tailor')}</th>
                                        <th style={{ minWidth: 200 }} scope="col">{t('booking_date_time')}</th>
                                        <th scope="col">{t('persons')}</th>
                                        <th scope="col">{t('status')}</th>
                                        <th scope="col">{t('price')}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        settings.orders.map((cr, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle' }}>{cr.type}</td>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle' }}>{cr.shop['shop_' + lang]?.title}</td>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle', textAlign: 'center' }}>
                                                        {moment(cr.booking_date).format('MMM Do YYYY')}
                                                        <br />
                                                        <div className="d-inline-block p-1 mt-1" style={{
                                                            background: '#ccc',
                                                            border: '1px solid #aaa'
                                                        }}>
                                                            {cr.time_from} to {cr.time_to}
                                                        </div>
                                                    </td>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle' }}>{cr.ttl_ppl}</td>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle' }}>{cr.status}</td>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle' }}>{cr.price}</td>
                                                    <td style={{ position: 'relative', verticalAlign: 'middle', width: 150 }}>
                                                        <button onClick={() => {
                                                            setSettings({
                                                                ...settings,
                                                                showModal: false,
                                                                updateState: {
                                                                    ...cr
                                                                }
                                                            })
                                                            setTimeout(() => {
                                                                setSettings({
                                                                    ...settings,
                                                                    showModal: true,
                                                                    updateState: {
                                                                        id: cr.id,
                                                                        tailor: {
                                                                            value: cr.shop_id,
                                                                            image: `${environment.staticUrl}shops/${cr.image}`,
                                                                            shop_id: cr.shop_id,
                                                                            label: cr.shop['shop_' + lang].title,
                                                                            appointment_price: cr.shop.appointment_price
                                                                        },
                                                                        selectedTime: `${cr.time_from},${cr.time_to}`,
                                                                        selectedDate: moment(cr.booking_date).toDate(),
                                                                        person: {
                                                                            label: `${cr.ttl_ppl} ` + t('persons'),
                                                                            value: cr.ttl_ppl
                                                                        }
                                                                    }
                                                                })
                                                                window.$('#editmodal').modal('show')
                                                            }, 100)
                                                        }} className="btn btn-sm btn-primary"><i className="fa fa-edit"></i></button>
                                                        {/* &nbsp;
                                                        <button
                                                            onClick={() => {
                                                                swal({
                                                                    title: "Are you sure?",
                                                                    text: "Once deleted, you will not be able to recover.",
                                                                    icon: "warning",
                                                                    buttons: true,
                                                                    dangerMode: true,
                                                                })
                                                                .then((willDelete) => {
                                                                    if (willDelete) {
                                                                        deleteCustomRequest(cr.id)
                                                                    }
                                                                });
                                                            }} 
                                                            className="btn btn-sm btn-danger"><i className="fa fa-times"></i></button> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </UserAuthenticatedArea>
            }
        </div>
    )
}