
export const womenshome = [
    { url: '/brands/abaya-brands', title: 'Shop By Brands', key: 'shop_by_brands', id: 1, image: '/assets/images/womenhome1.jpg' },
    { title: 'Design Your Abaya', key: 'design_your_abaya', id: 2, image: '/assets/images/womenhome2.jpg', url: '/book-a-tailor' },
];
export const showcases = [
    { url: '/womens-home', title: 'Trendy Abaya\'s', key: 'trendy_abaya',  id: 1, image: '/assets/images/showcase1.jpg' },
    { title: 'Men\'s Thob', key: 'mens_thob', id: 2, image: '/assets/images/showcase2.png', url: '/book-a-tailor' },
    { url: '/school-uniform', title: 'Uniforms', key: 'uniforms', id: 3, image: '/assets/images/showcase3.jpg' },
];
export const fluidShowcases = [
    { url: '/products/shemagh', title: 'Shemagh', key: 'shemagh', id: 1, image: '/assets/images/slider1.jpg' },
    { url: '/products/slippers', title: 'Slippers', key: 'slippers', id: 2, image: '/assets/images/slippers.jpg' },
];
export const genderCategoryShowCaseTabs = [
    { name: 'NEW IN', key: 'new_products', active: true },
    { name: 'MEN', key: 'men_products', active: false },
    { name: 'WOMEN', key: 'women_products', active: false }
];
export const genderCategoryShowCases = [
    { title: 'Trendy Abaya\'s', key: 'trendy_abaya', id: 1, image: '/assets/images/categoryshowcase.jpg' },
    { title: 'Men\'s Thob', key: 'mens_thob', id: 2, image: '/assets/images/categoryshowcase.jpg' },
    { title: 'Uniforms', key: 'uniforms', id: 3, image: '/assets/images/categoryshowcase.jpg' },
    { title: 'Uniforms', key: 'uniforms', id: 3, image: '/assets/images/categoryshowcase.jpg' },
];


export const genderCategoryShowCaseTabs2 = [
    { name: 'MESBAH', key: 'uniforms', active: true },
    { name: 'CUFFLIN', key: 'uniforms', active: false },
    { name: 'SHEMAGH', key: 'uniforms', active: false },
    { name: 'GHUTHRA', key: 'uniforms', active: false },
];
export const genderCategoryShowCases2 = [
    { title: 'Trendy Abaya\'s', key: 'trendy_abaya', id: 1, image: '/assets/images/categoryshowcase.jpg' },
    { title: 'Men\'s Thob', key: 'mens_thob', id: 2, image: '/assets/images/categoryshowcase.jpg' },
    { title: 'Uniforms', key: 'uniforms', id: 3, image: '/assets/images/categoryshowcase.jpg' },
    { title: 'Uniforms', key: 'uniforms', id: 3, image: '/assets/images/categoryshowcase.jpg' },
];

export const football = [
    { title: 'Trendy Abaya\'s', key: 'trendy_abaya',  id: 2, image: '/assets/images/footbalkit.gif' },
];


export const womensCategory = [
    { name: 'ABAYA', key: 'abaya',  active: true },
    { name: 'JALABIA', key: 'jalabia',  active: false },
];
export const womensCategoryShowCases = [
    { title: 'Trendy Abaya\'s', id: 1, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Men\'s Thob', id: 2, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Uniforms', id: 3, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Uniforms', id: 3, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Trendy Abaya\'s', id: 1, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Men\'s Thob', id: 2, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Uniforms', id: 3, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Uniforms', id: 3, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Trendy Abaya\'s', id: 1, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Men\'s Thob', id: 2, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Uniforms', id: 3, image: '/assets/images/categoryshowcase2.jpg' },
    { title: 'Uniforms', id: 3, image: '/assets/images/categoryshowcase2.jpg' },
];

export const womensShowcases = [
    { url: 'womens-home', key: 'abaya_brands', title: 'Abaya Brand\'s', id: 1, image: '/assets/images/showcase1.jpg' },
    { url: 'mens-home', key: 'abaya_design', title: 'Abaya Design\'s', id: 2, image: '/assets/images/showcase2.png' },
];

export const mensShowcases = [
    { url: '/brands', key: 'brands', title: 'Pick A Tailor Shop', id: 2, image: '/assets/images/showcase2.png' },
    { url: '/book-a-tailor', key: 'book_now', title: 'Book Now', id: 2, image: '/assets/images/showcase2.png' },
];
