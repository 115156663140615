import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CartContext } from "../contexts/CartContext"
import { SideBarContext } from "../contexts/SidebarContext"

export const LeftSideBar = (props) => {
    const [ settings, setSettings ] = useState({ cart: [] })
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const lang = document.documentElement.lang;
    const language = lang !== 'en' ? 'English' : 'العربية';
    const categories = JSON.parse(localStorage.getItem('categories')) || [];
    return (
       <div className={ appSettings.leftsidenav ? "navsidebar active leftsidebar" : "navsidebar leftsidebar" }>
            {
                appSettings.leftsidenav ?
                    <div className="blur-area" onClick={() => { setAppSettings({ ...appSettings, leftsidenav: false }) }}></div>
                    : ''
            }
           <div className="topbar">
               <div className="title pull-left" style={{ pading: '18px' }}>All Categories</div>
               <div className="title pull-right">
                   <i 
                    onClick={() => setAppSettings({...appSettings, leftsidenav: false}) } 
                    className="fa fa-times"></i>
                </div>
               <div className="clear clearfix"></div>
           </div>
           <div className="cart">
                <ul className="list-group text-center">
                    {
                        categories && Array.isArray(categories) && categories.map((cat, index) => {
                            return (
                                <li key={index} className="nav-item">
                                    <Link 
                                        className="nav-link active" 
                                        aria-current="page" 
                                        to={`/products/${cat['category_' + lang]?.slug}`}>
                                            { cat['category_' + lang]?.title }
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
           </div>
       </div>
    )
}