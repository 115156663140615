import { useContext } from 'react'
import { LayoutContext } from '../contexts/LayoutContext';

export function useTranslation() {
  const {layoutSettings, setLayoutSettings} = useContext(LayoutContext);
  const t = (key) => {
    if (layoutSettings.translate[key]) {
      return layoutSettings.translate[key];
    } else {
      return key;
    }
  }
  return t;
}