import { useContext } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "../../hooks/Translation";

export const WithAuth = (Component) => {
    const AuthComponent = (props) => {
        const t = useTranslation();
        const history = useHistory();
        const authData = JSON.parse(localStorage.getItem('auth')) || null;
        let auth = false;
        if (authData) {
            auth = true;
        }
        if (auth) {
            return (
                <Component {...props}></Component>
            )
        } else {
            swal(t('login'), t('please_login'), 'error');
            history.push("/my-account");
            return (
                <div>A</div>
            )
        }
    }
    return AuthComponent;
}