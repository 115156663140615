import axios from 'axios';
import swal from 'sweetalert';
import { environment } from './config';

export const parseQueryString = (qString = '') => {
    var search = qString.substring(1);
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}
export const printLeftCenterRight = (l, c, r) => {
    const dir = document.dir || 'rtl';
    if (dir !== 'rtl') {
        return (
            <p className="d-inline-block">{l} {c} {r}</p>
        )
    } else {
        return (
            <p className="d-inline-block">
                <span className="m-1">{r}</span>
                <span className="m-1">{c}</span>
                <span className="m-1">{l}</span>
            </p>
        )
    }
} 
export const printPrice = (t, price) => {
    const dir = document.dir || 'rtl';
    // if (dir !== 'rtl') {
        return (
            <p className="d-inline-block">
                { price }
                &nbsp;
                { t('currency') === 'currency' ? '' : t('currency') }
            </p>
        )
    // } else {
    //     return (
    //         <p>
    //             { t('currency') === 'currency' ? '' : t('currency') }
    //             &nbsp;
    //             { price }
    //         </p>
    //     )
    // }
}

export const Axios = (headers = {}) => {
    const lang = document.documentElement.lang || 'ar';
    const auth = JSON.parse(localStorage.getItem('auth')) || { token: '' }
    const ax = axios.create({
        baseURL: environment.apiUrl,
        timeout: 30000,
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            'auth_key': 'thetailor_site',
            'lang_code': lang,
            "X-Cookies": document.cookie,
            "Authorization": `Bearer ${auth.token}`,
            ...headers
        }
    });
    ax.interceptors.response.use((response) => {
        if (response.config.method !== 'get' && response.config.headers.hl !== false && response.data.success === false || response.data.success === 0) {
            swal('Error', response.data.errors, 'error');
        } else if (response.config.method !== 'get' && response.config.headers.hl !== false && response.data.success) {
            swal("Success", response.data.errors, "success")
        }
        return response;
    }, (error) => {
        swal("Oops", 'Some thing went wrong', "error")
        return Promise.reject(error.message);
    });
    return ax;
};


export const CartTypes = {
    Product: 3,
    Appointment: 1,
    Material: 4,
    CustomRequest: 2,
    StaticTShirt: 5
}

export const CartTypeIds = {
    1: 'appointment_id',
    2: 'custom_request_id',
    3: 'product_id',
    4: 'material_id',
    5: 'customize'
}