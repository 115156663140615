import { ProductCategory } from './ProductCategory';
import { ShowCase } from "../components/ShowCase";
import { womenshome } from "../placeholder";
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';

export const ProductCategoryChild = (props) => {
    const paramas = useParams();
    const [settings, setSettings] = useState({ 
        onceLoaded: false,
        tab: 'abaya', 
        slug: 'abaya',
        tabs: [],
        image: ''
     })
    return (
        <>
            <div className="mb-3">
                <ProductCategory
                    title={'A'}
                    onTabClick={(e, tab, index, loadData) => {
                        e.preventDefault();
                        setSettings({ ...settings, slug: tab.key, tab: tab.key });
                    }}
                    {...props} 
                    image={settings.image}
                    tabs={settings.tabs}
                    tab={settings.tab}                    
                    onLoadTabs={(tabs, loadData, image) => {
                        if (!settings.onceLoaded) {
                            let tab = '';
                            let slug = '';
                            if (tabs.length > 0) {
                                tab = tabs[0].key;
                                slug = tabs[0].key;
                            }                            
                            setSettings({ ...settings, tabs: tabs, tab, slug, onceLoaded: true, image });
                            setTimeout(() => {
                                loadData(tabs[0].key);
                            }, 0);
                        }
                    }}
                    showFilters={false}
                    slug={paramas.slug} />
            </div>
        </>
    )
}