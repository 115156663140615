import { useContext, useEffect, useState } from "react";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { ContactUsForm } from "../components/Forms/ContactUsForm";
import { useTranslation } from "../hooks/Translation";


export const ContactUs = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({});
    return (
        <div className="myaccount brands">
            <div className="header" style={{ backgroundImage: `url(${'assets/images/contact.jpeg'})` }}>
                <h1 style={{ flex: 0.1 }}><strong>{t('contact_us')}</strong></h1>
                <h2>{t('need_a_hand')}</h2>
                <h4><a><i className="fa fa-whatsapp"></i> <span style={{}}>{t('contact_number')}</span></a></h4>
                <h4><a><i className="fa fa-envelope"></i> thetailorapp@followme.qa</a></h4>
            </div>
            <div className="container mt-3">
                <h2><strong>{t('anything_to_say')}</strong></h2>
                <h4>{t('fill_this_out_so_we_can')}</h4>
                <ContactUsForm {...props} />
                <hr />
                <h2 className="mt-3"><strong>{t('get_directions_to_our_office')}</strong></h2>
                <iframe
                    style={{ width: '100%', height: 300, border: '1px solid #ccc', borderRadius: 5, boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)' }}
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?q=The&amp;t=m&amp;z=14&amp;output=embed&amp;iwloc=near"
                    title="The" aria-label="The">
                </iframe>
                <hr />
                <h2 className="mt-3"><strong>{t('follow_us_on')}</strong></h2>
                <a target="_blank" href="https://www.instagram.com/thetailor.qa/" className="btn btn-dark mt-1"><i className="fa fa-instagram"></i> &nbsp;{t('our_instagram')}</a>
                <hr />
                <h2 className="mt-3"><strong>{t('our_whatsapp')}</strong></h2>
                <a
                    target="__blank"
                    href="https://wa.me/97470228200"
                    style={{
                        background: '#4dc247',
                        borderColor: '#4dc247',
                        width: '55px',
                        height: '55px',
                        display: 'block',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '100%',
                        color: 'white',
                        fontSize: '32px'
                    }}>
                    <i className="fa fa-whatsapp"></i>
                </a>
            </div>
        </div>
    )
}