import axios from "axios";
import { useContext, useEffect, useState } from "react"
import swal from "sweetalert";
import { environment } from "../../config";
import { AuthContext } from "../../contexts/AuthContext";
import { CartContext } from "../../contexts/CartContext";
import { SideBarContext } from "../../contexts/SidebarContext";
import { Axios, CartTypes, parseQueryString } from "../../helpers"
import { RequetAQoute } from "../../pages/RequestAQoute";
import { Modal } from "../Modal";
import { UserAuthenticatedArea } from "./UserAuthenticatedArea"
import { WithAuth } from "./WithAuth";
import { useTranslation } from "../../hooks/Translation";

export const CustomRequest = (props) => {
    const t = useTranslation();
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext, onSetRecentViewsContext, onSetWishListContext } = useContext(CartContext)
    const [settings, setSettings] = useState({
        isFormValid: false,
        showModal: false,
        loader: false,
        customRequest: [],
        updateState: {
            note: '',
            qty: 1
        },
        state: {
            note: '',
            qty: ''
        },
        errors: {
            da_name: [],
            contact: []
        }
    });
    const { auth, setAuth } = useContext(AuthContext)
    const lang = localStorage.getItem('lang') || 'en';
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];

    useEffect(() => {
        loadData();
    }, [])
    const loadData = () => {
        setAppSettings({ ...appSettings, loading: true });
        axios.all([
            Axios({}).get('/custom-requests'),
        ]).then(axios.spread((addressRes) => {
            setSettings({
                ...settings,
                customRequest: addressRes.data.map((d) => {
                    d.images = d.images.map((image) => {
                        image.image = `${environment.staticUrl}custom_requests/${image.image}`;
                        return image;
                    })
                    d.files = d.images.map((image) => {
                        return image.image;
                    });
                    return d;
                }),
                state: { ...addressRes.data },
            })
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        });
    }
    const deleteCustomRequest = (id) => {
        setAppSettings({ ...appSettings, loading: true });
        axios.all([
            Axios({}).get(`/delete-custom-request/${id}`),
        ]).then(axios.spread((addressRes) => {
            loadData();
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        });
    }
    const onQuantityChange = (op) => {
        let quantity = settings.updateState.qty;
        if (op === '+' && quantity <= 100) {
            quantity += 1;
        } else if (op === '-' && quantity > 1) {
            quantity -= 1;
        }
        setSettings({
            ...settings,
            updateState: {
                ...settings.updateState,
                qty: quantity
            },
        })
    }
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
            }
        }
        setSettings({ ...settings, updateState: { ...settings.updateState, [event.target.name]: event.target.value } })
    }
    return (
        <div className="myaccount container withoutheader-mobile mt-5 mb-5">
            <Modal
                onClose={() => {
                    setSettings({
                        ...settings,
                        showModal: false
                    })
                }}
                dialogClassName="modal-lg" {...props}
                id="editmodal" title={t('edit_custom_requset')} >
                {
                    settings.showModal ?
                        <RequetAQoute
                            btnText={'Update'}
                            isEdit={true}
                            onLoadData={
                                () => {
                                    loadData();
                                }
                            }
                            setCloseModal={() => {
                                setSettings({
                                    ...settings,
                                    showModal: false
                                })
                                window.$('#editmodal').modal('hide')
                            }}
                            hideMessage={true}
                            state={settings.updateState}
                            {...props} />
                        : ''
                }
            </Modal>
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <UserAuthenticatedArea>
                <h2 className="app-title bolder m-0 pt-0 mt-4">{t('custom_request')}</h2>
                <div className="table-responsive" >
                    <table className="table table-bordered p-0 m-0">
                        <thead>
                            <tr>
                                <th style={{ minWidth: 150 }} scope="col">{t('info')}</th>
                                {/* <th style={{ minWidth: 200 }} scope="col">{t('note')}</th> */}
                                {/* <th style={{ minWidth: 100 }} scope="col">{t('quantity')}</th> */}
                                {/* <th style={{ minWidth: 100 }} scope="col">{t('price')}</th> */}
                                <th style={{ minWidth: 150 }} className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                settings.customRequest.map((cr, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ position: 'relative', verticalAlign: 'middle' }} className="text-center">
                                                {
                                                    cr.images.map((image, i) => {
                                                        return <img
                                                            style={{
                                                                width: 100,
                                                                height: 100
                                                            }}
                                                            key={i}
                                                            className="img-thumbnail"
                                                            src={image.image} />
                                                    })
                                                }
                                                <p className="m-0"><strong>t{t('note')}:</strong> {cr.note}</p>
                                                <p  className="m-0"><strong>{t('quantity')}:</strong> {cr.qty}</p>
                                                <p  className="m-0"><strong>{t('price')}:</strong> {cr.price}</p>
                                            </td>
                                            <td className="text-center" style={{ position: 'relative', verticalAlign: 'middle', width: 150 }}>
                                                <button
                                                    hidden={!cr.price}
                                                    onClick={() => {
                                                        let cart = Array.isArray(lCart) ? lCart : [];
                                                        const found = cart.findIndex((x) => x.pid === cr.id + '_cr');
                                                        const p = { 
                                                            id: cr.id,
                                                            pid: cr.id + '_cr',
                                                            name: t('custom_request'),
                                                            price: cr.price || 50,
                                                            quantity: cr.qty,
                                                            image: cr.images.length > 0 ? cr.images[0] : environment.staticUrl + 'no_image.png'
                                                        };
                                                        if (found > -1) {
                                                            swal(t("cart"), t('already_in_the_cart'), "info")
                                                            return;
                                                        } else {
                                                            p.type = CartTypes.CustomRequest;
                                                            cart.push(p);
                                                        }
                                                        swal ( t("cart") ,  t("cart_updated") ,  "success" )
                                                        onSetCartContext(cart);
                                                    }}
                                                    className="btn btn-dark btn-sm"><i className="fa fa-shopping-cart"></i></button>
                                                    {/* &nbsp; */}
                                            {/* <button onClick={() => {
                                                    setSettings({
                                                        ...settings,
                                                        showModal: false,
                                                        updateState: {
                                                            ...cr
                                                        }
                                                    })
                                                    setTimeout(() => {
                                                        setSettings({
                                                            ...settings,
                                                            showModal: true,
                                                            updateState: {
                                                                ...cr
                                                            }
                                                        })
                                                        window.$('#editmodal').modal('show')
                                                    }, 100)
                                                }} className="btn btn-sm btn-primary"><i className="fa fa-edit"></i></button> */}
                                                    &nbsp;
                                            <button
                                                    onClick={() => {
                                                        swal({
                                                            title: t('are_you_sure'),
                                                            text: t('once_deleted_not_recover'),
                                                            icon: "warning",
                                                            buttons: true,
                                                            dangerMode: true,
                                                        })
                                                            .then((willDelete) => {
                                                                if (willDelete) {
                                                                    deleteCustomRequest(cr.id)
                                                                }
                                                            });
                                                    }}
                                                    className="btn btn-sm btn-danger"><i className="fa fa-times"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </UserAuthenticatedArea>
        </div >
    )
}