import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Appointment } from "../components/Appointment";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { Axios } from "../helpers";

export const BrandsBooking = (props) => {
    const [settings, setSettings] = useState({ shop: {} });
    const params = useParams();
    const lang = localStorage.getItem('lang') || 'en';
    return (
        <div className="myaccount container">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <div className="row">
                <div className="col-12 col-lg-10 offset-lg-1">
                    <h1 className="app-title cap">{ settings.shop['shop_' + lang]?.title }</h1>
                    <Appointment
                        {...props}
                        onShopLoad={(shop) => {
                            setSettings({ shop })
                        }}
                        singleShop={true}
                        params={'/' + params.slug}
                        showType={false}
                    />
                </div>
            </div>
        </div>
    )
}