import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Carousel, CarouselV2 } from "../../components/Carousel";
import { CategoryShowCase } from "../../components/CategoryShowcase";
import { HCarousel } from "../../components/HCarousel";
import { ShowCase } from "../../components/ShowCase";
import { fluidShowcases, genderCategoryShowCases, genderCategoryShowCaseTabs, showcases, genderCategoryShowCases2, genderCategoryShowCaseTabs2, football, womenshome } from "../../placeholder";
import { Axios } from '../../helpers';
import { SideBarContext } from "../../contexts/SidebarContext";
import { environment } from '../../config';
import axios from "axios";
import { useTranslation } from "../../hooks/Translation";
import { StaticPara } from "../../components/StaticPara";
import { ColumnShowCase } from "../../components/ColumnShowCase";
import { TitleShowCase } from "../../components/TitleShowCase";
import { TextShowCase, TitleImageShowCase } from "../../components/TitleImageShowCase";
import { ShopsByCategory } from "../../components/ShopsByCategory";
import { BrandsComponent } from "../../components/BrandsComponent";
import { ProductCategory } from '../ProductCategory';
import { StaticProduct } from "../StaticProduct";

export const TailorAndCustomizeAbaya = (props) => {
    const t = useTranslation();
    const params = useParams();
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const [settings, setSettings] = useState({
        image: '',
        pdc: {},
        category1: { title: '', showcase: [], image: 'cat1.jpeg' },
        category3: { title: '', showcase: [], image: 'cat1.jpeg' },
        tailor1: { title: '', showcase: [], image: 'cat1.jpeg' },
        tailor2: { title: '', showcase: [], image: 'cat1.jpeg' },
        shop_by_categories: {},
        content1: {},
        content2: {},
        fall_season_type: 'new_products',
        home_category_type: 'new_products',
        fall_seasons: [],
        fallSeasonsProducts: [],
        homeCategories: {
            title: '',
            tabs: [],
            categories: {},
            selected: {
                title: '',
                products: []
            }
        },
        menEssentials: {
            title: t('men_essentials'),
            showcases: []
        },
        slider: [{ img: '/assets/images/logo.png' }],
        brandSliders: []
    });
    const lang = localStorage.getItem('lang') || 'en';
    useEffect(() => {
        setAppSettings({ ...appSettings, loading: true, sidenav: false });
        setSettings({ ...settings, loader: true });
        axios.all([
            Axios({}).get(`/categories/${params.slug}`),
        ]).then(axios.spread((ctRes) => {
            let image = ctRes.data.banner_image;
            setSettings({
                ...settings,
                loader: false,
                pdc: ctRes.data,
                image,
            })
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
            setSettings({ ...settings, loader: false });
        });
    }, []);
    const mapFallProducts = (products) => {
        return products.map((product) => {
            product.url = '/products/' + product['product_' + lang].slug;
            if (product.have_color) {
                product.images = product?.product_color_images;
            } else {
                product.images = product?.product_images;
            }
            if (product?.images?.length > 0) {
                product.image = `${environment.staticUrl}/products/` + product?.images[0].image;
            } else {
                product.image = `${environment.staticUrl}no_image.png`;
            }
            return product;
        });
    };
    const women = [
        { url: '/request-a-qoute', title: t('request_for_quotation'), key: 'shop_by_brands', id: 1, image: '/assets/images/womenhome2.jpg' }
    ];
    const custom_jersey = [
        { url: '/request-a-qoute', title: t('request_for_quotation'), key: 'shop_by_brands', id: 1, image: '/assets/images/custom_jersey.gif' }
    ];
    const brand = [
        { url: '/brands/abaya-brands', title: t('shop_by_brands'), key: 'shop_by_brands', id: 1, image: '/assets/images/shopsbybrands.jpg' },
    ];
    let image = settings.image;
    return (
        <div className="brands pb-4">
            <div className="header" style={{ backgroundImage: `url(${environment.staticUrl + 'categories/' + image})` }}>
                <h1 className="d-none"><strong>{t('tailor_customize')}</strong></h1>
                <h2 className="d-none"><strong>{settings?.pdc['category_' + lang]?.title}</strong></h2>
            </div>
            <StaticPara
                content={{
                    heading: t(props.match.params.slug.replace(/-/g, '_') + '_h'),
                    paragraph_1: t(props.match.params.slug.replace(/-/g, '_') + '_p'),
                    paragraph_2: t(props.match.params.slug.replace(/-/g, '_') + '_p1')
                }}
                {...props}
            />

            {
                params.slug === 'custom-sports-jersey' ?
                    <>
                        <div className="mb-3">
                            <ShowCase
                                titleStyle={{
                                    top: 110,
                                    margin: "0 0 0 auto",
                                    padding: 8,
                                    fontSize: 14,
                                    width: "35%",
                                    lineHeight: "18px !important",
                                    borderRadius: "50px 50px",
                                    textTransform: "uppercase",
                                    backgroundColor: "#9db2a8",
                                }}
                                itemStyle={
                                    {
                                        height: 180
                                    }
                                }
                                wishlist={false}
                                showOnlyTitle={true}
                                className="col-12 col-md-12 col-lg-12 col-xl-12 animate__animated animate__bounce"
                                showcases={custom_jersey} />
                        </div>
                        <StaticProduct
                            {...props}
                        />
                    </>
                    : params.slug === 'custom-thob' ?
                        <div className="mt-3">
                            <BrandsComponent
                                category="men"
                                showHeader={false}
                                showCounter={true}
                                title={t('tailor_shop')}
                                {...props} />
                        </div>
                        : (
                            <>
                                {
                                    params.slug === 'custom-sports-jersey' ? ''
                                        :
                                        <div className="mb-3">
                                            <ShowCase
                                                titleStyle={{
                                                    top: 315,
                                                    margin: "0 14px 0 auto",
                                                    padding: 8,
                                                    fontSize: 14,
                                                    width: "35%",
                                                    lineHeight: "18px !important",
                                                    borderRadius: "50px 50px",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#9db2a8",
                                                }}
                                                showOnlyTitle={true}
                                                className="col-12 col-md-12 col-lg-12 col-xl-12 animate__animated animate__bounce"
                                                showcases={women} />
                                        </div>
                                }
                                <ProductCategory
                                    title={''}
                                    {...props}
                                    showHeader={false}
                                    showFilters={false}
                                    slug={params.slug} />
                                {/* <div className="container-fluid p-0 p-xl-3">
                            <CategoryShowCase
                                className="col-6 col-md-6 col-lg-3 col-xl-3 text-center"
                                activeKey={settings.fall_season_type}
                                itemStyle={{
                                    height: 300
                                }}
                                onTabClick={(e, tab, index) => {
                                    e.preventDefault();
                                    const selectedFallProducts = mapFallProducts(settings.fallSeasonsProducts[tab.key]);
                                    setSettings({
                                        ...settings,
                                        fall_season_type: tab.key,
                                        selectedFallProducts: selectedFallProducts
                                    })
                                }}
                                showcases={settings.selectedFallProducts} />
                        </div> */}
                                {
                                    params.slug === 'custom-sports-jersey' ? ''
                                        :
                                        <div className="mb-3">
                                            <ShowCase
                                                titleStyle={{
                                                    top: 200
                                                }}
                                                showOnlyTitle={true}
                                                className="col-12 col-md-12 col-lg-12 col-xl-12 animate__animated animate__bounce"
                                                showcases={brand} />
                                        </div>
                                }
                            </>
                        )
            }
        </div>
    )
}