import { useContext, useEffect, useState } from "react";
import { Axios } from '../helpers';
import swal from 'sweetalert';
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "../hooks/Translation";

export const ForgotPassword = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({
        isFormValid: false,
        loader: false,
        state: { email: '', password: '' },
        errors: {
            email: [],
            password: []
        }
    });
    const { auth, setAuth } = useContext(AuthContext)
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        Axios({}).post('/forget', settings.state).then((res) => {
            if (res.data.success) {
                setSettings({ ...settings, loader: false })
                swal(t("forgot_password_title"), t('check_your_email'), "success")
                props.history.push('/my-account/resetpassword');
            }
        }).catch(error => {
            swal("Oops", error.response.data.message, "error")
        })
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    return (
        <div className="myaccount container withoutheader-mobile">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <div className="col-12 col-lg-8 offset-lg-2">
                <h2 className="app-title">{t('forgot_password')}</h2>
                <form method="POST" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label for="username" className="form-label">{t('email')}</label>
                        <input
                            required={true}
                            type="email"
                            value={settings.state.email}
                            onChange={handleInputChange}
                            className="form-control"
                            id="username"
                            name="email"
                            placeholder={t('email') + "*"} />
                    </div>
                    <button className="btn btn-primary form-control mt-3">
                        {
                            settings.loader ?
                                <i className="fa fa-spin fa-spinner"></i>
                                : <span>{t('send')}</span>
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}