import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel, CarouselV2 } from "../../components/Carousel";
import { CategoryShowCase } from "../../components/CategoryShowcase";
import { HCarousel } from "../../components/HCarousel";
import { ShowCase } from "../../components/ShowCase";
import { fluidShowcases, genderCategoryShowCases, genderCategoryShowCaseTabs, showcases, genderCategoryShowCases2, genderCategoryShowCaseTabs2, football } from "../../placeholder";
import { Axios } from '../../helpers';
import { SideBarContext } from "../../contexts/SidebarContext";
import { environment } from '../../config';
import axios from "axios";
import { useTranslation } from "../../hooks/Translation";
import { StaticPara } from "../../components/StaticPara";
import { ColumnShowCase } from "../../components/ColumnShowCase";
import { TitleShowCase } from "../../components/TitleShowCase";
import { TextShowCase, TitleImageShowCase } from "../../components/TitleImageShowCase";
import { ShopsByCategory } from "../../components/ShopsByCategory";

export const HomeV2 = (props) => {
    const t = useTranslation();
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const [settings, setSettings] = useState({
        category1: { title: '', showcase: [], image: 'cat1.jpeg' },
        category3: { title: '', showcase: [], image: 'cat1.jpeg' },
        tailor1: { title: '', showcase: [], image: 'cat1.jpeg' },
        tailor2: { title: '', showcase: [], image: 'cat1.jpeg' },
        shop_by_categories: {},
        content1: {},
        content2: {},
        fall_season_type: 'new_products',
        home_category_type: 'new_products',
        fall_seasons: [],
        fallSeasonsProducts: [],
        cate3AtiveProduct: [],
        cate3ActiveIndex: 0,
        homeCategories: {
            title: '',
            tabs: [],
            categories: {},
            selected: {
                title: '',
                products: []
            }
        },
        menEssentials: {
            title: t('men_essentials'),
            showcases: []
        },
        slider: [{ img: '/assets/images/logo.png' }],
        brandSliders: []
    });
    const lang = localStorage.getItem('lang') || 'en';
    useEffect(() => {
        setAppSettings({ ...appSettings, loading: true, sidenav: false });
        setSettings({ ...settings, loader: true });
        axios.all([
            Axios({}).get(`/home`),
            // Axios({}).get(`/fall-season-products`),
            // Axios({}).get(`/men-essentials`),
            // Axios({}).get(`/home-category`),
            // Axios({}).get(`/brand-sliders`),
        ]).then(axios.spread((homeRes, fallSeasonsProducts) => {
            const sliderV2 = homeRes.data.data['slider'].map((s) => {
                return {
                    img: environment.staticUrl + s.image,
                    ...s
                }
            })
            const category1 = {
                title: homeRes.data.data['category_1'].title,
                showcase: homeRes.data.data['category_1'].categories,
                image: `/assets/images/` + homeRes.data.data['category_1'].image
            };
            const category3 = {
                title: homeRes.data.data['category_2'].title,
                showcase: homeRes.data.data['category_2'].categories,
                image: `/assets/images/` + homeRes.data.data['category_2'].image
            };
            const cate3AtiveProduct = homeRes.data.data['category_2'].categories[0].products;
            const tailor1 = {
                title: homeRes.data.data['tailor_1'].title,
                showcase: Object.keys(homeRes.data.data['tailor_1'].categories).map((r) => {
                    return {
                        title: homeRes.data.data['tailor_1'].categories[r],
                        slug: r
                    }
                }),
                image: `/assets/images/` + homeRes.data.data['tailor_1'].image
            };
            const tailor2 = {
                title: homeRes.data.data['tailor_2'].title,
                showcase: homeRes.data.data['tailor_2'].categories.map((cat) => {
                    return {
                        ...cat,
                        image: `/assets/images` + cat.image,
                    }
                }),
                image: `/assets/images` + homeRes.data.data['tailor_2'].image
            };
            setSettings({
                ...settings,
                category1: category1,
                tailor1: tailor1,
                tailor2: tailor2,
                category3: category3,
                cate3AtiveProduct: cate3AtiveProduct,
                cate3ActiveIndex: 0,
                shop_by_categories: homeRes.data.data.shop_by_categories,
                content2: homeRes.data.data.content2,
                content1: homeRes.data.data.content1,
                slider: sliderV2,
                loader: false,
            })
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
            setSettings({ ...settings, loader: false });
        });
    }, []);
    return (
        <div className="pb-4">
            {
                !settings.loader ?
                    <CarouselV2
                        id="hslider"
                        sliders={settings.slider} />
                    : (
                        <div className="bolder text-center m-5" style={{ fontSize: 32, height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i className="fa fa-spin fa-spinner"></i>
                        </div>
                    )
            }
            <br />
            <StaticPara content={settings.content1} {...props} />
            <div className="container-fluid p-0 ">
                <ColumnShowCase
                    title={settings.category1.title.replace(/-/g, ' ')}
                    tabs={settings?.category1.showcase}
                    image={settings?.category1.image}
                    showcases={showcases} />
                <TitleShowCase
                    style={{
                        height: 400
                    }}
                    url="custom"
                    title={settings.tailor1.title.replace(/-/g, ' ')}
                    tabs={settings?.tailor1.showcase}
                    image={settings?.tailor1.image}
                    className="col-12 col-md-12 col-lg-6 col-xl-6 animate__animated animate__bounce"
                    showcases={showcases} />
                <TextShowCase
                    content={settings.content2 || {}}
                    {...props}
                />
                <TitleImageShowCase
                    onTabClick={(e, tab, index) => {
                        const cate3AtiveProduct = settings.category3.showcase[index].products;
                        setSettings({
                            ...settings,
                            cate3ActiveIndex: index,
                            cate3AtiveProduct: cate3AtiveProduct
                        })
                    }}
                    activeIndex={settings.cate3ActiveIndex}
                    title={settings.category3.title.replace(/-/g, ' ')}
                    tabs={settings?.category3.showcase}
                    image={settings?.category3.image}
                    {...props}
                />
                <div className="container-fluid">
                    <div className="row">
                        {
                            settings.cate3AtiveProduct.map((cat3) => {
                                return (
                                    <Link
                                        to={'/shops/' + cat3.slug}
                                        className="col-6 col-md-3 text-center p-1 " style={{ position: 'relative', border: '1.5px solid #dee2e6' }}>
                                        <img
                                            // style={{ height: 300 }}
                                            src={environment.staticUrl + 'products/' + cat3.image}
                                            className="img-fluid" />
                                        <div style={{
                                            position: 'absolute',
                                            zIndex: 2,
                                            bottom: 0,
                                            background: `rgba(255,255,255, 0.7)`,
                                            height: 60,
                                            width: '100%',
                                            padding: '1ex',
                                            paddingLeft: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}>
                                            <p className="p-0 m-0" style={{ textTransform: 'uppercase', textAlign: 'left' }}><strong>{cat3.title}</strong></p>
                                            <p className="p-0 m-0" style={{ textTransform: 'uppercase', textAlign: 'left' }}><strong>{cat3.price}</strong></p>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                        <div className="col-12 text-center">
                            {
                                settings.cate3AtiveProduct.length == 0 ? <h1>{t('product_coming_soon')}</h1> : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <TitleShowCase
                    style={{
                        // height: 500
                    }}
                    withCatImages={true}
                    title={settings.tailor2.title.replace(/-/g, ' ')}
                    tabs={settings?.tailor2.showcase}
                    image={settings?.tailor2.image}
                    className="col-12 col-md-12 col-lg-6 col-xl-6 animate__animated animate__bounce"
                    showcases={showcases} />
                <ShopsByCategory
                    data={settings.shop_by_categories}
                    {...props}
                />
                {/* <ShowCase
                    className="col-12 col-md-12 col-lg-6 col-xl-6"
                    title={settings.menEssentials.title}
                    showcases={settings.menEssentials.showcases} /> */}
                {/* {
                    !settings.loader ?
                        <HCarousel
                            activeKey={settings.home_category_type}
                            className="col-12 col-md-12 col-lg-3 col-xl-3"
                            title={settings.homeCategories.title.replace(/-/g, ' ')}
                            initItems={5}
                            onTabClick={(e, tab, index) => {
                                e.preventDefault();
                                const homeCategories = mapHomeCategory(settings.homeCategories.categories, tab.slug);
                                setSettings({
                                    ...settings,
                                    home_category_type: tab.slug,
                                    loader: true,
                                    homeCategories: {
                                        ...settings.homeCategories,
                                        title: homeCategories.title,
                                        tabs: homeCategories.tabs,
                                        selected: homeCategories.selected
                                    }
                                });
                                setTimeout(() => {
                                    setSettings({
                                        ...settings,
                                        home_category_type: tab.slug,
                                        loader: false,
                                        homeCategories: {
                                            ...settings.homeCategories,
                                            title: homeCategories.title,
                                            tabs: homeCategories.tabs,
                                            selected: homeCategories.selected
                                        }
                                    });
                                }, 200);
                            }}
                            itemStyle={{
                                height: 220
                            }}
                            tabs={settings?.homeCategories?.tabs}
                            showcases={settings.homeCategories?.selected?.products} />
                        : (
                            <div className="bolder text-center m-5" style={{ fontSize: 32 }}>
                                <i className="fa fa-spin fa-spinner"></i>
                            </div>
                        )
                } */}
                {/* <ShowCase
                    imageTag={true}
                    itemStyle={{
                        height: 400,
                        background: '#eeeeee',
                        textAlign: 'center'
                    }}
                    actionStyle={{
                        top: 350,
                        left: 40
                    }}
                    className="col-12"
                    title={t('customize')}
                    showcases={football}
                    renderActions={(showcase) => {
                        return (
                            <>
                                <Link to="request-a-qoute" className="d-block btn btn-dark mb-3">{t('customize_sportwear')}</Link>
                                <Link to="/sports-wear" className="btn btn-dark">{t('sports_wear')}</Link>
                            </>
                        )
                    }}
                /> */}
            </div>
        </div>
    )
}