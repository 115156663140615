import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom";
import { MobileNavbar } from "./components/MobileNavBar";
import { MobileSideNavbar } from "./components/MobileSideNavbar";
import { MobileTopNavBar } from "./components/MobileTopNavBar";
import { Navbar } from "./components/Navbar";
import { Search } from "./components/SearchComponent";
import { SideBar } from "./components/Siderbar";
import { CartContext } from "./contexts/CartContext";
import { SideBarContext } from "./contexts/SidebarContext";
import { Home } from "./pages/Home";
import { SlugPages } from "./pages/slug/SlugPages";
import { routes } from "./routes";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import { slugAllowdPages } from "./slug-routes";
import { LeftSideBar } from "./components/LeftSideBar";
import { AuthContext } from "./contexts/AuthContext";
import { RecentlyViewdItems } from "./components/RecentlyViewdItems";
import { Axios } from "./helpers";
import { LayoutContext } from "./contexts/LayoutContext";
import axios from "axios";
export const mobileNav = [
  {
    title: 'Menu',
    icon: 'lnr lnr-menu-circle',
    url: '',
    callback: (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      document.getElementById('menubtn').click();
    }
  },
  {
    title: 'Account',
    icon: 'lnr lnr-user',
    url: '/my-account'
  }
]
export const app_settings = {
  mobileNav: [
    {
      title: 'Menu',
      icon: 'lnr lnr-menu-circle',
      callback: (e) => {
        if (e.preventDefault) {
          e.preventDefault();
        }
        document.getElementById('menubtn').click();
      }
    },
    {
      title: 'Account',
      icon: 'lnr lnr-user',
      url: '/my-account'
    }
  ],
  showHome: false,
  leftsidenav: false,
  sidenav: false,
  show: false,
  search: false,
  loading: false,
  dir: 'ltr',
  lang: 'en',
  paddingTop: false,
  translate: {}
};
function App(props) {
  const [appSettings, setAppSettings] = useState(app_settings);
  const [layoutSettings, setLayoutSettings] = useState(app_settings);
  const [isLoaded, setIsLoaded] = useState(false);
  const [auth, setAuth] = useState({ auth: {}, is_auth: false });
  const [cartContext, setCartContext] = useState({ cart: [], wishlist: [], recentViews: [] });
  const llocation = useLocation();
  const onSetCartContext = (cart) => {
    setCartContext({ ...cartContext, cart: cart })
    localStorage.setItem('cart', JSON.stringify(cart));
  };
  const onSetRecentViewsContext = (recentViews) => {
    if (recentViews.length > 10 && Array.isArray(recentViews)) {
      recentViews.shift();
    }
    setCartContext({ ...cartContext, recentViews: recentViews })
    localStorage.setItem('recent-views', JSON.stringify(recentViews));
  };
  const onSetWishListContext = (wishlist) => {
    setCartContext({ ...cartContext, wishlist: wishlist })
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
  };
  useEffect(() => {
    try {
      const authData = JSON.parse(localStorage.getItem('auth')) || null;
      if (authData) {
        const token = JSON.parse(localStorage.getItem('auth')).token
        const p = JSON.parse(atob(token.split('.')[1]));
        if (Date.now() >= p.exp * 1000) {
          setAuth({ is_auth: false, auth: {} })
        } else {
          setAuth({ is_auth: true, auth: authData })
        }
      }
    } catch {
      setAuth({ is_auth: false, auth: {} })
    }
    try {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      const wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
      const recentViews = JSON.parse(localStorage.getItem('recent-views')) || [];
      setCartContext({ cart, wishlist: wishlist, recentViews: recentViews });
    } catch (e) {
      console.log(e);
    }
    axios.all([
      Axios({}).get('/categories'),
      Axios({}).get('/lang')
    ]).then(axios.spread((catRes, langRes) => {
      setLayoutSettings({ ...layoutSettings, translate: langRes.data });
      localStorage.setItem('categories', JSON.stringify(catRes.data));
      setAppSettings({ ...appSettings, categories: catRes.data });
      setIsLoaded(true);
    })).catch((error) => {
      setIsLoaded(true);
    })
    const lang = localStorage.getItem('lang') || 'en';
    const dir = localStorage.getItem('dir') || 'ltr';
    if (lang && dir) {
      document.documentElement.lang = lang;
      document.documentElement.dir = dir;
      setAppSettings({ ...appSettings, lang: lang, dir: dir })
    }
  }, []);
  useEffect(() => {
    // setLayoutSettings({ ...layoutSettings, loading: false, sidenav: false, show: false, paddingTop: false, mobileNav: mobileNav })
    setAppSettings({ ...appSettings, loading: false, sidenav: false, show: false, paddingTop: false, mobileNav: mobileNav });
    const rootElement = document.documentElement;
    rootElement.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [llocation.pathname]);
  if (!isLoaded) {
    return (
      <div className="loading"></div>
    )
  }
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <LayoutContext.Provider value={{ layoutSettings, setLayoutSettings }}>
        <CartContext.Provider value={{ cartContext, onSetCartContext, onSetWishListContext, onSetRecentViewsContext }}>
          <SideBarContext.Provider value={{ appSettings, setAppSettings }}>
            <div hidden={!appSettings.loading} className="loading"></div>
            {
              appSettings.search ?
                <Search {...props}></Search>
                : ''
            }
            <div className="App">
              <SideBar {...props} />
              <MobileSideNavbar {...props} />
              <MobileTopNavBar {...props} />
              <LeftSideBar {...props} />
              <Navbar {...props} />
              <Switch>
                {
                  routes.map((route) => {
                    return (
                      <Route
                        exact={route.exact}
                        key={route.path}
                        path={route.path}
                        render={(props) => {
                          const crumbs = routes
                            .filter(({ path }) => props.match.path.includes(path))
                            .map(({ path, ...rest }) => ({
                              path: Object.keys(props.match.params).length
                                ? Object.keys(props.match.params).reduce(
                                  (path, param) => path.replace(
                                    `:${param}`, props.match.params[param]
                                  ), path
                                )
                                : path,
                              ...rest
                            }));
                          return (
                            <>
                              <div className="app-breadcrumb d-none d-xl-block">
                                <nav aria-label="breadcrumb" className="container" style={{ position: 'relative' }}>
                                  {
                                    window.location.pathname !== '/' && crumbs && crumbs.length > 1 ?
                                      <div
                                        onClick={() => {
                                          window.history.back();
                                        }}
                                        className="d-inline-block back-button">
                                        <a><i className="fa fa-chevron-left"></i>
                                        </a>
                                      </div>
                                      : ''
                                  }
                                  <ol className="breadcrumb m-0">
                                    {
                                      crumbs.map((crumb, index) => {
                                        if (crumbs.length === (index + 1)) {
                                          if (crumb.isRootSlugRoute && slugAllowdPages[props.match.params.slug]) {
                                            const rName = slugAllowdPages[props.match.params.slug].name;
                                            return <li
                                              key={index}
                                              aria-current="page"
                                              className="breadcrumb-item active">
                                              <Link to={crumb.path}>{layoutSettings?.translate[rName] || rName}</Link>
                                            </li>
                                          } else {
                                            return <li
                                              key={index}
                                              className="breadcrumb-item active"
                                              aria-current="page">
                                              <Link to={crumb.path}>{layoutSettings?.translate[crumb.name] || crumb.name}</Link>
                                            </li>
                                          }
                                        } else {
                                          if (crumb.isRootSlugRoute && slugAllowdPages[props.match.params.slug]) {
                                            const rName = slugAllowdPages[props.match.params.slug].name;
                                            return <li
                                              key={index}
                                              className="breadcrumb-item">
                                              <Link to={crumb.path}>{layoutSettings?.translate[rName] || rName}</Link>
                                            </li>
                                          } else {
                                            return <li
                                              key={index}
                                              className="breadcrumb-item">
                                              <Link to={crumb.path}>{layoutSettings?.translate[crumb.name] || crumb.name}</Link>
                                            </li>
                                          }
                                        }
                                      })
                                    }
                                  </ol>
                                </nav>
                              </div>
                              <route.component {...props} breadCrumbs={crumbs} />
                            </>
                          )
                        }}
                      />
                    )
                  })
                }
              </Switch>
              <RecentlyViewdItems {...props} />
              <MobileNavbar {...props} />
              <div
                className="mt-4 d-lg-none"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 200,
                  width: '100%',
                  backgroundImage: `url(/assets/images/bg_placeholder.png)`,
                  backgroundRepeat: 'repeat',
                  flexDirection: 'column'
                }}
              >
                <button
                  onClick={() => {
                    const rootElement = document.documentElement;
                    rootElement.scrollTo({
                      top: 0,
                      behavior: "smooth"
                    });
                  }}
                  style={{
                    background: '#9db2a8',
                    border: 'none',
                    width: 60,
                    height: 60,
                    borderRadius: '100%'
                  }}>
                  <i className="lnr lnr-chevron-up"></i>
                </button>
                <p>{layoutSettings?.translate['back_to_top']}</p>
              </div>
            </div>
          </SideBarContext.Provider>
        </CartContext.Provider>
      </LayoutContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
