import { useContext, useEffect, useState } from "react";
import { Axios } from '../../helpers';
import swal from 'sweetalert';
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { environment } from "../../config";
import { SideBarContext } from "../../contexts/SidebarContext";
import { SocialLogin } from "./SocialLogin";
import { useTranslation } from "../../hooks/Translation";

export const Login = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({
        isFormValid: false,
        loader: false,
        state: { email: '', password: '' },
        errors: {
            email: [],
            password: []
        }
    });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { auth, setAuth } = useContext(AuthContext)
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        Axios({}).post('/login', settings.state).then((res) => {
            let image = environment.staticUrl + 'users/' + res.data.response_data.image;
            res.data.response_data.image = image;
            localStorage.setItem('auth', JSON.stringify({ ...res.data.response_data, image }));
            setAuth({ is_auth: true, auth: res.data });
            setSettings({ ...settings, loader: false })
            window.location.reload();
        }).catch(error => {
            setSettings({ ...settings, loader: false })
            console.dir(error);
        })
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    return (
        <div className="login">
            <h2 className="text-center bolder">{t('login')}</h2>
            <form method="POST" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label for="email" className="form-label">{t('email')}</label>
                    <input
                        required={true}
                        type="email"
                        value={settings.state.email}
                        onChange={handleInputChange}
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder={t('email') + "*"} />
                </div>
                <div className="mb-3">
                    <label for="password" className="form-label">{t('login')}</label>
                    <input
                        type="password"
                        required={true}
                        value={settings.state.password}
                        onChange={handleInputChange}
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder={t('password') + "*"} />
                </div>
                <div className="row">
                    <div className="col-6">
                        <div>
                            <input className="form-check-input d-inline-block" type="checkbox" value="" id="rememberme" />
                            &nbsp;
                            <label className="form-check-label d-inline-block" for="rememberme">
                                {t('remember_me')}
                            </label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="text-right" style={{ textAlign: 'right' }}>
                            <Link
                                to="/my-account/forgotpassword" href="/">{t('lost_your_password')}</Link>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary form-control mt-3">
                    {
                        settings.loader ?
                            <i className="fa fa-spin fa-spinner"></i>
                            : <span>{t('login')}</span>
                    }
                </button>
                <SocialLogin {...props} />
            </form>
        </div>
    )
}