import axios from "axios";
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/AuthContext";
import { SideBarContext } from "../../contexts/SidebarContext";
import { Axios, parseQueryString } from "../../helpers";
import { UserAuthenticatedArea } from "./UserAuthenticatedArea";
import { useTranslation } from "../../hooks/Translation";

const BillingAddress = (props) => {
    const t = useTranslation();
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const [settings, setSettings] = useState({
        isFormValid: false,
        loader: false,
        state: {
            da_name: '',
            contact: '',
            city: '',
            da_address: '',
            is_default: true,
            email: '',
            slug: ''
        },
        errors: {
            da_name: [],
            contact: []
        }
    });
    const { auth, setAuth } = useContext(AuthContext)
    useEffect(() => {
        setAppSettings({ ...appSettings, loading: true });
        axios.all([
            Axios({}).get(props.config?.get),
        ]).then(axios.spread((addressRes) => {
            setSettings({
                ...settings,
                state: { ...settings.state, ...addressRes.data, da_address: addressRes.data.address, da_name: addressRes.data.name },
            })
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        });
    }, [])
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        if (!settings.state.slug) {
            Axios({}).post(props.config?.add, { ...settings.state, is_default: true, da_name: props.config?.name }).then((res) => {
                setSettings({ ...settings, loader: false })
            }).catch(error => {
                setSettings({ ...settings, loader: false })
            })
        } else {
            Axios({}).post(`${props.config?.put}/${settings.state.slug}`, { ...settings.state, is_default: true, da_name: props.config?.name }).then((res) => {
                setSettings({ ...settings, loader: false })
            }).catch(error => {
                setSettings({ ...settings, loader: false })
            })
        }
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    return (
        <div className="billing-address mb-5">
            <h2>{props.config?.name}</h2>
            <form method="POST" onSubmit={handleSubmit}>
                <div hidden={true} className="mb-3">
                    <label htmlFor="da_name" className="form-label">{t('name')}</label>
                    <input
                        value={props.config?.name}
                        onChange={handleInputChange}
                        value="Delivery Address"
                        name="da_name"
                        type="text"
                        className="form-control"
                        required={true}
                        id="da_name"
                        placeholder={t('name') + "*"} />
                </div>
                <div className="mb-3">
                    <label htmlFor="da_address" className="form-label">{t('street_address')}</label>
                    <input
                        type="text"
                        value={settings.state.da_address}
                        required={true}
                        onChange={handleInputChange}
                        name="da_address"
                        className="form-control"
                        id="da_address"
                        placeholder={t('street_address')} />
                </div>
                <div className="mb-3">
                    <label htmlFor="city" className="form-label">{t('town_city')}</label>
                    <input
                        type="text"
                        value={settings.state.city}
                        required={true}
                        onChange={handleInputChange}
                        name="city"
                        className="form-control"
                        id="city"
                        placeholder={t('town_city')} />
                </div>
                <div className="mb-3">
                    <label htmlFor="contact" className="form-label">{t('phone')}</label>
                    <input
                        type="text"
                        value={settings.state.contact}
                        onChange={handleInputChange}
                        name="contact"
                        placeholder={t('phone')}
                        className="form-control"
                        id="contact" />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">{t('email_address')}</label>
                    <input
                        type="email"
                        value={settings.state.email}
                        onChange={handleInputChange}
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder={t('email_address')} />
                </div>
                <button className="btn btn-primary form-control mt-3">
                    {
                        settings.loader ?
                            <i className="fa fa-spin fa-spinner"></i>
                            : <span>{t('save')}</span>
                    }
                </button>
            </form>
        </div>
    )
}

export const Addresses = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ deliveryAddrss: {}, billingAddress: {}, view: '', billing: { name: 'Billing Address' }, delivery: { name: 'Delivery Address' } })
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    useEffect(() => {
        const views = ['billing', 'shipping'];
        try {
            if (props.location.search) {
                const qs = parseQueryString(props.location.search);
                if (qs.view && views.indexOf(qs.view) !== -1) {
                    setSettings({ ...settings, view: qs.view });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => {
        setAppSettings({ ...appSettings, loading: true });
        axios.all([
            Axios({}).get('/default-delivery-addresses'),
            Axios({}).get('/default-billing-addresses'),
        ]).then(axios.spread((deliveryAddrss, billingAddress) => {
            setSettings({
                ...settings,
                deliveryAddrss: deliveryAddrss.data,
                billingAddress: billingAddress.data,
            })
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        })).catch(errors => {
            setAppSettings({ ...appSettings, sidenav: false, loading: false });
        });
    }, [])
    const componentSettings = {
        'billing': BillingAddress,
        'delivery': BillingAddress
    }
    const s = {
        'delivery': {
            add: '/add-delivery-address',
            put: '/update-delivery-address',
            get: '/default-delivery-addresses',
            name: t('delivery_address'),
        },
        'billing': {
            add: '/add-billing-address',
            put: '/update-billing-address',
            get: '/default-billing-addresses',
            name: t('billing_address'),
        }
    }
    let Component = <>Not Found</>
    if (settings.view !== '' && componentSettings[settings.view]) {
        Component = componentSettings[settings.view];
    }
    if (props.location.search === '' && settings.view !== '') {
        setSettings({ view: '' });
    }
    return (
        <div className="myaccount container withoutheader-mobile mb-5">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <h1 className="app-title">{t('addresses')}</h1>
            <UserAuthenticatedArea>
                {
                    settings.view === '' ?
                        <>
                            <p>{t('the_following_address_used')}</p>
                            <div className="row">
                                <div className="col-12 col-lg-6 col-xl-6 mt-3">
                                    <div className="box gray">
                                        <h3>{t('billing_address')}</h3>
                                        {
                                            settings.billingAddress && settings.billingAddress.email ?
                                                (
                                                    <div>
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>{t('email')}</td>
                                                                    <td>{settings.billingAddress.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('city')}</td>
                                                                    <td>{settings.billingAddress.city}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('contact')}</td>
                                                                    <td>{settings.billingAddress.contact}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('address')}</td>
                                                                    <td>{settings.billingAddress.address}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                                : <p>{t('not_setup_address')}.</p>
                                        }
                                        <button
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: props.location.pathname,
                                                    search: '?view=billing'
                                                })
                                                setSettings({
                                                    ...settings,
                                                    view: 'billing'
                                                })
                                            }}
                                            className="btn btn-dark btn-sm">
                                            {
                                                settings.billingAddress && settings.billingAddress.email ?
                                                    t('edit')
                                                    : t('add')
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-6 mt-3">
                                    <div className="box gray">
                                        <h3>{t('delivery_address')}</h3>
                                        {
                                            settings.deliveryAddrss && settings.deliveryAddrss.email ?
                                                (
                                                    <div>
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>{t('email')}</td>
                                                                    <td>{settings.deliveryAddrss.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('city')}</td>
                                                                    <td>{settings.deliveryAddrss.city}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('contact')}</td>
                                                                    <td>{settings.deliveryAddrss.contact}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('address')}</td>
                                                                    <td>{settings.deliveryAddrss.address}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                                : <p>{t('not_setup_address')}.</p>
                                        }
                                        <button
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: props.location.pathname,
                                                    search: '?view=delivery'
                                                })
                                                setSettings({
                                                    ...settings,
                                                    view: 'delivery'
                                                })
                                            }}
                                            className="btn btn-dark btn-sm">
                                            {
                                                settings.deliveryAddrss && settings.deliveryAddrss.email ?
                                                    t('edit')
                                                    : t('add')
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <Component
                            {...props}
                            config={s[settings.view]}
                            state={settings[settings.view]}
                            name={settings.view}>
                        </Component>
                }
            </UserAuthenticatedArea>
        </div>
    )
}