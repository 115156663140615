import { Link } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";

export const TitleShowCase = (props) => {
    const t = useTranslation();
    const itemStyle = props.itemStyle ? props.itemStyle : {};
    const actionStyle = props.actionStyle ? props.actionStyle : {};
    const isRtl = document.documentElement.dir === 'rtl' ? true : false;
    return (
        <div className="titleshowcases" style={props.style} data-aos="fade-right">
            <div
                className={`showcase-img content`} 
                style={{ 
                    backgroundImage: `linear-gradient(to bottom, rgb(0 0 0 / 21%), rgb(0 0 0 / 38%)), url(${props.image})`, 
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center',
                    height: 400,
                    
                }}>
                    {
                        isRtl ?
                            <h2 className="title-rtl">
                                { props.title }
                            </h2>
                        : (
                            <h2 className="title">
                                { props.title }
                            </h2>
                        )
                    }
                {
                    props.withCatImages ?
                        <div className="text-center actions">
                            <div style={{ maxWidth: 400, margin: '0 auto' }}>
                                {
                                    props.tabs.map((tab, i) => {
                                        return (
                                            <Link 
                                                key={i}
                                                to={props.url ? props.url : '/products/' + tab.slug}
                                                className="d-inline-block singleimg m-3 p-0 text-center" >
                                                <img 
                                                    className="img-fluid" src={tab.image} 
                                                    style={{ height: 60, width: 60, borderRadius:'100%', objectFit: 'unset' }} />
                                                <h5 style={{ color: 'white' }}>{ tab.title }</h5>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    : 
                    <div className="actions m-0 text-center row">
                        {
                            props.tabs.map((tab) => {
                                return (
                                    <div className="col-6 col-md-3 text-center" style={{ margin: '0 auto' }}>
                                        <Link to={props.url ? props.url + '/' + tab.slug : '/products/' + tab.slug}>
                                            <button 
                                                style={{ minWidth: 150, width: '100%', border: 0, outline: 0 }}
                                                className="circle-button text-center m-1">{ tab.title }</button>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}