import { useContext, useEffect, useState } from "react";
import { Axios } from '../helpers';
import swal from 'sweetalert';
import { AuthContext } from "../contexts/AuthContext";
import { parseQueryString } from '../helpers'
import { useTranslation } from "../hooks/Translation";

export const ResetPassword = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({
        isFormValid: false,
        loader: false,
        state: { reset_code: '', password: '', c_password: '' },
        errors: {
            email: [],
            c_password: []
        },
        message: {
            text: '',
            className: ''
        }
    });
    const { auth, setAuth } = useContext(AuthContext);
    useEffect(() => {
        try {
            const pQueryString = parseQueryString(props.location.search);
            if (pQueryString && pQueryString.code) {
                setSettings({ ...settings, state: {...settings.state, reset_code: pQueryString.code } });
            } else {
                setSettings({
                    ...settings,
                    message: {
                        text: t('reset_code_not_valid'),
                        className: 'alert alert-danger'
                    }
                });
            }
        } catch (error) {
            setSettings({
                ...settings,
                message: {
                    text: t('reset_code_not_valid'),
                    className: 'alert alert-danger'
                }
            });
        }
    }, [])
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (settings.state.password !== settings.state.c_password) {
            swal("Oops", t('please_confirm_your_password'), "info")
            return;
        }
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        Axios({}).post('/reset', settings.state).then((res) => {
            if (res.data.success) {
                setSettings({ ...settings, loader: false })
                swal(t("reset_password_title"), t('password_reset_login_please'), "success")
                props.history.push('/my-account');
            }
        }).catch(error => {
            swal("Oops", error.response.data.message, "error")
        })
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    return (
        <div className="myaccount container withoutheader-mobile">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <div className="col-12 col-lg-8 offset-lg-2">
                <h2 className="app-title">{t('reset_password')}</h2>
                <form method="POST" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">{t('reset_code')}</label>
                        <input
                            required={true}
                            type="code"
                            value={settings.state.reset_code}
                            onChange={handleInputChange}
                            className="form-control"
                            id="reset_code"
                            name="reset_code"
                            placeholder={t('reset_code') + "*"} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">{t('password')}</label>
                        <input
                            required={true}
                            type="password"
                            value={settings.state.password}
                            onChange={handleInputChange}
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder={t('password') + "*"} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="c_password" className="form-label">{t('confirm_password')}</label>
                        <input
                            required={true}
                            type="password"
                            value={settings.state.c_password}
                            onChange={handleInputChange}
                            className="form-control"
                            id="c_password"
                            name="c_password"
                            placeholder={t('confirm_password') + "*"} />
                    </div>
                    <button className="btn btn-primary form-control mt-3">
                        {
                            settings.loader ?
                                <i className="fa fa-spin fa-spinner"></i>
                                : <span>{t('reset')}</span>
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}