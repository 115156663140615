import { useContext } from "react";
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/AuthContext";
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login'
import { useTranslation } from "../../hooks/Translation";
import { Axios } from '../../helpers';
import { SideBarContext } from "../../contexts/SidebarContext";
import { environment } from "../../config";

export const SocialLogin = (props) => {
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const t = useTranslation();
    const { auth, setAuth } = useContext(AuthContext)
    const responseGoogleFail = (response) => {
        console.log(response);
    }
    const responseGoogle = (response) => {
        if (response.profileObj) {
            setAppSettings({ ...appSettings, loading: true });
            Axios({}).post('/social-login/google', response.profileObj).then((res) => {
                let image = environment.staticUrl + 'users/' + res.data.response_data.image;
                res.data.response_data.image = image;
                localStorage.setItem('auth', JSON.stringify({ ...res.data.response_data, image }));
                window.location.reload();
            }).catch(error => {
                setAppSettings({ ...appSettings, loading: false });
                console.dir(error);
            })
        }
    }
    const is_safari = navigator.userAgent.indexOf("Safari") > -1;
    return <div></div>;
    return (
        <div className="row">
            <div className={!is_safari ? "col-12" : 'col-6'}>
                <GoogleLogin
                    clientId="928815199348-b7rrmdpv6vu3o93du378m7gdpg6ors85.apps.googleusercontent.com"
                    render={renderProps => (
                        <button
                            onClick={renderProps.onClick} disabled={renderProps.disabled}
                            className="btn btn-primary form-control mt-3" style={{ position: 'relative' }}>
                            <i className="fa fa-google" style={{ position: 'absolute', left: 20, top: 7, fontSize: 20 }}></i>
                            <span>{t('continue_with_google')}</span>
                        </button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogleFail}
                    cookiePolicy={'single_host_origin'}
                />
                {/* <button className="btn btn-primary form-control mt-3" style={{ position: 'relative' }}>
                    <i className="fa fa-google" style={{ position: 'absolute', left: 20, top: 10, fontSize: 20 }}></i> 
                    <span>Continue with Google</span>
                </button> */}
            </div>
            {
                is_safari ?
                    <div className={"col-6"}>
                        <AppleLogin
                            render={renderProps => (
                                <button
                                    onClick={renderProps.onClick} disabled={renderProps.disabled}
                                    className="btn btn-dark form-control mt-3" style={{ position: 'relative' }}>
                                    <i className="fa fa-apple" style={{ position: 'absolute', left: 20, top: 10, fontSize: 20 }}></i>
                                    <span>{t('continue_with_apple')}</span>
                                </button>
                            )}
                            scope="email name"
                            responseType="id_token code"
                            responseMode="form_post"
                            clientId="qa.thetailor.service.id"
                            redirectURI="https://doshopping.pk/projects/thetailor/social-login/apple" />
                        {/* <button className="btn btn-dark form-control mt-3" style={{ position: 'relative' }}>
                            <i className="fa fa-apple" style={{ position: 'absolute', left: 20, top: 10, fontSize: 20 }}></i> 
                            <span>Continue with Apple</span>
                        </button> */}
                    </div>
                : ''
            }
        </div>
    )
}