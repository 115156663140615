import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { environment } from "../config/environment";
import { CartContext } from "../contexts/CartContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { useTranslation } from "../hooks/Translation";

export const Navbar = (props) => {
    const t = useTranslation();
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const lang = document.documentElement.lang;
    const language = lang !== 'en' ? 'English' : 'العربية';
    const lCart = (JSON.parse(localStorage.getItem('cart')) || []);
    const lWishlist = (JSON.parse(localStorage.getItem('wishlist')) || []);
    return (
        <nav className="app-nav navbar navbar-expand-lg navbar-light p-0 d-none d-xl-block">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src="/assets/images/logo.png" className="logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                    <form className="d-flex">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">{t('home')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to={`/shops`}>{t('shop')}</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/products">Products</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to={`/tailor-shops`}>{t('tailor_shops')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to={`/my-account`}>{t('my_account')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to={`/book-a-tailor`}>{t('book_a_tailor')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to={`/contact-us`}>{t('contact')}</Link>
                            </li>
                            {
                                localStorage.getItem('lang') === 'en' ?
                                    <li className="nav-item">
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            document.documentElement.lang = 'ar';
                                            document.documentElement.dir = 'rtl'
                                            localStorage.setItem('lang', 'ar');
                                            localStorage.setItem('dir', 'rtl');
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 0)
                                        }} className="nav-link" href="/">{language}</a>
                                    </li>
                                    : <li className="nav-item">
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            document.documentElement.lang = 'en';
                                            document.documentElement.dir = 'ltr'
                                            localStorage.setItem('lang', 'en');
                                            localStorage.setItem('dir', 'ltr');
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 0)
                                        }} className="nav-link" href="/">{language}</a>
                                    </li>
                            }
                            <li className="nav-item">
                                <a onClick={(e) => {
                                    e.preventDefault()
                                    setAppSettings({ ...appSettings, search: true })
                                }} className="nav-link withicon" href="/"><i className="fa fa-search"></i></a>
                            </li>
                            <li className="nav-item">
                                <div className="wishlist-content">
                                    <div className="wishlist-contentcount">{lWishlist.length || 0}</div>
                                </div>
                                <Link className="nav-link withicon" to="/wishlist"><i className="fa fa-heart"></i></Link>
                            </li>
                            <li className="nav-item">
                                <div className="wishlist-content">
                                    <div className="wishlist-contentcount">{lCart.length || 0}</div>
                                </div>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    setAppSettings({ ...appSettings, show: !appSettings.show })
                                }} className="nav-link withicon"
                                    href="/">
                                    <i className="fa fa-shopping-bag"></i>
                                </a>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </nav>
    )
}