import React, { useEffect } from 'react';
import AppleLogin from 'react-apple-login';
import { Axios, parseQueryString } from '../helpers';
import { useTranslation } from '../hooks/Translation';

export const SocialAppLogin = (props) => {
    const t = useTranslation();
    useEffect(() => {
        try {
            let l = parseQueryString(window.location.search);
            const f = new FormData();
            f.append('grant_type', 'authorization_code');
            f.append('code', l.code);
            f.append('redirect_uri', 'https://doshopping.pk/projects/thetailor/social-login/apple');
            f.append('client_id', 'qa.thetailor.service.id');
            f.append('client_secret', 'MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgBuEg/ih4Wpp5i3c+rcU07avbFjrpEVTOK+4aOhkGp3ugCgYIKoZIzj0DAQehRANCAAQBK2OMQ6J9o+ubCBA9p4bxo2FSxRbi8V8URRm4AkZKWxD7cf9/hYbbz6PJAA5L1O4R8Ye3kdkkIubAtKYC4VG+');
            Axios({ "Content-Type": "application/x-www-form-urlencoded" }).post(`https://appleid.apple.com/auth/token`, f).then((r) => {
                console.log(r);
            }).catch((rr) => {
                console.log(rr);
            })
        } catch (e) {

        }
    }, [])
    return (
        <div className="shops container mt-3 mb-5">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <div className="text-center">Test Apple Login</div>
        </div>
    )
}