import { useContext, useEffect, useState } from "react";
import { Axios } from '../../helpers';
import swal from 'sweetalert';
import { SocialLogin } from './SocialLogin';
import { Link } from "react-router-dom";
import { useTranslation } from "../../hooks/Translation";


export const Register = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({
        isFormValid: false,
        state: { name: '', email: '', password: '', phone: '' },
        loader: false,
        errors: {
            name: [],
            email: [],
            password: []
        }
    });
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        Axios({}).post('/register', { ...settings.state, c_password: settings.state.password }).then((res) => {
            setSettings({ ...settings, loader: false, state: { name: '', email: '', password: '', phone: '' } });
            if (res.data.success) {
                props.history.push('/my-account/activation');
            }
        }).catch(error => {
            setSettings({ ...settings, loader: false })
        })
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    return (
        <div className="login">
            <h2 className="text-center bolder">{t('register')}</h2>
            <form method="POST" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">{t('name')}</label>
                    <input
                        type="text"
                        value={settings.state.name}
                        onChange={handleInputChange}
                        className="form-control"
                        id="name"
                        required={true}
                        name="name"
                        placeholder={t('name') + "*"} />
                    <RenderErros key="name" errors={settings.errors}></RenderErros>
                </div>
                <div className="mb-3">
                    <label htmlFor="emailaddress" className="form-label">{t('email_address')}</label>
                    <input
                        type="email"
                        required={true}
                        value={settings.state.email}
                        onChange={handleInputChange}
                        className="form-control"
                        id="emailaddress"
                        name="email"
                        placeholder={t('email_address') + "*"} />
                </div>
                <div className="mb-3">
                    <label htmlFor="phone" className="form-label">{t('phone')}</label>
                    <input
                        type="phone"
                        required={true}
                        value={settings.state.phone}
                        onChange={handleInputChange}
                        className="form-control"
                        id="emailaddress"
                        name="phone"
                        placeholder={t('phone') + "*"} />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">{t('password')}</label>
                    <input
                        type="password"
                        required={true}
                        value={settings.state.password}
                        onChange={handleInputChange}
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder={t('password') + "*"} />
                </div>
                <div className="privacy">
                    {t('your_data_use_to_experience')}&nbsp;<Link className="link" to={'/privacy-policy'}>{t('privacy_policy')}</Link>
                </div>
                <button className="btn btn-primary form-control mt-3">
                    {
                        settings.loader ?
                            <i className="fa fa-spin fa-spinner"></i>
                            : <span>{t('register')}</span>
                    }
                </button>
                <SocialLogin {...props} />
            </form>
        </div>
    )
}