import { useContext, useEffect, useState } from "react";
import { AuthSideNav } from "../components/Auth/AuthSideNav";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { UserAuthenticatedArea } from "../components/Auth/UserAuthenticatedArea";
import { AuthContext } from "../contexts/AuthContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { useTranslation } from "../hooks/Translation";

export const MyAccount = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ isAuth: false, screen: 'login' });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { auth, setAuth } = useContext(AuthContext)
    return (
        <div className="myaccount container withoutheader-mobile">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            {
                props.showTitle === false ? ''
                    : <h2 className="app-title">{t('my_account')}</h2>
            }
            {
                !auth.is_auth ?
                    <>
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-6 offset-lg-3">
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button
                                        style={{ width: '50%' }}
                                        className="nav-link rlogin active"
                                        id="nav-home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-home"
                                        type="button"
                                        onClick={() => { setSettings({ ...settings, screen: 'login' }) }}
                                        role="tab" aria-controls="nav-home" aria-selected="true">{t('login')}</button>
                                    <button
                                        style={{ width: '50%' }}
                                        className="nav-link rlogin"
                                        onClick={() => { setSettings({ ...settings, screen: 'register' }) }}
                                        id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">{t('register')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                settings.screen === 'login' ?
                                    <div className="col-12 col-lg-6 col-xl-6 offset-lg-3">
                                        <br />
                                        <Login
                                            onForgot={(e) => {
                                                e.preventDefault();
                                                setSettings({ ...settings, screen: 'forgotpassword' })
                                            }}
                                            {...props} />
                                    </div> : settings.screen === 'register' ?
                                        <div className="col-12 col-lg-6 col-xl-6 offset-lg-3">
                                            <br />
                                            <Register {...props} />
                                        </div> : ''
                            }
                        </div>
                    </>
                    : (
                        <div className="row">
                            <div className="col-12 col-lg-3 col-xl-3">
                                <AuthSideNav {...props} />
                            </div>
                            <div className="col-12 col-lg-9 col-xl-9 mt-3">
                                <p>{t('hello')} <b className="im">{auth?.auth?.name}</b> (not <b className="im">{auth?.auth.name}</b>? {t('logout')})</p>
                                <p>{t('account_paragraph')}</p>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}