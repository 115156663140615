import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "../components/Modal";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios, CartTypes } from '../helpers';
import { environment } from '../config';
import InfiniteScroll from 'react-infinite-scroller'
import Select from 'react-select'
import { CartContext } from "../contexts/CartContext";
import swal from 'sweetalert';
import { useTranslation } from "../hooks/Translation";

export const Materials = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ profile: {}, measurements: [], measurement: {}, showModal: false, material: {}, loader: false,  products: [], categories: [] });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext, onSetRecentViewsContext, onSetWishListContext } = useContext(CartContext)
    const lang = localStorage.getItem('lang') || 'en';
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];

    useEffect(() => {
        setAppSettings({ ...appSettings, loading: true });
        Axios({}).get('/measurements').then((res) => {
            setSettings({
                ...settings,
                measurements: res.data.map((m) => {
                    return {
                        label: m.profile,
                        length: parseFloat(m.length),
                        value: m.id
                    }
                })
            })
            setAppSettings({ 
                ...appSettings, 
                loading: true,                
            });
        }).then((error) => {
            setAppSettings({ ...appSettings, loading: false });
        })
    }, []);
    const handleAddToCart = (cr) => {
        let profile = {};
        if (props.profile) {
            profile = props.profile;
            if (profile.id) {
                profile.value = profile.id;
            }
        } else {
            profile = settings.measurement;
            profile.profile = settings.measurement.label;
        }
        if (!profile || !profile.value) {
            swal(t('profile'), t('please_select_profile'), 'error');
            return;
        }
        let cart = Array.isArray(lCart) ? lCart : [];
        let pKey = `_ma_${profile.id}`;
        const found = cart.findIndex((x) => x.id + pKey === cr.id + pKey);
        let price = 0;
        if (profile.length <= 22) {
            price = cr.less_than_22;
        } else if (profile.length <= 30) {
            price = cr.less_than_30;
        } else if (profile.length <= 35) {
            price = cr.less_than_35;
        } else if (profile.length <= 40) {
            price = cr.less_than_40;
        } else if (profile.length <= 45) {
            price = cr.less_than_45;
        } else if (profile.length <= 50) {
            price = cr.less_than_50;
        } else if (profile.length <= 54) {
            price = cr.less_than_54;
        } else if (profile.length <= 60) {
            price = cr.less_than_60;
        } else if (profile.length <= 65) {
            price = cr.less_than_65;
        } else {
            price = 0;
        }
        const p = { 
            id: cr.id,
            pid: cr.id + '_ma',
            name: cr['material_' + lang ]?.title,
            price: price,
            material_profile_id: profile.value,
            quantity: cr.qty || 1,
            profile: profile.profile,
            color: cr?.material_color?.color_code,
            image: {
                image: cr.image ? cr.image : environment.staticUrl + 'no_image.png'  
            }
        };
        if (found > -1) {
            swal(t("cart") , t("already_in_the_cart") ,  "success" )
            return;
        } else {
            p.type = CartTypes.Material;
            cart.push(p);
        }
        swal( t("cart") , t("cart_updated") ,  "success" )
        onSetCartContext(cart);
        window.$('#editmodal').modal('hide')
    }
    const getPrice = () => {
        const cr = settings.material;
        let price = 0;
        if (settings.measurement.length <= 22) {
            price = cr.less_than_22;
        } else if (settings.measurement.length <= 30) {
            price = cr.less_than_30;
        } else if (settings.measurement.length <= 35) {
            price = cr.less_than_35;
        } else if (settings.measurement.length <= 40) {
            price = cr.less_than_40;
        } else if (settings.measurement.length <= 45) {
            price = cr.less_than_45;
        } else if (settings.measurement.length <= 50) {
            price = cr.less_than_50;
        } else if (settings.measurement.length <= 54) {
            price = cr.less_than_54;
        } else if (settings.measurement.length <= 60) {
            price = cr.less_than_60;
        } else if (settings.measurement.length <= 65) {
            price = cr.less_than_65;
        } else {
            price = 0;
        }
        return price;
    }
    const getWithProfilePrice = (m) => {
        const cr = m;
        let price = 0;
        const profile = props.profile;
        profile.length = parseFloat(profile.length)
        if (profile.length <= 22) {
            price = cr.less_than_22;
        } else if (profile.length <= 30) {
            price = cr.less_than_30;
        } else if (profile.length <= 35) {
            price = cr.less_than_35;
        } else if (profile.length <= 40) {
            price = cr.less_than_40;
        } else if (profile.length <= 45) {
            price = cr.less_than_45;
        } else if (profile.length <= 50) {
            price = cr.less_than_50;
        } else if (profile.length <= 54) {
            price = cr.less_than_54;
        } else if (profile.length <= 60) {
            price = cr.less_than_60;
        } else if (profile.length <= 65) {
            price = cr.less_than_65;
        } else {
            price = 0;
        }
        return price;
    }
    return (
        <div className="shops mt-3 mb-5">
            <Modal 
                onClose={() => {
                    setSettings({
                        ...settings,
                        showModal: false
                    })
                }}
                dialogClassName="modal-lg" {...props} 
                id="editmodal" title={t("add_material")} >
                    <div className="p-0 pt-3 pb-3" style={{ margin: '0 auto' }}>     
                        <div className="row">
                            <div className="col-12 col-lg-5 " style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img 
                                    style={{ height: 200, width: 200 }}
                                    className="img-thumbnail" 
                                    src={`${settings.material?.image}`} 
                                />
                            </div>
                            <div className="col-12 col-lg-7">
                                <div className="d-lg-none mt-5"></div>
                                <div style={{ verticalAlign: 'middle', padding: '1ex' }}>
                                    <div className="mb-3">
                                        <label>{t('measurement')}</label>
                                        <Select
                                            onChange={(e) => {
                                                setSettings({
                                                    ...settings,
                                                    measurement: e
                                                })
                                            }}
                                            options={settings.measurements}
                                            style={{
                                                width: '100%'
                                            }}
                                        />
                                    </div>      
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>{t('name')}</th>
                                                <th>{settings.material?.shop?.title }</th>
                                            </tr>
                                            <tr>
                                                <th>{t('shop')}</th>
                                                <th>{ settings.material['material_' + lang ]?.title }</th>
                                            </tr>
                                            <tr>
                                                <th>Material Color</th>
                                                <th>
                                                    <button 
                                                        className="bold img-thumbnail btn btn-sm" 
                                                        style={{ border: '1px solid #ccc', height: 25, width: 25, backgroundColor: settings.material?.material_color?.color_code }}>
                                                    </button>
                                                </th>
                                            </tr>
                                            {
                                                settings.measurement && settings.measurement.length ?
                                                <tr>
                                                    <th>Price</th>
                                                    <th>
                                                        { getPrice() }
                                                    </th>
                                                </tr> : ''
                                            }
                                        </thead>
                                    </table>
                                    <div className="clear clearfix"></div>
                                </div>
                            </div>
                        </div>                
                        <br />      
                        <div className="mb-3 p-2">
                            <button 
                                onClick={() => { handleAddToCart(settings.material) }}
                                className="btn btn-dark form-control">
                                <i className="fa fa-shopping-cart"></i>
                            </button>
                        </div>
                </div>
            </Modal>
            <h1 className="app-title d-none d-lg-block">{t('materials')}</h1>
            <div className="row p-0 m-0 mt-2">
                {
                        props.materials.map((m, i) => {
                            return (
                                <div 
                                    onChange={() => {
                                        setSettings({
                                            ...settings,
                                            profile: m
                                        })
                                    }} 
                                    key={i} 
                                    className="col-6 col-lg-4 col-xl-4 p-1 mb-3" 
                                    to={`/shops`}>
                                        {/* <div 
                                            className="bold img-thumbnail btn btn-sm" 
                                            style={{ border: '1px solid #ccc', height: 280, width: '100%', backgroundColor: m.material_color.color_code }}>
                                        </div> */}
                                        <img 
                                            style={{ height: 320, width: '100%' }}
                                            className="img-thumbnail" 
                                            src={`${m?.image}`} 
                                        />
                                        <div style={{ verticalAlign: 'middle', padding: '1ex' }}>
                                            <div>
                                                <p className="bold m-0">{ m?.shop?.title }</p>
                                                {
                                                    props.profile ?
                                                        <p className="bold m-0">{t('price')}: { getWithProfilePrice(m) }</p>
                                                    : ''
                                                }
                                                <p className="bold m-0">{ m['material_' + lang ]?.title }</p>
                                                <span className="bolder">{t('color')}</span>
                                                &nbsp; &nbsp;
                                                <button 
                                                    className="bold img-thumbnail btn btn-sm" 
                                                    style={{ border: '1px solid #ccc', borderRadius: '100%', height: 25, width: 25, backgroundColor: m.material_color.color_code }}>
                                                </button>
                                            </div>
                                            <div>
                                                <button 
                                                    onClick={() => {
                                                        if (props.profile) {
                                                            handleAddToCart(m);
                                                        } else {
                                                            setSettings({
                                                                ...settings,
                                                                material: m
                                                            })
                                                            window.$('#editmodal').modal('show')
                                                        }
                                                    }}
                                                    className="btn btn-dark btn-sm mt-1">
                                                    <i className="fa fa-shopping-cart"></i> 
                                                    {/* &nbsp;{t('add_to_cart')} */}
                                                </button>
                                                <p className="clearfix"></p>
                                            </div>
                                        </div>
                                </div>
                            )
                        })
                    }
                </div>
        </div>
    )
}