import { Link } from "react-router-dom"
import { environment } from "../config"

export const ShopsByCategory = (props) => {
    return (
        <div className="p-1 text-center mt-3">
            <h1>{props?.data?.title}</h1>
            {
                props?.data?.categories?.map((r) => {
                    return (
                        <div className="mt-1">
                            <h2 className="mt-2">{r.title}</h2>
                            <div className="row">
                                {
                                    r?.childrens.map((r) => {
                                        return (
                                            <Link
                                                to={'/products/' + r.slug}
                                                className="col-4 col-lg-3 mt-2">
                                                <div className="img-thumbnail p-1">
                                                    <img
                                                        style={{
                                                            // height: 200
                                                        }}
                                                        className="img-fluid"
                                                        src={environment.staticUrl + `categories/` + r.image} />
                                                    <p>{r?.title}</p>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                            <hr style={{
                                border: '1px solid black'
                            }} />
                        </div>
                    )
                })
            }
        </div>
    )
}