import { useContext, useEffect, useState } from "react";
import { CartContext } from "../contexts/CartContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { Link, useHistory } from "react-router-dom";

export const MobileTopNavBar = (props) => {
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const history = useHistory();
    const lang = document.documentElement.lang;
    const language = lang !== 'en' ? 'English' : 'العربية';
    const lWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    return (
        <>
            <div className="tbg d-lg-none"></div>
            <nav className="mobile-top-nav navbar navbar-light p-0 d-block d-xl-none">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src="/assets/images/logo.png" className="logo" />
                    </Link>
                    <div  id="navbarMobileText">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a onClick={(e) => { 
                                    e.preventDefault()
                                    setAppSettings({ ...appSettings, search: true }) }} className="nav-link withicon" href="/"><i className="fa fa-search"></i></a>
                            </li>
                            <li className="nav-item">
                                <div className="wishlist-content" style={{ right: -5 }}>
                                    <div className="wishlist-contentcount">{ lWishlist.length || 0 }</div>
                                </div>
                                <Link className="nav-link withicon" to="/wishlist"><i className="fa fa-heart"></i></Link>
                            </li>
                            <li className="nav-item">
                                <a  
                                id="menubtn"
                                name="menubtn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAppSettings({ ...appSettings, sidenav: !appSettings.sidenav })
                                }} className="nav-link withicon" 
                                    href="/">
                                    <i className="fa fa-ellipsis-v"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <button 
                    onClick={() => {
                        history.goBack();
                    }}
                    className="btn btn-dark" style={{
                        position: 'absolute',
                        left: 30
                    }}><i className="fa fa-caret-left"></i></button> */}
            </nav>
        </>
    )
}