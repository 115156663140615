import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "../components/Modal";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios, printPrice } from '../helpers';
import { mobileNav } from '../App';
import { environment } from '../config';
import InfiniteScroll from 'react-infinite-scroller'
import { LayoutContext } from "../contexts/LayoutContext";
import { useTranslation } from "../hooks/Translation";
import * as _ from 'lodash';

export const Shops = (props) => {
    const t = useTranslation();
    const [filter, setFilter] = useState({ search: '', orderby: 'date-desc', filter: { min: 0, max: 1000000 } });
    const [iScroll, setIScroll] = useState({ last_page: 0, stopHits: false, page: 1, hasMore: false, });
    const [settings, setSettings] = useState({ loader: false, products: [], categories: [] });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { layoutSettings, setLayoutSettings } = useContext(LayoutContext)

    const lang = localStorage.getItem('lang') || 'en';
    const mobileNav1 = [
        {
            title: t('categories'),
            icon: 'lnr lnr-menu',
            url: '',
            callback: (e) => {
                setTimeout(() => {
                    setAppSettings({ ...appSettings, mobileNav: mobileNav, leftsidenav: true, sidenav: false })
                }, 10)
            }
        },
        {
            title: t('sorting'),
            icon: 'lnr lnr-sort-amount-asc',
            url: '',
            props: {
                "data-toggle": "modal",
                "data-target": "#sorting"
            },
            callback: (e) => {
                window.$('#sorting').modal('show')
            }
        },
        {
            title: t('filters'),
            icon: 'lnr lnr-funnel',
            url: '',
            callback: (e) => {
                window.$('#filter').modal('show')
            }
        }
    ];
    useEffect(() => {
        setTimeout(() => {
            setLayoutSettings({ ...layoutSettings, mobileNav: mobileNav1, showHome: true })
        }, 400);
        loadData(1);
        return () => {
            setLayoutSettings({ ...layoutSettings, mobileNav: mobileNav, showHome: false })
        }
    }, []);
    const doSearch = _.debounce((e) => {
        setFilter({ ...filter, search: e.target.value })
        setIScroll({ ...iScroll, page: 1 });
        loadData(1, e.target.value, filter.orderby, filter.filter, true);
    }, 500)
    const loadData = (page, search = '', orderBy = filter.orderby, ffilter = filter.filter, refresh = false) => {
        setIScroll({ hasMore: false })
        if (settings.loader && !iScroll.stopHits && page <= iScroll.last_page) {
            return;
        }
        setAppSettings({
            ...appSettings,
            mobileNav: mobileNav1,
            sidenav: false,
            leftsidenav: false,
            loading: true
        })
        setSettings({ ...settings, loader: true });
        Axios({}).get(`/products?page=${page}&search=${search}&orderby=${orderBy}&min=${ffilter.min}&max=${ffilter.max}`).then((res) => {
            const p = res.data.data.map((r) => {
                let image = {};
                if (r.have_color === 1) {
                    image = r.product_color_images.length > 0 ?
                        { ...r.product_color_images[0], image: `${environment.staticUrl}products/${r.product_color_images[0].image}` } :
                        { image: `${environment.staticUrl}no_image.png` }
                } else {
                    if (r.product_images && r.product_images.length > 0) {
                        image = r.product_images.length > 0 ?
                            { ...r.product_images[0], image: `${environment.staticUrl}products/${r.product_images[0].image}` } :
                            { image: `${environment.staticUrl}no_image.png` }
                    }
                }
                return {
                    ...r,
                    product_color_image: image
                }
            })
            let pd = { products: [...settings.products, ...p] };
            if (refresh) {
                pd = { products: [...p] }
            }
            if (res.data.last_page === iScroll.page) {
                let p = iScroll.page;
                if (refresh) {
                    p = page;
                }
                setIScroll({ ...iScroll, stopHits: true, hasMore: false, page: p, last_page: res.data.last_page });
                setSettings({ ...settings, ...pd, loader: false });
            } else {
                let p = iScroll.page + 1;
                if (refresh) {
                    p = page;
                }
                setIScroll({ ...iScroll, hasMore: true, page: p });
                setSettings({ ...settings, ...pd, hasMore: true, loader: false });
            }
            setLayoutSettings({ ...layoutSettings, mobileNav: mobileNav1, showHome: true })
            setAppSettings({
                ...appSettings,
                mobileNav: mobileNav1,
                loading: false,
                sidenav: false,
                leftsidenav: false,
            })
        }).catch((error) => {
            setSettings({ ...settings, products: [], loader: false });
            setLayoutSettings({ ...layoutSettings, mobileNav: mobileNav1, showHome: true })
            setAppSettings({
                ...appSettings,
                mobileNav: mobileNav1,
                loading: false,
                sidenav: false,
                leftsidenav: false,
            })
        })
    };
    const categories = JSON.parse(localStorage.getItem('categories')) || [];
    const handleFilter = (e, min, max) => {
        e.preventDefault();
        setFilter({ ...filter, filter: { min: min, max: max } });
        loadData(1, filter.search, filter.orderby, { min: min, max: max }, true);
        window.$('#filter').modal('hide')
    }
    const handleSort = (e, s) => {
        e.preventDefault();
        setFilter({ ...filter, orderby: s });
        loadData(1, filter.search, s, filter.filter, true);
        window.$('#sorting').modal('hide')
    }
    return (
        <div className="shops container mt-3 mb-5 pt-5">
            <div className="d-lg-none pb-2"></div>
            <div className="d-lg-none pb-5"></div>
            <h1 className="app-title d-none d-lg-block">{t('shop')}</h1>
            <Modal {...props} id="sorting" title="Sort By">
                <div className="modal-body p-0">
                    <div className="p-1 m-0 mt-1 text-center">
                        <h6 className="bolder">{t('sort_by')}</h6>
                    </div>
                    <div className="list-group text-center">
                        <a
                            onClick={(e) => handleSort(e, 'date')}
                            className="list-group-item p-3"
                            href="#">{t('sort_by_newness')}</a>
                        <a
                            onClick={(e) => handleSort(e, 'date-desc')}
                            className="list-group-item p-3"
                            href="#">{t('sort_by_old')}</a>
                        <a
                            onClick={(e) => handleSort(e, 'price')}
                            className="list-group-item p-3"
                            href="#">{t('order_by_price')}</a>
                        <a
                            onClick={(e) => handleSort(e, 'price-desc')}
                            className="list-group-item p-3"
                            href="#">{t('order_by_price_desc')}</a>
                    </div>
                </div>
            </Modal>
            <Modal {...props} id="filter" title="Filter By">
                <div className="modal-body p-0">
                    <div className="p-1 m-0 mt-1 text-center">
                        <h6 className="bolder">{t('filter_by_price')}</h6>
                    </div>
                    <div className="list-group text-center">
                        <a onClick={(e) => handleFilter(e, 0, 1000000)}
                            className={filter.filter.min === 0 && filter.filter.max === 1000000 ? "list-group-item active p-3" : 'list-group-item p-3'}
                            href="#">All</a>
                        <a
                            onClick={(e) => handleFilter(e, 20, 45)}
                            className={filter.filter.min === 20 && filter.filter.max === 45 ? "list-group-item active p-3" : 'list-group-item p-3'}
                            className="list-group-item p-3"
                            href="#">20 ر.ق - 45 ر.ق</a>
                        <a
                            onClick={(e) => handleFilter(e, 45, 70)}
                            className={filter.filter.min === 45 && filter.filter.max === 70 ? "list-group-item active p-3" : 'list-group-item p-3'}
                            className="list-group-item p-3"
                            href="#">45 ر.ق - 70 ر.ق</a>
                        <a
                            onClick={(e) => handleFilter(e, 70, 95)}
                            className={filter.filter.min === 70 && filter.filter.max === 95 ? "list-group-item active p-3" : 'list-group-item p-3'}
                            className="list-group-item p-3"
                            href="#">70 ر.ق - 95 ر.ق</a>
                        <a
                            onClick={(e) => handleFilter(e, 95, 120)}
                            className={filter.filter.min === 95 && filter.filter.max === 120 ? "list-group-item active p-3" : 'list-group-item p-3'}
                            className="list-group-item p-3"
                            href="#">95 ر.ق - 120 ر.ق</a>
                        <a
                            onClick={(e) => handleFilter(e, 120, 145)}
                            className={filter.filter.min === 120 && filter.filter.max === 145 ? "list-group-item active p-3" : 'list-group-item p-3'}
                            className="list-group-item p-3"
                            href="#">120 ر.ق - 145 ر.ق</a>
                        <a
                            onClick={(e) => handleFilter(e, 145, 170)}
                            className={filter.filter.min === 145 && filter.filter.max === 170 ? "list-group-item active p-3" : 'list-group-item p-3'}
                            className="list-group-item p-3"
                            href="#">145 ر.ق - 170 ر.ق</a>
                        <a
                            onClick={(e) => handleFilter(e, 170, 1000000)}
                            className={filter.filter.min === 170 && filter.filter.max === 1000000 ? "list-group-item active p-3" : 'list-group-item p-3'}
                            className="list-group-item p-3"
                            href="#">170 ر.ق+</a>
                    </div>
                </div>
            </Modal>
            <div className="text-center pb-5 d-none d-lg-block" style={{ fontSize: 16 }}>
                {
                    categories && Array.isArray(categories) && categories.map((cat, i) => {
                        return (
                            <Link key={i} to={`/products/${cat['category_' + lang]?.slug}`} className="bolder p-2 d-inline-block">{cat['category_' + lang]?.title}</Link>
                        )
                    })
                }
            </div>
            <div className="col-12 col-lg-12 col-xl-12">
                <div className="actions d-none d-lg-block p-2">
                    <div className="pull-left" style={{ width: '30%' }}>
                        <button
                            onClick={() => {
                                window.$('#sorting').modal('show')
                            }}
                            className="btn btn-dark"><i className="fa fa-filter"></i> {t('filter')}</button>
                    </div>
                    <div className="pull-right" style={{ width: '70%', textAlign: 'right' }}>
                        <select
                            onChange={(e) => {
                                setFilter({ ...filter, orderby: e.target.value });
                                loadData(1, filter.search, e.target.value, true);
                            }}
                            value={filter.orderby}
                            className="form-control d-inline" style={{ maxWidth: 200 }}>
                            <option value="date">{t('sort_by_newness')}</option>
                            <option value="date-desc">{t('sort_by_old')}</option>
                            <option value="price">{t('order_by_price')}</option>
                            <option value="price-desc">{t('order_by_price_desc')}</option>
                        </select>
                        <span>&nbsp;</span>
                        <input
                            onChange={doSearch}
                            type="search" className="form-control d-inline" placeholder="Search" style={{ maxWidth: 200 }} />
                    </div>
                    <div className="clear clearfix"></div>
                </div>
                <InfiniteScroll
                    initialLoad={false}
                    pageStart={iScroll.page}
                    loadMore={(p) => loadData(p, filter.search)}
                    threshold={10}
                    hasMore={iScroll.hasMore}
                    loader={<div></div>}
                >
                    <div className="row p-0 m-0 mt-2">
                        {
                            settings.products.map((product, i) => {
                                return (
                                    <Link
                                        key={i}
                                        to={`/shops/${product['product_' + lang].slug}`}
                                        className="col-6 col-md-3 text-center p-1 " style={{ position: 'relative', border: '1.5px solid #dee2e6' }}>
                                        <img
                                            // style={{ height: 300 }}
                                            src={`${product.product_color_image.image}`}
                                            className="img-fluid" />
                                        <div style={{
                                            position: 'absolute',
                                            zIndex: 2,
                                            bottom: 0,
                                            background: `rgba(255,255,255, 0.7)`,
                                            height: 60,
                                            width: '100%',
                                            padding: '1ex',
                                            paddingLeft: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}>
                                            <p className="p-0 m-0" style={{ textTransform: 'uppercase', textAlign: 'left' }}><strong>{product['product_' + lang].title}</strong></p>
                                            <p className="p-0 m-0" style={{ textTransform: 'uppercase', textAlign: 'left' }}><strong>{
                                                product.have_size == 1 && product.product_price_sizes && product.product_price_sizes.length > 0 ?
                                                    <p className="p-0 m-0 bolder">{printPrice(t, product.product_price_sizes[0].price)}</p>
                                                    : <p className="bold m-0 bold">{printPrice(t, product?.price)}</p>
                                            }</strong></p>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}