import { useContext, useEffect, useState } from "react";
import { Carousel } from "./Carousel";
import { CategoryShowCase } from "./CategoryShowcase";
import { ShowCase } from "./ShowCase";
import { fluidShowcases, genderCategoryShowCases, genderCategoryShowCaseTabs, showcases, genderCategoryShowCases2, genderCategoryShowCaseTabs2, football } from "../placeholder";
import CountUp from 'react-countup';
import { Axios, printPrice } from "../helpers";
import { SideBarContext } from "../contexts/SidebarContext";
import { environment } from '../config';
import { Link } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";

export const BrandsComponent = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ brands: [], total: 0 });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const lang = localStorage.getItem('lang') || 'en';
    useEffect(() => {
        setTimeout(() => {
            const type = props.category ? `?category=${props.category}` : '';
            setAppSettings({
                ...appSettings,
                sidenav: false,
                leftsidenav: false,
                loading: true
            })
            Axios({}).get(`/shops${type}`).then((res) => {
                setSettings({ ...settings, brands: res.data, total: res.data.length });
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    leftsidenav: false,
                })
            }).catch((error) => {
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    leftsidenav: false,
                })
            })
        }, 0);
    }, []);
    let headerStyle = { backgroundColor: '#aaa' };
    if (props.bg) {
        headerStyle = props.bg;
    }
    return (
        <div className="brands pb-5">
            {
                props.showHeader === false ?
                    ''
                :   <div className="header" style={headerStyle}>
                        <h1 style={{ flex: 0.1 }}><strong>{props.title}</strong></h1>
                        {
                            props.showCounter ?
                                <div style={{ flex: 0.2 }} className="counter d-block">
                                    <h1><strong><CountUp start={0} end={settings.total} /></strong></h1>
                                </div>
                                : ''
                        }
                    </div> 
            }
            <div className="container-fluid p-3 pt-0">
                <div className="row">
                    {
                        settings.brands.map((shop, i) => {
                            return (
                                <div key={i} className="col-12 col-lg-3 mt-2">
                                    <div className="shop">
                                        <div className="wrap">
                                            <div className="image" style={{ backgroundImage: `url(${environment.staticUrl + 'shops/' + shop.image})` }}>
                                                <div className="text">
                                                    <h6><strong>{shop['shop_' + lang]?.title}</strong></h6>
                                                    <h6>{shop['shop_' + lang]?.description}</h6>
                                                    <h6>{printPrice(t, shop?.shop_price_range)}</h6>
                                                    <Link
                                                        to={`/brands/booking/${shop['shop_' + lang]?.slug}`}
                                                        className="btn btn-sm btn-dark">{t('book_now')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}