import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { environment } from "../config";
import { CartContext } from "../contexts/CartContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { CartTypes, printPrice } from "../helpers";
import { useTranslation } from "../hooks/Translation";

export const Cart = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({});
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const lang = localStorage.getItem('lang') || 'en';
    useEffect(() => {
        setAppSettings({ ...appSettings, show: false });
    }, []);
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];
    const printTotalF = (props) => {
        let total = [];
        lCart.forEach((cart, index) => {
            if (cart.price && cart.quantity > 0) {
                let t = parseFloat((parseFloat(cart.price)) * cart.quantity).toFixed(2);
                total.push(t);
            }
        });
        const types = Array.from(new Set(lCart.map((x) => x.type)));
        let priceToAdd = 0;
        if (types.length === 1 && types.find((x) => x === CartTypes.Appointment)) {
            priceToAdd = 0;
        } else {
            priceToAdd = t('ship_price') === 'ship_price' ? 0 : parseFloat(t('ship_price'));
        }
        const price = total.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
        if (parseFloat(price) > 1000) {
            priceToAdd = 0;
        }
        return parseFloat(parseFloat(price) + priceToAdd).toFixed(2);
    }
    const onQuantityChange = (c, index, op) => {
        const tcart = Object.assign([], lCart);
        if (op === '+' && tcart[index].quantity < 100) {
            tcart[index].quantity += 1;
        } else if (op === '-' && tcart[index].quantity > 1) {
            tcart[index].quantity -= 1;
        }
        onSetCartContext(tcart);
    }
    const onRemoveCart = (c, index, op) => {
        let tcart = Object.assign([], lCart);
        delete tcart[index];
        tcart = tcart.filter(x => x);
        onSetCartContext(tcart);
    }
    const printTotal = (props) => {
        let total = [];
        lCart.forEach((cart, index) => {
            if (cart.price && cart.quantity > 0) {
                let t = parseFloat((parseFloat(cart.price)) * cart.quantity).toFixed(2);
                total.push(t);
            }
        });
        const types = Array.from(new Set(lCart.map((x) => x.type)));
        let priceToAdd = 0;
        if (types.length === 1 && types.find((x) => x === CartTypes.Appointment)) {
            priceToAdd = 0;
        } else {
            priceToAdd = t('ship_price') === 'ship_price' ? 0 : parseFloat(t('ship_price'));
        }
        const price = total.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
        if (parseFloat(price) > 1000) {
            priceToAdd = 0;
        }
        return printPrice(t, parseFloat(parseFloat(price) + priceToAdd).toFixed(2));
    }
    return (
        <div className="container cart mb-5">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <h1 className="app-title">{t('cart')}</h1>
            <div className="row">
                <div className="col-12 col-lg-8 mt-3">
                    <div className="table-responsive">
                        <table className="table table-bordered p-0 m-0">
                            <thead>
                                <tr>
                                    <th className="text-center" style={{ minWidth: 200 }} scope="col">{t('image')}</th>
                                    <th style={{ minWidth: 100 }} scope="col">{t('product')}</th>
                                    <th style={{ minWidth: 100 }} scope="col">{t('price')}</th>
                                    <th style={{ minWidth: 150 }} scope="col">{t('quantity')}</th>
                                    <th style={{ minWidth: 100 }} scope="col">{t('subtotal')}</th>
                                    <th style={{ minWidth: 50 }} scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lCart.map((cart, index) => {
                                        return (
                                            <tr>
                                                <td className="text-center" style={{ position: 'relative', verticalAlign: 'middle' }}><img src={cart.image.image} style={{ width: 80 }} className="img-fluid" /></td>
                                                <td style={{ position: 'relative', verticalAlign: 'middle' }}>
                                                    {
                                                        CartTypes.StaticTShirt === cart.type ?
                                                            <>
                                                                <div className="mb-3">
                                                                    <label>Name</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={cart.name}
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label>Number</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={cart.number}
                                                                    />
                                                                </div>
                                                                {
                                                                    cart.files.map((file) => {
                                                                        return (
                                                                            <div className="mb-3 text-center ">
                                                                                <img className="img-thumbnail" width={100} src={environment.staticUrl + 'customize_shirts/' + file.image} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            : cart.name
                                                    }
                                                    {
                                                        cart.size ?
                                                            <p className="m-0 cap">Size: {cart.size}</p>
                                                            : ''
                                                    }
                                                </td>
                                                <td style={{ position: 'relative', verticalAlign: 'middle' }}>{printPrice(t, parseFloat(cart.price).toFixed(2))}</td>
                                                <td
                                                    style={{ position: 'relative', verticalAlign: 'middle' }}>
                                                    <div
                                                        hidden={
                                                            cart.type === CartTypes.Appointment
                                                        }
                                                        style={{
                                                            position: 'relative',
                                                            verticalAlign: 'middle',
                                                            top: 1
                                                        }}
                                                        className="input-group input-group-sm flex-nowrap">
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => onQuantityChange(cart, index, '-')}
                                                            className="input-group-text" id="addon-wrapping">-</span>
                                                        <input
                                                            readOnly
                                                            type="text"
                                                            value={cart.quantity}
                                                            className="form-control text-center bolder"
                                                            placeholder="Username"
                                                            aria-label="Username" aria-describedby="addon-wrapping" />
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => onQuantityChange(cart, index, '+')}
                                                            className="input-group-text" id="addon-wrapping">+</span>
                                                    </div>
                                                    <div
                                                        className="text-center"
                                                        hidden={
                                                            cart.type !== CartTypes.Appointment
                                                        }
                                                    >
                                                        {cart.quantity}
                                                    </div>
                                                    {/* <div className="kt-quantity-change d-block">
                                                        <span 
                                                            className="kt-minus fa fa-minus" 
                                                            onClick={() => onQuantityChange(cart, index, '-')}></span>
                                                        <span 
                                                            className="kt-num">{ cart.quantity }</span>
                                                        <span 
                                                            style={{
                                                            }}
                                                            onClick={() => onQuantityChange(cart, index, '+')}
                                                            className="kt-plus fa fa-plus"></span>
                                                    </div> */}
                                                </td>
                                                <td style={{ position: 'relative', verticalAlign: 'middle' }}>{printPrice(t, (parseFloat((parseFloat(cart.price)) * cart.quantity).toFixed(2)))}</td>
                                                <td style={{ position: 'relative', verticalAlign: 'middle' }}><i onClick={() => onRemoveCart(cart, index, '')} className="fa fa-times"></i></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mt-3">
                    <h4 style={{
                        padding: '8px',
                        color: '#555',
                        background: '#ccc',
                        margin: 0
                    }}><strong>{t('cart_totals')}</strong></h4>
                    <div className="shipping-info" style={{
                        padding: '2ex',
                        backgroundColor: '#ddd'
                    }}>
                        <h6>{t('shipping')}</h6>
                        {/* {
                            printTotalF() > 1000 ?
                                <>
                                    <h6>{t('free_delivery')}</h6>
                                    <h6>{t('shipping_to')} <strong>{t('to_doha')}</strong></h6>
                                </>
                                :
                                <>
                                    <h6>{t('charges_for_delivery')}</h6>
                                    <h6>{t('shipping_to')} <strong>{t('to_doha')}</strong></h6>
                                </>
                        } */}
                        <h6>{t('free_delivery')}</h6>
                        <h6>{t('shipping_to')} <strong>{t('to_doha')}</strong></h6>
                        {/* <p style={{
                            padding: '8px',
                            margin: 0,
                            color: '#555',
                            background: '#eee'
                        }}><i className="fa fa-truck"></i> {t('you_got_free_shipping')}</p> */}
                        <button className="form-control btn btn-dark mt-2">{t('total')} {printTotal()}</button>
                    </div>
                    <Link to={"/checkout"} className="btn btn-secondary form-control mt-2">
                        <span>{t('proceed_to_checkout')}</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}