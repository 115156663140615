import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { Modal } from "../components/Modal";
import { environment } from "../config";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios } from "../helpers";
import { useTranslation } from "../hooks/Translation";

export const ProductChildCategory = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ category_en: {}, category_ar: {}, category_products: [] });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const params = useParams();
    const lang = 'en';
    const mobileNav = [
        {
            title: t('categories'),
            icon: 'lnr lnr-menu',
            url: '',
            callback: (e) => {
                setTimeout(() => {
                    setAppSettings({ ...appSettings, mobileNav: mobileNav, leftsidenav: true, sidenav: false })
                }, 10)
            }
        },
        {
            title: t('sorting'),
            icon: 'lnr lnr-sort-amount-asc',
            url: '',
            props: {
                "data-toggle": "modal",
                "data-target": "#sorting"
            },
            callback: (e) => {
                window.$('#sorting').modal('show')
            }
        },
        {
            title: t('filters'),
            icon: 'lnr lnr-funnel',
            url: '',
            callback: (e) => {
                console.log(3);
            }
        }
    ];
    useEffect(() => {
        setTimeout(() => {
            setAppSettings({
                ...appSettings,
                sidenav: false,
                mobileNav: mobileNav,
                leftsidenav: false,
                loading: true
            })
            Axios({}).get(`/category-child/${props.slug ? props.slug: params.slug}`).then((res) => {
                res.data.category_products = res?.data?.category_products?.map((r) => {
                    if (r.have_color) {
                        r.images = r.product_color_images;
                    } else {
                        r.images = r.product_images;
                    }
                    if (r?.images?.length > 0) {
                        r.image = { image: `${environment.staticUrl}products/${r.images[0].image}` };
                    } else {
                        r.image = { image: `${environment.staticUrl}no_image.png` };
                    }
                    return { ...r.image, ...r['product_' + lang] };
                })
                setSettings({ ...settings, ...res.data });
                setAppSettings({
                    ...appSettings,
                    loading: false,
                    sidenav: false,
                    mobileNav: mobileNav,
                    leftsidenav: false,
                })
            })
        }, 0);
    }, []);
    return (
        <div className="brands  mb-5">
            <div className="header" style={{ backgroundImage: `url(${environment.staticUrl + 'categories/' + settings.banner_image})` }}>
                <h1><strong>{settings['category_' + lang].title}</strong></h1>
            </div>
            <div className="shops container mt-3">
                <Modal {...props} id="sorting" title="Sort By">
                    <div className="modal-body p-0">
                        <div className="list-group text-center">
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=menu_order">{t('filter_1')}</a>
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=popularity">{t('filter_2')}</a>
                            <a className="list-group-item active p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=rating">{t('filter_3')}</a>
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=rating">{t('filter_4')}</a>
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=price">{t('filter_5')}</a>
                            <a className="list-group-item p-3" href="https://doshopping.pk/projects/thetailor/en/shop-18/?orderby=price-desc">{t('filter_6')}</a>
                        </div>
                    </div>
                </Modal>
                {
                    props.showFilters !== false ?
                        <div className="actions d-none d-lg-block p-2">
                            <div className="pull-left" style={{ width: '30%' }}>
                                <button className="btn btn-dark"><i className="fa fa-filter"></i> Filter</button>
                            </div>
                            <div className="pull-right" style={{ width: '70%', textAlign: 'right' }}>
                                <select className="form-control d-inline" style={{ maxWidth: 200 }}>
                                    <option>{t('filter_4')}</option>
                                    <option>{t('filter_7')}</option>
                                    <option>{t('filter_7')}</option>
                                    <option>{t('filter_7')}</option>
                                    <option>{t('filter_7')}</option>
                                    <option>{t('filter_7')}</option>
                                </select>
                                <span>&nbsp;</span>
                                <input type="search" className="form-control d-inline" placeholder="Search" style={{ maxWidth: 200 }} />
                            </div>
                            <div className="clear clearfix"></div>
                        </div>
                        : ''
                }
                <div className="row p-0 m-0 mt-2">
                    {
                        settings?.children?.map((product, i) => {
                            return (
                                <Link
                                    key={i}
                                    className="col-xs-6 col-sm-6 col-lg-6 col-xl-6 p-1"
                                    to={`/products/${product['category_' + lang].slug}`}>
                                    {
                                        product.image ?
                                            <img style={{ height: 360, width: '100%' }} className="img-thumbnail" src={`${environment.staticUrl + 'categories/' + product.image}`} />
                                            :
                                            <img style={{ height: 360, width: '100%' }} className="img-thumbnail" src={`${environment.staticUrl + 'no_image.png'}`} />
                                    }
                                    <h6 className="bolder p-0 m-0 mt-2">{product['category_' + lang].title}</h6>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}