import { AbayaBrands } from "./pages/slug/AbayaBrands";
import { AbayaDesign } from "./pages/slug/AbayaDesign";
import { SchoolUniform } from "./pages/slug/SchoolUniform";
import { Mens } from "./pages/slug/Mens";
import { Womens } from "./pages/slug/Womens";
import { SportsWear } from "./pages/slug/SportsWear";

export const slugAllowdPages = {
    'abaya-design': {
        component: AbayaDesign,
        name: 'Abaya Designs'
    },
    'sports-wear': {
        component: SportsWear,
        name: 'Sports Wear'
    },
    'abaya-brands': {
        component: AbayaBrands,
        name: 'Abaya Brands'
    },
    'school-uniform': {
        component: SchoolUniform,
        name: 'School Uniform'
    },
    'mens-home': {
        component: Mens,
        name: 'Mens Home'
    },
    'womens-home': {
        component: Womens,
        name: 'Womens Home'
    },
};
