import { ProductCategory } from '../ProductCategory';

export const Mens = (props) => {
    return (
        <>
            <div className="mb-3">
                <ProductCategory 
                    {...props} 
                    showFilters={false}
                    slug="mens-fashion" />
            </div>
            {/* <div className="container">
                <div className="mb-3">
                    <ShowCase
                        className="col-12 col-md-12 col-lg-6 col-xl-6 animate__animated animate__bounce"
                        showcases={womenshome} />
                </div>
            </div> */}
        </>
    )
}