import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CartContext } from "../contexts/CartContext"
import { SideBarContext } from "../contexts/SidebarContext"
import { useTranslation } from "../hooks/Translation"

export const MobileSideNavbar = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ cart: [], reRender: false })
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const lang = document.documentElement.lang;
    const language = lang !== 'en' ? 'English' : 'العربية';
    return (
        <div className={appSettings.sidenav ? "navsidebar sidebar active" : "navsidebar sidebar"}>
            {
                appSettings.sidenav ?
                    <div className="blur-area" onClick={() => { setAppSettings({ ...appSettings, sidenav: false }) }}></div>
                    : ''
            }
            <div className="topbar">
                <div className="title pull-left" style={{ pading: '18px' }}></div>
                <div className="title pull-right">
                    <i
                        onClick={() => setAppSettings({ ...appSettings, sidenav: false })}
                        className="fa fa-times"></i>
                </div>
                <div className="clear clearfix"></div>
            </div>
            <div className="cart">
                <ul className="list-group text-center">
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/">{t('home')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to={`/shops`}>{t('shop')}</Link>
                    </li>
                    {/* <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/products">Products</Link>
                </li> */}
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to={`/tailor-shops`}>{t('tailor_shops')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to={`/my-account`}>{t('my_account')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to={`/book-a-tailor`}>{t('book_a_tailor')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to={`/contact-us`}>{t('contact')}</Link>
                    </li>
                    {
                        document.documentElement.lang === 'en' ?
                            <li className="nav-item">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.setItem('lang', 'ar');
                                    localStorage.setItem('dir', 'rtl');
                                    document.documentElement.lang = 'ar';
                                    document.documentElement.dir = 'rtl'
                                    setAppSettings({ lang: 'ar', dir: 'rtl' })
                                    window.location.reload();
                                }} className="nav-link" href="/">{language}</a>
                            </li>
                            : <li className="nav-item">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.setItem('lang', 'en');
                                    localStorage.setItem('dir', 'ltr');
                                    document.documentElement.lang = 'en';
                                    document.documentElement.dir = 'ltr'
                                    setAppSettings({ lang: 'en', dir: 'ltr' })
                                    window.location.reload();
                                }} className="nav-link" href="/">{language}</a>
                            </li>
                    }
                </ul>
                <div style={{ 
                    position: "absolute",
                    bottom: "80px",
                    right: "12px",}} >
                    <p>FollowMe For Digital Marketing </p>
                </div>
            </div>
        </div>
    )
}