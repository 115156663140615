import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SideBySideMagnifier } from 'react-image-magnifiers';
import { CartContext } from "../contexts/CartContext";
import { SideBarContext } from "../contexts/SidebarContext";
import { Axios, CartTypes } from "../helpers";
import { environment } from "../config";
import { RelatedProducts } from "../components/RelatedProducts";
import swal from "sweetalert";
import axios from "axios";
import { RecentlyViewdItems, RecentlyViewdItemsMobile } from "../components/RecentlyViewdItems";
import { useTranslation } from "../hooks/Translation";

export const StaticProduct = (props) => {
    const t = useTranslation();
    let fRef = useRef(null);

    const [settings, setSettings] = useState({
        state: {
            files: [],
            quantity: 1,
            size: '',
            color: '',
            name: '',
            number: '',
            measurements: {

            }
        },
        files: [],
        params: '',
        render: false,
        related_products: [],
        product: { product_price_sizes: [], product_category: {}, images: [] },
        displayImage: {}
    });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext, onSetRecentViewsContext, onSetWishListContext } = useContext(CartContext)
    const params = useParams();
    const product = { title: 'Romantice Beige', image: '/assets/images/shop.jpg' };
    const lang = localStorage.getItem('lang') || 'en';
    const categories = JSON.parse(localStorage.getItem('categories')) || [];
    const pKey = 'product_'
    const state = {
        alwaysInPlace: false,
        overlayOpacity: 0.6,
        switchSides: false,
        fillAvailableSpace: true,
        fillAlignTop: false,
        fillGapLeft: 0,
        fillGapRight: 0,
        fillGapTop: 0,
        fillGapBottom: 0
    };
    const {
        alwaysInPlace,
        overlayOpacity,
        switchSides,
        fillAvailableSpace,
        fillAlignTop,
        fillGapLeft,
        fillGapRight,
        fillGapTop,
        fillGapBottom
    } = state;
    useEffect(() => {
        if (params.slug && settings.params && params.slug !== settings.params) {
            setSettings({
                ...settings,
                params: params.slug,
            });
            loadData();
        }
    })
    useEffect(() => {
        setSettings({
            ...settings,
            params: params.slug,
        });
        loadData();
    }, []);
    function getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
    const loadData = () => {
        setTimeout(() => {
            setAppSettings({
                ...appSettings,
                sidenav: false,
                leftsidenav: false,
                loading: true
            })
            setSettings({
                ...settings,
                params: params.slug,
                loader: true,
            });
            let displayImage = '/assets/images/footbalkit.gif'
            Axios({}).get(`/categories/t-shirts`).then((ress) => {
                const related = ress.data.category_products.map((r) => {
                    let rdisplayImage = {};
                    if (r.have_color) {
                        r.images = r.product_color_images;
                    } else {
                        r.images = r.product_images;
                    }
                    if (r?.images?.length > 0) {
                        r.image = `${environment.staticUrl}products/${r.images[0].image}`;
                    } else {
                        r.image = `${environment.staticUrl}no_image.png`;
                    }
                    r.url = `/shops/` + r['product_' + lang].slug;
                    r.slug = `/shops/` + r['product_' + lang].slug;
                    return r;
                })
                setSettings({
                    ...settings,
                    loader: false,
                    render: true,
                    params: params.slug,
                    product: [],
                    related_products: related || [],
                    displayImage,
                });
                setAppSettings({
                    ...appSettings,
                    sidenav: false,
                    leftsidenav: false,
                    loading: false
                })
            });
        }, 0);
    }
    const addToRecent = (id, item) => {
        try {
            let recentViews = JSON.parse(localStorage.getItem('recent-views')) || [];
            const found = recentViews.findIndex((x) => x.id === id);
            if (recentViews.length > 10) {
                // recentViews.shift();
            }
            if (found === -1) {
                recentViews.push({
                    id: item.id,
                    url: `/shops/` + item['product_' + lang].slug,
                    slug: `/shops/` + item['product_' + lang].slug,
                    name: item['product_' + lang].title,
                    title: item['product_' + lang].title,
                    image: item.image.image,
                });
            }
            onSetRecentViewsContext(recentViews);
        } catch (error) {
            console.log(error);
        }
    }
    const deleteImage = (index) => {
        if (!props.isEdit) {
            return;
        }
        const id = settings?.state?.images[index]?.id;
        const custom_request_id = settings?.state?.images[index]?.custom_request_id;
        if (!id || !custom_request_id) {
            return;
        }
        setAppSettings({ ...appSettings, loading: true });
        Axios({}).post(`delete-custom-request-image/${custom_request_id}`, { image_id: id }).then(() => {
            setAppSettings({ ...appSettings, loading: false });
            props.onLoadData();
        }).catch((error) => {
            setAppSettings({ ...appSettings, loading: false });
        })
    }
    const lCart = JSON.parse(localStorage.getItem('cart')) || [];
    return (
        <div className="shops container mt-3">
            {/* <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div> */}
            <div className="row">
                <div className="d-none d-lg-block col-md-3 col-sm-12">
                    <h5><strong>{t('product_categories')}</strong></h5>
                    <ul className="list-group">
                        {
                            categories && Array.isArray(categories) && categories.map((cat, index) => {
                                return (
                                    <Link
                                        className="nav-link list-group-item"
                                        aria-current="page"
                                        to={`/products/${cat['category_' + lang]?.slug}`}>
                                        { cat['category_' + lang]?.title}
                                    </Link>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="col-12 col-lg-9">
                    {
                        !settings.loader ?
                            <>
                                <h5><strong>{settings.product[pKey + lang]?.title}</strong></h5>
                                <div className="row p-0 m-0 mt-2">
                                    <div className="col-md-7 col-sm-12 p-0" style={{ position: 'relative' }}>
                                        {
                                            settings.render ?
                                                <div className="ml-2">
                                                    <button
                                                        className={`btn btn-danger mt-2 ${props.match.params.slug === "custom-sports-jersey" ? "d-none" : ""}`}
                                                        style={{
                                                            position: 'absolute',
                                                            right: 12,
                                                            zIndex: 1
                                                        }}
                                                        onClick={() => {
                                                            let wishlist = Array.isArray(cartContext.wishlist) ? cartContext.wishlist : [];
                                                            wishlist.push({ ...settings.product, ...settings.state, image: settings.displayImage });
                                                            onSetWishListContext(wishlist);
                                                        }} ><i className="fa fa-heart"></i></button>
                                                    <SideBySideMagnifier
                                                        className=""
                                                        style={{ order: switchSides ? "1" : "0" }}
                                                        imageSrc={settings.displayImage}
                                                        largeImageSrc={settings.displayImage}
                                                        alwaysInPlace={alwaysInPlace}
                                                        overlayOpacity={overlayOpacity}
                                                        switchSides={switchSides}
                                                        zoomPosition="left"
                                                        inPlaceMinBreakpoint={641}
                                                        fillAvailableSpace={fillAvailableSpace}
                                                        fillAlignTop={fillAlignTop}
                                                        fillGapTop={fillGapTop}
                                                        fillGapRight={fillGapRight}
                                                        fillGapBottom={fillGapBottom}
                                                        fillGapLeft={fillGapLeft}
                                                        zoomContainerBorder="1px solid #ccc"
                                                        zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                                                    />
                                                </div>
                                                : ''
                                        }
                                        <div className="thumbnails text-center mt-3">
                                            {
                                                settings?.product?.have_color === 1 ?
                                                    <div>
                                                        {
                                                            settings?.product?.product_color_images?.map((image, i) => {
                                                                return (
                                                                    <div
                                                                        onClick={(e) => {
                                                                            setSettings({
                                                                                ...settings,
                                                                                displayImage: {
                                                                                    image: `${environment.staticUrl}products/${image.image}`
                                                                                },
                                                                                state: {
                                                                                    ...settings.state,
                                                                                    color: image.color,
                                                                                },
                                                                                render: true
                                                                            });
                                                                        }}
                                                                        className="d-inline-block m-1"
                                                                        style={{
                                                                            width: 50,
                                                                            height: 50,
                                                                            cursor: 'pointer',
                                                                            borderRadius: '100%',
                                                                            backgroundColor: `#${image.color.color_code}`
                                                                        }}>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    settings?.product?.images?.map((image, i) => {
                                                        return (
                                                            <img
                                                                onClick={(e) => {
                                                                    setSettings({
                                                                        ...settings,
                                                                        displayImage: {
                                                                            image: `${environment.staticUrl}products/${image.image}`
                                                                        },
                                                                        render: true
                                                                    });
                                                                }}
                                                                key={i}
                                                                alt=""
                                                                src={`${environment.staticUrl}products/${image.image}`}
                                                                className="img-fluid img-thumbnail"
                                                                style={{ width: 100, height: 100, cursor: 'pointer' }}
                                                            />
                                                        )
                                                    })
                                            }
                                        </div>
                                        {/* {
                                        settings?.product.product_price_sizes.map((x) => {
                                            return (
                                                <p>{ x.size['size_' + lang]?.title }</p>
                                            )
                                        })
                                    } */}
                                    </div>
                                    <div className="col-md-5 col-sm-12">
                                        <div className="mt-3 d-lg-none"></div>
                                        <h3 className="bolder">{t('jersey_cotton')}</h3>
                                        <div className="mb-3">
                                            <label>{t('sizes')}</label>
                                            <select
                                                onChange={(e) => {
                                                    const price = 150;
                                                    setSettings({
                                                        ...settings,
                                                        state: {
                                                            ...settings.state,
                                                            size: e.target.value,
                                                            price: (parseFloat(price.price) * settings.state.quantity).toFixed(2)
                                                        }
                                                    })
                                                }}
                                                value={settings.state.size}
                                                className="form-control"
                                                aria-label="Default select example">
                                                <option selected>{t('select_size')}</option>
                                                <option value="S">S</option>
                                                <option value="M">M</option>
                                                <option value="L">L</option>
                                                <option value="XL">XL</option>
                                                <option value="XXL">XXL</option>
                                                <option value="XXXL">XXXL</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label>{t('name')}</label>
                                            <input
                                                onChange={(e) => {
                                                    setSettings({
                                                        ...settings,
                                                        state: {
                                                            ...settings.state,
                                                            name: e.target.value,
                                                        }
                                                    })
                                                }}
                                                value={settings.state.name}
                                                className="form-control"
                                                placeholder={t('name')}
                                                type="text" />
                                        </div>
                                        <div className="mb-3">
                                            <label>{t('number')}</label>
                                            <input
                                                onChange={(e) => {
                                                    setSettings({
                                                        ...settings,
                                                        state: {
                                                            ...settings.state,
                                                            number: e.target.value,
                                                        }
                                                    })
                                                }}
                                                value={settings.state.number}
                                                className="form-control"
                                                placeholder={t('number')}
                                                type="text" />
                                        </div>
                                        <div className="mb-3">
                                            {
                                                settings.state?.files?.length > 0 ?
                                                    <div className="text-center">
                                                        {
                                                            settings.state.files && settings.state.files.map((f, i) => {
                                                                return (
                                                                    <div className="col-12 col-lg-12">
                                                                        <div className="wrapper p-1 mt-3 text-center img-thumbnail" style={{ position: 'relative' }}>
                                                                            <span
                                                                                onClick={() => {
                                                                                    if (props.isEdit) {
                                                                                        swal({
                                                                                            title: t('are_you_sure'),
                                                                                            text: t('once_deleted_not_recover'),
                                                                                            icon: "warning",
                                                                                            buttons: true,
                                                                                            dangerMode: true,
                                                                                        })
                                                                                            .then((willDelete) => {
                                                                                                if (willDelete) {
                                                                                                    const foundB = settings.files;
                                                                                                    const found64 = settings.state.files;
                                                                                                    delete foundB[i];
                                                                                                    delete found64[i];
                                                                                                    setSettings({
                                                                                                        ...settings,
                                                                                                        files: foundB.filter((x) => x !== undefined),
                                                                                                        state: {
                                                                                                            ...settings.state,
                                                                                                            files: found64.filter((x) => x !== undefined),
                                                                                                        }
                                                                                                    })
                                                                                                    deleteImage(i);
                                                                                                }
                                                                                            });
                                                                                    } else {
                                                                                        const foundB = settings.files;
                                                                                        const found64 = settings.state.files;
                                                                                        delete foundB[i];
                                                                                        delete found64[i];
                                                                                        setSettings({
                                                                                            ...settings,
                                                                                            files: foundB.filter((x) => x !== undefined),
                                                                                            state: {
                                                                                                ...settings.state,
                                                                                                files: found64.filter((x) => x !== undefined),
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    right: 0,
                                                                                    top: -5,
                                                                                    color: 'white',
                                                                                    cursor: 'pointer',
                                                                                    background: 'red',
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    borderRadius: '100%',
                                                                                    textAlign: 'center'
                                                                                }}
                                                                            >
                                                                                <i style={{ position: 'relative', left: 1, top: -2 }} className="fa fa-times"></i>
                                                                            </span>
                                                                            <img className="img-fluid" style={{ height: 150, }} src={environment.staticUrl + 'customize_shirts/' + f.image} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div className="col-12 col-lg-12">
                                                            <div
                                                                className="wrapper mt-3 img-thumbnail text-center"
                                                                onClick={() => {
                                                                    if (fRef.current) {
                                                                        fRef.current.click();
                                                                    }
                                                                }}
                                                                style={{ cursor: 'pointer', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '32px', color: '#aaa' }}
                                                            >
                                                                <i className="fa fa-upload"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <div
                                                            onClick={() => {
                                                                if (fRef.current) {
                                                                    fRef.current.click();
                                                                }
                                                            }}
                                                            className="img-thumbnail" style={{
                                                                display: 'flex',
                                                                cursor: 'pointer',
                                                                width: '100%',
                                                                height: 100,
                                                                fontSize: 32,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#aaa'
                                                            }}>
                                                            <i className="fa fa-upload"></i>
                                                        </div>
                                                    </div>
                                            }
                                            <input
                                                onChange={(e) => {
                                                    const bfiles = [];
                                                    const vfiles = [];
                                                    for (let i = 0; i < e.target.files.length; i++) {
                                                        const file = e.target.files[i];
                                                        bfiles.push(URL.createObjectURL(file));
                                                        const formData = new FormData();
                                                        formData.append('file', file)
                                                        vfiles.push(Axios({ "Content-Type": "multipart/form-data" }).post('/add-customize-image', formData));
                                                    }
                                                    setAppSettings({
                                                        ...appSettings,
                                                        loading: true
                                                    })
                                                    axios.all(vfiles).then(axios.spread((image1) => {
                                                        setSettings({
                                                            ...settings,
                                                            files: Array.from(e.target.files).slice(0, 5),
                                                            state: {
                                                                ...settings.state,
                                                                files: [...settings.state.files, ...[image1.data.response_data]].slice(0, 5)
                                                            }
                                                        })
                                                        setAppSettings({
                                                            ...appSettings,
                                                            loading: false
                                                        })
                                                    }));
                                                }}
                                                style={{ display: 'none' }}
                                                ref={fRef} accept="image/x-png,image/gif,image/jpeg" type="file" />
                                        </div>

                                        <div className="measurement" hidden={settings.product.have_measurement !== 1}>
                                            {
                                                settings.product?.product_measurements?.map((measurement) => {
                                                    return (
                                                        <div className="mb-3">
                                                            <label>{t(measurement.measurement['measurement_' + lang].slug.replace(/-/g, '_'))}</label>
                                                            <input
                                                                onChange={(e) => {
                                                                    setSettings({
                                                                        ...settings,
                                                                        state: {
                                                                            ...settings.state,
                                                                            measurements: {
                                                                                ...settings.state.measurements,
                                                                                [measurement.measurement['measurement_' + lang].slug.replace(/[- ]/g, '_')]: e.target.value
                                                                            }
                                                                        }
                                                                    })
                                                                }}
                                                                value={settings.state.measurements[measurement.measurement['measurement_' + lang].slug.replace(/-/g, '_')] || ''}
                                                                type="number"
                                                                className="form-control" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label>{t('quantity')}</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" value={settings.state.quantity} />
                                                <button
                                                    onClick={() => {
                                                        if (settings.state.quantity > 1) {
                                                            setSettings({
                                                                ...settings,
                                                                state: { ...settings.state, quantity: settings.state.quantity - 1 }
                                                            })
                                                        }
                                                    }}
                                                    className="btn btn-outline-secondary" type="button">-</button>
                                                <button
                                                    onClick={() => {
                                                        if (settings.state.quantity <= 100) {
                                                            setSettings({
                                                                ...settings,
                                                                state: { ...settings.state, quantity: settings.state.quantity + 1 }
                                                            })
                                                        }
                                                    }}
                                                    className="btn btn-outline-secondary"
                                                    type="button">+</button>
                                            </div>
                                        </div>
                                        <div className="prices">
                                            <div className="alert alert-info mt-1 bolder">
                                                <label className="pull-left">{t('product_price')} 150 x {settings.state.quantity}</label>
                                                <label className="pull-right">{parseFloat(parseFloat(150) * settings.state.quantity).toFixed(2)}</label>
                                                <div className="clear clearfix"></div>
                                            </div> : ''
                                    </div>
                                        <button onClick={() => {
                                            if (!settings.state.size) {
                                                swal("Size Error", "Please select size", "error")
                                                return;
                                            }
                                            let cart = Array.isArray((lCart)) ? lCart : [];
                                            let found = {};
                                            let size = null;
                                            let price = settings.product.price;
                                            let colorKey = ``;
                                            let sizeKey = ''
                                            if (settings.state.size) {
                                                sizeKey = `_size_${settings.state.size}`;
                                            }
                                            let key = `_p_${sizeKey}${colorKey}`;
                                            const id = '__cuztomize_id__' + (Math.random(0, 1000) * 100);
                                            found = cart.findIndex((x) => x.pid === id + key);
                                            const p = {
                                                id: id,
                                                pid: id + key,
                                                image: { image: settings.displayImage },
                                                name: settings.state.name,
                                                number: settings.state.number,
                                                size: settings.state.size,
                                                files: settings.state.files,
                                                price: 150,
                                                color: settings.state.color['color_' + lang]?.title,
                                                quantity: settings.state.quantity,
                                                have_measurement: settings.product.have_measurement,
                                                measurement: settings.state.measurements
                                            };
                                            if (found > -1) {
                                                cart[found].quantity = cart[found].quantity + settings.state.quantity;
                                                cart[found].type = CartTypes.Product;
                                                if (!cart[found].moreProducts) {
                                                    cart[found].moreProducts = [];
                                                    cart[found].moreProducts.push(p)
                                                } else {
                                                    cart[found].moreProducts.push(p);
                                                }
                                            } else {
                                                p.type = CartTypes.StaticTShirt;
                                                cart.push(p);
                                            }
                                            swal(t("cart"), t("cart_updated"), "success")
                                            onSetCartContext(cart);
                                        }} className="btn btn-dark form-control mt-2">{t('add_to_cart')}</button>
                                    </div>
                                </div>
                            </> :
                            <div className="col-12 col-lg-9 text-center bolder" style={{ fontSize: 26 }}>
                                <i className="fa fa-spin fa-spinner"></i>
                            </div>
                    }
                </div>
                {
                    settings.render ?
                        <RelatedProducts
                            products={settings.related_products}
                            {...props}
                        />
                        : ''
                }
                <RecentlyViewdItemsMobile {...props} />
            </div>
        </div>
    )
}