import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { environment } from "../config"
import { CartContext } from "../contexts/CartContext"
import { SideBarContext } from "../contexts/SidebarContext"
import { CartTypes, printPrice } from '../helpers';
import { useTranslation } from "../hooks/Translation";

export const SideBar = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({ cart: [] })
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    const { cartContext, onSetCartContext } = useContext(CartContext)
    const lCart = (JSON.parse(localStorage.getItem('cart')) || []);
    const lang = localStorage.getItem('lang') || 'en';

    const onQuantityChange = (c, index, op) => {
        const tcart = Object.assign([], lCart);
        if (op === '+' && tcart[index].quantity < 100) {
            tcart[index].quantity += 1;
        } else if (op === '-' && tcart[index].quantity > 1) {
            tcart[index].quantity -= 1;
        }
        onSetCartContext(tcart);
    }
    const onChangeMeasurements = (value, index, mk) => {
        const tcart = Object.assign([], lCart);
        tcart[index].measurement[mk] = value;
        onSetCartContext(tcart);
    }
    
    const onRemoveCart = (c, index, op) => {
        let tcart = Object.assign([], lCart);
        delete tcart[index];
        tcart = tcart.filter(x => x);
        onSetCartContext(tcart);
    }
    const printTotal = (props) => {
        let total = [];
        lCart.forEach((cart, index) => {
            if (cart.price && cart.quantity > 0) {
                let t = parseFloat((parseFloat(cart.price)) * cart.quantity).toFixed(2);
                total.push(t);
            }
        });
        const price = total.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
        return printPrice(t, price);
    }
    return (
        <div className={appSettings.show ? "sidebar active mb-5 pb-5" : "sidebar mb-5 pb-5"}>
            {
                appSettings.show ?
                    <div className="blur-area" onClick={() => { setAppSettings({ ...appSettings, show: false }) }}></div>
                    : ''
            }
            <div className="topbar">
                <div className="title pull-left">{t('shopping_bag')} <span className="badge bg-secondary">{lCart.length || 0}</span></div>
                <div className="title pull-right">
                    <i
                        onClick={() => setAppSettings({ ...appSettings, show: false })}
                        className="fa fa-times"></i>
                </div>
                <div className="clear clearfix"></div>
            </div>
            <div
                className="cart"
                style={{ backgroundImage: `url(/assets/images/empty_box.png)`, paddingBottom: '9em' }}>
                {
                    lCart.length && lCart.length > 0 ?
                        <ul className="list-group">
                            {
                                lCart.map((c, index) => {
                                    return (
                                        <li key={index} className="list-group-item p-0">
                                            <table className="table m-0">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ minWidth: 100, verticalAlign: 'middle' }}>
                                                            <p className="m-0">{c?.name}</p>
                                                            <img
                                                                src={c?.image?.image}
                                                                style={{ width: 80 }}
                                                                className="pull-left img-fluid img-thumbnail" />
                                                        </td>
                                                        <td
                                                            className="text-center bolder"
                                                            style={{
                                                                verticalAlign: 'middle'
                                                            }}>
                                                            {
                                                                c.type === CartTypes.Appointment ?
                                                                    <p>{t('person')} : {c.person}</p>
                                                                    : ''
                                                            }
                                                            <div
                                                                hidden={
                                                                    c.type === CartTypes.Appointment
                                                                    // || c.type === CartTypes.CustomRequest 
                                                                    || c.type === CartTypes.Material
                                                                }
                                                                style={{
                                                                    position: 'relative',
                                                                    top: 1
                                                                }}
                                                                className="input-group input-group-sm flex-nowrap">
                                                                <span
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => onQuantityChange(c, index, '-')}
                                                                    className="input-group-text" id="addon-wrapping">-</span>
                                                                <input
                                                                    readOnly
                                                                    type="text"
                                                                    value={c.quantity}
                                                                    className="form-control text-center"
                                                                    placeholder="Username"
                                                                    aria-label="Username" aria-describedby="addon-wrapping" />
                                                                <span
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => onQuantityChange(c, index, '+')}
                                                                    className="input-group-text" id="addon-wrapping">+</span>
                                                            </div>
                                                            <div
                                                                className="bolder mt-1" style={{ fontSize: '14px' }}>
                                                                {
                                                                    c.have_measurement === 1 ?
                                                                        Object.keys(c.measurement).map((mk) => {
                                                                            return (
                                                                                <div className="mb-3">
                                                                                    <label>{ t(mk.replace(/-/g, '_')) }</label>
                                                                                    <input
                                                                                        value={c.measurement[mk] || ''}
                                                                                        onChange={(e) => onChangeMeasurements(e.target.value, index, mk) }
                                                                                        type="number" 
                                                                                        className="form-control" />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    : ''
                                                                }
                                                                <div className="text-center">
                                                                    {
                                                                        c.size ?
                                                                        <p className="m-0 cap">{ t('size') }: { c.size }</p>
                                                                        : ''
                                                                    }
                                                                    {
                                                                        c?.color?.id ?
                                                                            <p className="m-1 cap">{t('color')}: <div className="d-inline-block" style={{ position: 'relative', top: 5, width: 20, height: 20, backgroundColor: `#${c.color.color_code}`, borderRadius: '100%' }}></div></p>
                                                                        : ''
                                                                    }
                                                                    {
                                                                        c?.profile ?
                                                                            <p className="m-1 cap">
                                                                                { t('profile')}: { c?.profile }
                                                                            </p>
                                                                        : ''
                                                                    }
                                                                    <div
                                                                        hidden={
                                                                            c.type === CartTypes.Appointment
                                                                        }
                                                                    >
                                                                        <p className="m-0">{ printPrice(t, parseFloat(c.price).toFixed(2)) } x { c.quantity }</p>
                                                                        <p className="m-0">{ printPrice(t, parseFloat(parseFloat(c.price).toFixed(2) * c.quantity).toFixed(2)) }</p>
                                                                    </div>
                                                                    <div
                                                                        hidden={
                                                                            c.type !== CartTypes.Appointment
                                                                        }>
                                                                        <p className="m-0">{ printPrice(t, parseFloat(parseFloat(c.price).toFixed(2) * c.quantity).toFixed(2)) }</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{
                                                            verticalAlign: 'middle'
                                                        }}>
                                                            <i
                                                                onClick={() => onRemoveCart(c, index, '+')}
                                                                style={{ fontSize: 12, position: 'relative', top: 3 }}
                                                                className="btn btn-danger btn-sm fa fa-times"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        : (
                            <div className="empty-cart">
                                <span>{t('shopping_bag')} {t('no_products_the_cart')}.</span>
                            </div
                            >)
                }
            </div>
            <div className="bottombar" style={{
                height: 100
            }}>
                <table className="table p-0 m-0">
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'center', padding: 15 }}><strong>{t('subtotal')}:</strong></td>
                            <td style={{ textAlign: 'center', padding: 15 }}>
                                {printTotal()}
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td style={{ backgroundColor: '#ccc', padding: 15 }}>
                                <Link to="/cart">{t('view_cart')}</Link>
                            </td>
                            <td style={{ backgroundColor: '#9eb3a9', padding: 15 }}>
                                <Link className="link" to="/checkout">{t('checkout')}</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="clear clearfix"></div>
            </div>
        </div>
    )
}