import { useContext, useRef, useState } from "react"
import { environment } from "../config";
import { SideBarContext } from "../contexts/SidebarContext"
import { Axios } from "../helpers";
import { useTranslation } from "../hooks/Translation";
import * as _ from 'lodash';
import { Link } from "react-router-dom";

export const Search = (props) => {
    const t = useTranslation();
    const [filter, setFilter] = useState({ search: '', orderby: 'date' });
    const [iScroll, setIScroll] = useState({ last_page: 0, stopHits: false, page: 1, hasMore: false,  });
    const [settings, setSettings] = useState({ loader: false,  products: [], categories: [] });
    const { appSettings, setAppSettings } = useContext(SideBarContext)
    let searchModalRef = useRef(null);
    const doSearch = _.debounce((e) => {
        setFilter({ ...filter, search: e.target.value })
        setIScroll({ ...iScroll, page: 1 });
        loadData(1, e.target.value, true);
    }, 500)
    const lang = localStorage.getItem('lang') || 'en';
    const loadData = (page, search = '', refresh=false) => {
        setIScroll({ hasMore: false })
        if (settings.loader && !iScroll.stopHits && page <= iScroll.last_page) {
            return;
        }
            setAppSettings({
                ...appSettings,
                sidenav: false,
                leftsidenav: false,
                loading: true              
            })            
            setSettings({ ...settings, loader: true });
            Axios({}).get(`/products?page=${page}&search=${search}&orderby=date`).then((res) => {
                const p = res.data.data.map((r) => {
                    let image = {};
                    if (r.have_color === 1) {
                        image = r.product_color_images.length > 0 ? 
                        { ...r.product_color_images[0], image: `${environment.staticUrl}products/${r.product_color_images[0].image}` } : 
                        { image: `${environment.staticUrl}no_image.png` }
                    } else {
                        if (r.product_images && r.product_images.length > 0) {
                            image = r.product_images.length > 0 ? 
                            { ...r.product_images[0], image: `${environment.staticUrl}products/${r.product_images[0].image}` } : 
                            { image: `${environment.staticUrl}no_image.png` }
                        }
                    }
                    return {
                        ...r,
                        product_color_image: image
                    }
                })
                let pd = { products: [...settings.products, ...p ] };
                if (refresh) {
                    pd = { products: [ ...p ] }
                }
                if (res.data.last_page === iScroll.page) {
                    let p = iScroll.page;
                    if (refresh) {
                        p = page;
                    }
                    setIScroll({ ...iScroll, stopHits: true, hasMore: false, page: p, last_page: res.data.last_page });
                    setSettings({ ...settings, ...pd, loader: false });
                } else {
                    let p = iScroll.page + 1;
                    if (refresh) {
                        p = page;
                    }
                    setIScroll({ ...iScroll, hasMore: true, page: p });
                    setSettings({ ...settings, ...pd, hasMore: true, loader: false  });
                }
                setAppSettings({
                    ...appSettings,
                    loading: false,            
                    sidenav: false,
                    leftsidenav: false,  
                })
            }).catch((error) => {
                setSettings({ ...settings, products: [], loader: false });
                setAppSettings({
                    ...appSettings,
                    loading: false,            
                    sidenav: false,
                    leftsidenav: false,  
                })
            })         
    };
    return (
        <div ref={searchModalRef} id="searchmodal" className="search-modal" onClick={(e) => {
            if (e.target.id === 'searchmodal') {
                setAppSettings({ ...appSettings, search: false })
            }
        }}>
            <div className="content" style={{ position: 'relative' }}>
                <div 
                onClick={() => {
                    setAppSettings({ ...appSettings, search: false })
                }}
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '15px',
                    top: '6px',
                    fontSize: '22px'
                }}>
                    <i className="fa fa-times"></i>
                </div>
                <div className="mb-3 mt-3">
                    <input 
                        onChange={doSearch}
                        placeholder={t('search')} 
                        type="search"
                        className="form-control" />
                </div>
            {
                filter.search ?
                <div className="row m-0" style={{ overflowY: 'auto', maxHeight: '400px' }}>
                    {
                        settings.products.map((product, i) => {
                            return (
                                <Link
                                    onClick={() => {
                                        setAppSettings({ ...appSettings, search: false })
                                    }}
                                    key={i} 
                                    className="col-12 col-sm-6 col-lg-4 col-xl-4 p-1" 
                                    to={`/shops/${product['product_' + lang].slug}`}>
                                        <img 
                                            style={{ height: 350, width: '100%' }}
                                            className="img-thumbnail" 
                                            src={`${product.product_color_image.image}`} 
                                        />
                                        <p className="p-0 m-0 bolder">{ product['product_' + lang].title }</p>
                                        {
                                            product.have_size == 1 && product.product_price_sizes && product.product_price_sizes.length > 0 ?
                                                <p className="p-0 m-0 bolder">{ product.product_price_sizes[0].price }</p>
                                            : <p className="bold m-0 bold">{ product?.price }</p>
                                        }
                                        {/* <div className="">
                                            <p className="bold m-0 pull-left bold">{ product['product_' + lang].title } { product?.price }</p>
                                            <p className="bold m-0 pull-right">{ product?.price }</p>
                                            <div className="clear clearfix"></div>
                                        </div> */}
                                </Link>
                            )
                        })
                    }
                </div>
                : ''
            }
            </div>
        </div>
    )
}