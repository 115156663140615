import { useContext } from "react";
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "../../hooks/Translation";

export const AuthSideNav = (props) => {
    const t = useTranslation();
    const { auth, setAuth } = useContext(AuthContext)
    return (
        <div className="list-group">
            <Link to="/my-account" className="list-group-item list-group-item-action" aria-current="true">
                {/* <i className="fa fa-dashboard"></i> */}
                <span style={{ position: 'relative', left: 14 }}>{t('dashboard')}</span>
            </Link>
            <Link to="/my-account/measurements" className="list-group-item list-group-item-action">
                {/* <i className="fa fa-dashboard"></i> */}
                <span style={{ position: 'relative', left: 14 }}>{t('measurement')}</span>
            </Link>
            <Link to="/my-account/orders" className="list-group-item list-group-item-action">
                {/* <i className="fa fa-dashboard"></i> */}
                <span style={{ position: 'relative', left: 14 }}>{t('orders')}</span>
            </Link>
            <Link to="/my-account/addresses" className="list-group-item list-group-item-action">
                {/* <i className="fa fa-dashboard"></i> */}
                <span style={{ position: 'relative', left: 14 }}>{t('addresses')}</span>
            </Link>
            <Link to="/my-account/custom-requests" className="list-group-item list-group-item-action">
                {/* <i className="fa fa-dashboard"></i> */}
                <span style={{ position: 'relative', left: 14 }}>{t('custom_request')}</span>
            </Link>
            <Link to="/my-account/account-details" className="list-group-item list-group-item-action">
                {/* <i className="fa fa-dashboard"></i> */}
                <span style={{ position: 'relative', left: 14 }}>{t('account_detail')}</span>
            </Link>
            <a href="#" className="list-group-item list-group-item-action" onClick={(e) => {
                e.preventDefault();
                localStorage.removeItem('auth');
                setAuth({ is_auth: false, auth: null })
                window.location = '/my-account';
            }}>
                {/* <i className="fa fa-dashboard"></i> */}
                <span style={{ position: 'relative', left: 14 }}>{t('logout')}</span>
            </a>
        </div>
    )
}