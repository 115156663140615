import { useContext, useEffect, useState } from "react";
import { Axios } from '../helpers';
import swal from 'sweetalert';
import { AuthContext } from "../contexts/AuthContext";
import { parseQueryString } from '../helpers'
import { Link } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";

export const ActivationLink = (props) => {
    const t = useTranslation();
    const [settings, setSettings] = useState({
        isFormValid: false,
        loader: false,
        resendLoader: false,
        state: { code: '' },
        errors: {
            email: [],
            c_password: []
        },
        message: {
            text: '',
            className: ''
        }
    });
    const { auth, setAuth } = useContext(AuthContext);
    const handleInputChange = (event) => {
        if (event.target.required) {
            if (!event.target.value) {
                // setSettings({ ...settings, })
            }
        }
        setSettings({ ...settings, state: { ...settings.state, [event.target.name]: event.target.value } })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!settings.state.code) {
            swal("Oops", t('please_confirm_code'), "info")
            return;
        }
        if (settings.loader) {
            return;
        }
        setSettings({ ...settings, loader: true })
        Axios({}).post('/activation', { activation_code: settings.state.code }).then((res) => {
            setSettings({ ...settings, loader: false })
            if (res.data.success) {
                props.history.push('/my-account');
            }
        }).catch(error => {
            setSettings({ ...settings, resendLoader: false })
            swal("Oops", error.response.data.message, "error")
        })
    }
    const RenderErros = (props) => {
        if (!props.errors[props.key]) {
            return '';
        }
        return (
            <div className="alert alert-danger mt-1 p-1">
                <ul className="m-0">
                    {
                        props.errors[props.key].map((error, index) => {
                            return <li key={index}>{t('error')}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    return (
        <div className="myaccount container withoutheader-mobile">
            <div className="d-lg-none pb-5"></div>
            <div className="d-lg-none pb-5"></div>
            <div className="col-12 col-lg-8 offset-lg-2">
                <h2 className="app-title">{t('activation')}</h2>
                <form method="POST" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="code" className="form-label">{t('activation_code')}</label>
                        <input
                            required={true}
                            type="text"
                            value={settings.state.code}
                            onChange={handleInputChange}
                            className="form-control"
                            id="code"
                            name="code"
                            placeholder={t('activation_code') + "*"} />
                    </div>
                    <Link to="/my-account/resend">{t('resend')}</Link>
                    <button disabled={settings.state.code === ''} className="btn btn-primary form-control mt-3">
                        {
                            settings.loader ?
                                <i className="fa fa-spin fa-spinner"></i>
                                : <span>{t('activate')}</span>
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}