import { useContext, useEffect, useState } from "react";
import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { Shops } from "./Shops";

export const Products = (props) => {
    const [settings, setSettings] = useState({ });
    return (
        <Shops {...props} />
    )
}