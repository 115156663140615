import { useEffect, useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { environment } from '../config';

export const HCarousel = (props) => {
    let r = useRef(null);
    const itemStyle = props.itemStyle ? props.itemStyle : {};
    const lang = localStorage.getItem('lang') || 'en';
    const dir = localStorage.getItem('dir') || 'rtl';

    const hCarouselSettings = {
        loop: true,
        margin: 10,
        rtl: dir === 'rtl',
        responsiveClass: true,
        responsive:{
            0: {
                items:1,
                nav:true
            },
            600:{
                items: 2,
                nav: true
            },
            1000: {
                items: props.initItems ? props.initItems : 3,
                nav: true,
                loop: false
            }
        },
        navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
    };
    useLayoutEffect(() => {
        if (r && r.current) {
            window.$(r.current).owlCarousel(hCarouselSettings);
        }
    }, [])
    return (
        <div className="category-showcases mb-5" data-aos="fade-top">
            <h2 className="text-center title mt-5 mb-5 cap">{ props.title }</h2>
            {
                props.tabs ?
                <ul className="nav justify-content-center tabs">
                    {
                        props.tabs && props.tabs.map((tab, index) => {
                            return (
                                <li key={index} className="nav-item tab">
                                    <a 
                                        className={ tab.slug === props.activeKey ? `nav-link tab-link active` : `nav-link tab-link` } 
                                        aria-current="page"
                                        onClick={(e) => { props.onTabClick && props.onTabClick(e, tab, index) }}
                                        href="/">
                                        { tab.name || tab.title }
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul> : ''
            }
            <div ref={r} className="owl-carousel owl-theme">
                    {
                        props.showcases && props.showcases.map((showcase, index) => {
                            return (
                                <Link 
                                exact={true}
                                to={`${showcase?.slug}`}
                                className="item"
                                key={index}>
                                    <img
                                        key={index}
                                        className="img-thumbnail m-0 mt-2"
                                        style={props.itemStyle}
                                        src={showcase?.image}
                                    />
                                </Link>
                            )
                        })
                    }
            </div>
        </div>
    )
}