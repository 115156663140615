import { Link } from "react-router-dom";
import { useTranslation } from "../hooks/Translation";

export const TitleImageShowCase = (props) => {
    const t = useTranslation();
    return (
        <div className="titleimageshowcase text-center mt-2">

            <h3
                className='btn circle-button m-1 mb-2'
                style={{
                    padding: 8
                }}>
                <Link to="/products/custom-abaya" className="btn text-white">{t('abaya')}</Link>
            </h3>
            <img src={props.image} className="img-fluid" />
            <h1 className="text-center pt-2" style={{
                textTransform: 'uppercase'
            }}>{t('new_collection')}</h1>
            <div className="columnshowcaseleft py-2">
                {
                    props.tabs.map((tab, index) => {
                        return (
                            <h3
                                onClick={(e) => {
                                    if (props.onTabClick) {
                                        props.onTabClick(e, tab, index)
                                    }
                                }}
                                className={props.activeIndex === index ? 'active btn circle-button m-1' : 'btn circle-button m-1'}
                                style={{
                                    padding: 8
                                }}>{tab.title}
                            </h3>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const TextShowCase = (props) => {
    return (
        <div className="titleimageshowcase p-5 mt-4 pb-0 pt-0">
            {
                Object.keys(props.content).map((p) => {
                    return <p className="text-center">{props.content[p]}</p>
                })
            }
        </div>
    )
}